/* eslint-disable camelcase */
import { useAppConfig } from "@/stores/appConfigStore"
import { VueAuthPlugin } from "oidc-vue"
import { defineGlobals } from "vue-utils"

class AppAuthPlugin extends VueAuthPlugin {
	constructor() {
		const config = useAppConfig()
		super({
			clientId: config.keycloak.clientId,
			authority: config.keycloak.authority,
			redirectUri: window.location.origin,
			postLogoutRedirectUri: window.location.origin,
			storagePrefix: "cg_sarlog_",
			sessionExpireLoginType: "popup",
		})
	}

	getKeycloakManageUrl() {
		const realmUrl = this.options.authority
		return `${realmUrl}/account?referrer=${encodeURIComponent(this.options.clientId)}&referrer_uri=${encodeURIComponent(
			window.location.href
		)}`
	}
}

export const AppAuthContext = new AppAuthPlugin()
defineGlobals({
	AppAuthContext,
})
