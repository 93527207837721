import { useLoading } from "@/composition/useLoading"
import type { IncidentLog } from "@/models"
import { createIncidentLog } from "@/services/incidentsService"
import { useLoggedInUser } from "@/stores/coreDataStore"
import usePopups from "@/stores/popupsStore"
import { useEventListener } from "@vueuse/core"
import { shallowRef } from "vue"
import { onBeforeRouteLeave, useRouter } from "vue-router"
import {
	ModalTitle,
	defineComponent,
	refSetter,
	requiredProp,
	useCustomFormValidation,
	type ReactiveComponent,
} from "vue-utils"
import IncidentForm from "../shared/incident-form/IncidentForm"
import { CreateIncidentHandler } from "./CreateIncidentHandler"

interface Props {
	incident: IncidentLog
}

const CreateIncidentLog: ReactiveComponent<Props> = (props) => {
	const popups = usePopups()
	const loggedInUser = useLoggedInUser()
	const router = useRouter()
	const { runAction } = useLoading()

	const formRef = shallowRef<HTMLFormElement>()
	const validationHandler = useCustomFormValidation({
		form: formRef,
	})

	const handler = new CreateIncidentHandler({
		validationHandler,
		loggedInUser,
		incident: props.incident,
		createIncident: () => runAction(save()),
	})

	let hasSaved = false

	async function save() {
		const createdIncident = await createIncidentLog(props.incident)

		hasSaved = true
		await router.replace(`/incidents/id/${createdIncident.id}`)
	}

	useEventListener("beforeunload", (e) => {
		if (!hasSaved) {
			e.preventDefault()
		}
	})

	onBeforeRouteLeave(async () => {
		if (hasSaved) {
			return true
		}
		return await popups.confirm(() => (
			<>
				<ModalTitle title="Confirm leave page" />
				<hr />
				<p>Are you sure you wish to leave without creating the incident?</p>
			</>
		))
	})

	return () => <IncidentForm handler={handler} setupForm={refSetter(formRef)} />
}

export default defineComponent(CreateIncidentLog, {
	incident: requiredProp(Object),
})
