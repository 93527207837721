import type { FormHTMLAttributes } from "vue"
import { defineComponent, renderSlot, type NoProps, type ReactiveComponent } from "vue-utils"

const SimpleForm: ReactiveComponent<NoProps, FormHTMLAttributes> = (_, { attrs, slots }) => {
	function handleSubmit(e: Event) {
		const form = e.target as HTMLFormElement
		if (!form.reportValidity()) {
			return
		}
		attrs.onSubmit?.(e)
	}

	return () => (
		<form {...attrs} onSubmit={handleSubmit}>
			{renderSlot(slots)}
		</form>
	)
}

export default defineComponent(SimpleForm)
