import AutoColumns from "@/components/AutoColumns"
import type { Tide } from "@/models"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../IncidentLogHandler"
import IncidentInput from "../../components/IncidentInput"
import IncidentInstantInput from "../../components/IncidentInstantInput"

interface Props {
	name: string
	type: keyof Tide
}

const TideInput: ReactiveComponent<Props> = (props) => {
	const handler = useIncidentHandler()
	const tidePath = () => handler.changeBuilder.at("currentTide").at(props.type)

	return () => (
		<AutoColumns size="16rem" class="spacing-4">
			<label>
				{props.name} Time (UTC)
				<IncidentInstantInput path={tidePath().at("time")} disabled={handler.readOnly} required />
			</label>
			<label>
				{props.name} Height (m)
				<IncidentInput
					type="number"
					path={tidePath().at("height")}
					placeholder={handler.readOnly ? "N/A" : "Enter tide height..."}
					min={props.type === "highWater" ? handler.incident.currentTide.lowWater.height : 0}
					max={props.type === "lowWater" ? handler.incident.currentTide.highWater.height : 20}
					step={0.1}
					disabled={handler.readOnly}
					required
				/>
			</label>
		</AutoColumns>
	)
}

export default defineComponent(TideInput, {
	name: requiredProp(String),
	type: requiredProp(String),
})
