import BootstrapButton from "@/components/form/BootstrapButton"
import Icon from "@/components/Icon"
import { useLoading } from "@/composition/useLoading"
import { IncidentStatus } from "@/models"
import { createEmptyAction } from "@/modifications"
import usePopups from "@/stores/popupsStore"
import { faPenNib } from "@fortawesome/free-solid-svg-icons"
import { Alert, defineComponent, type ReactiveComponent } from "vue-utils"
import type { SignOffAction } from "../../AdditionalActions"
import { useIncidentHandler } from "../../IncidentLogHandler"

const SignOffButton: ReactiveComponent = () => {
	const popups = usePopups()
	const handler = useIncidentHandler()
	const { runAction } = useLoading()

	async function handleClick() {
		if (!(await handler.validateForm()) || !(await confirmSignOff())) {
			return
		}
		const action: SignOffAction = {
			...createEmptyAction(),
			type: "SignOff",
		}
		await runAction(handler.awaitChange(action))
		void popups.showAlertPopup(() => <Alert title="Success">The incident has been successfully signed off.</Alert>)
	}

	async function confirmSignOff() {
		return await popups.confirm({
			cancelText: "Cancel",
			confirmText: "Sign Off",
			content: () => (
				<Alert title="Confirm Sign Off">
					<p>
						Please confirm that{" "}
						<strong>
							<i>all information</i>
						</strong>{" "}
						on the incident log is correct.
					</p>
					<p>
						Saving the log and signing off will prevent this incident log being being edited further or information
						amended.
					</p>
				</Alert>
			),
		})
	}

	return () => (
		<BootstrapButton type="button" color="danger" onClick={() => void handleClick()}>
			<Icon icon={faPenNib} />
			<span>{handler.incident.status === IncidentStatus.Open ? "Close & Sign Off" : "Sign Off"} Incident</span>
		</BootstrapButton>
	)
}

export default defineComponent(SignOffButton)
