import type { EditorConfig } from "@ckeditor/ckeditor5-core"
import type { ClassicEditor } from "@ckeditor/ckeditor5-editor-classic"
import { onMounted, onUnmounted, shallowRef, watch, type HTMLAttributes } from "vue"

import { css } from "vite-css-in-js"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import "./CKEditor.scss"

interface Props {
	value: string
	setValue(value: string): void

	options?: EditorConfig
	onInit?: (editor: ClassicEditor) => void

	height?: string
	readOnly?: boolean
	viewOnly?: boolean
}

const containerStyles = css`
	&[data-view-only="true"] {
		.ck-toolbar {
			display: none;
		}
	}
	&[data-read-only="true"] {
		color: #333;
	}

	.ck-editor__main .ck-content {
		padding: 0.5rem;

		& > *:first-child {
			margin-top: 0;
		}
		& > *:last-child {
			margin-bottom: 0;
		}
		p {
			margin: 0.125rem 0;
		}
		h2 {
			font-size: 1.5rem;
		}
		h3 {
			font-size: 1.25rem;
		}
		h4 {
			font-size: 1.125rem;
		}
		h1,
		h2,
		h3,
		h4,
		h5 {
			margin-top: 1rem;
			margin-bottom: 0.25rem;
		}
	}
`

const CKVueEditor: ReactiveComponent<Props, HTMLAttributes> = (props, { attrs }) => {
	let editor: ClassicEditor
	let mounted = true
	let previousValue = props.value

	const containerRef = shallowRef<HTMLElement>()
	async function initEditor() {
		mounted = true

		const { ClassicEditor, CKEditorPlugins, CKEditorToolbar } = await import("./configuration")

		if (!mounted) {
			return
		}
		editor = await ClassicEditor.create(containerRef.value!, {
			initialData: props.value,
			plugins: CKEditorPlugins,
			toolbar: CKEditorToolbar,
			...props.options,
		})
		previousValue = props.value

		props.onInit?.(editor)

		editor.model.document.on("change:data", updateValue)

		updateReadOnly()
		updateHeight()
	}

	function updateReadOnly() {
		const readOnly = props.readOnly ?? false
		if (!editor || editor.isReadOnly === readOnly) {
			return
		}
		if (readOnly) {
			editor.enableReadOnlyMode("vue")
		} else {
			editor.disableReadOnlyMode("vue")
		}
	}

	function updateValue() {
		if (!editor) {
			return
		}
		const newValue = editor.data.get()
		props.setValue(newValue)
		previousValue = newValue
	}

	function updateValueFromProps() {
		if (!editor) {
			return
		}
		const newValue = props.value
		if (newValue !== previousValue) {
			editor.setData(newValue)
			previousValue = newValue
		}
	}

	function updateHeight() {
		if (!editor) {
			return
		}
		editor.editing.view.change((writer) => {
			const root = editor.editing.view.document.getRoot()!
			if (props.height) {
				writer.setStyle("height", props.height, root)
			} else {
				writer.removeStyle("height", root)
			}
		})
	}

	onMounted(initEditor)
	onUnmounted(async () => {
		mounted = false
		await editor?.destroy()
	})
	watch(() => props.readOnly, updateReadOnly)
	watch(() => props.value, updateValue)
	watch(() => props.height, updateHeight)
	watch(() => props.value, updateValueFromProps)

	return () => (
		<div
			class={containerStyles}
			data-view-only={String(props.viewOnly ?? false)}
			data-read-only={String(props.readOnly ?? false)}
			{...attrs}
		>
			<div ref={containerRef} />
		</div>
	)
}

export default defineComponent(CKVueEditor, {
	value: requiredProp(String),
	setValue: requiredProp(Function),

	options: optionalProp(Object),
	onInit: optionalProp(Function),

	height: optionalProp(String),
	readOnly: optionalProp(Boolean),
	viewOnly: optionalProp(Boolean),
})
