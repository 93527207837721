import { Instant } from "@js-joda/core"
import type { IIdentifier, Id } from "../models/IncidentEntity"
import type { AddItemAction, BaseAction, RemoveFieldAction, SetFieldValueAction } from "./Modifications"
import { IdPath, MemberPath, type ObjectPath, type ObjectPathEntry } from "./path"

export class ChangeBuilder<T> {
	readonly path: Readonly<ObjectPath>

	constructor(path: ObjectPath) {
		this.path = path
	}

	public static of<T>(): ChangeBuilder<T> {
		return new ChangeBuilder([])
	}

	public at<TKey extends string & keyof T>(key: TKey): ChangeBuilder<T[TKey]> {
		return this.with(new MemberPath(key))
	}

	public id(id: Id): ChangeBuilder<T extends (infer U)[] ? (U extends IIdentifier ? U : never) : never> {
		return this.with(new IdPath(id))
	}

	public setTo(newValue: T): SetFieldValueAction<T> {
		return {
			type: "Set",
			path: [...this.path],
			newValue,
			...createEmptyAction(),
		}
	}

	public add(value: T): AddItemAction<T> {
		return {
			type: "Add",
			path: [...this.path],
			value,
			...createEmptyAction(),
		}
	}

	public remove(): RemoveFieldAction {
		return {
			type: "Remove",
			path: [...this.path],
			...createEmptyAction(),
		}
	}

	with<TNewValue>(entry: ObjectPathEntry): ChangeBuilder<TNewValue> {
		return new ChangeBuilder([...this.path, entry])
	}
}

export function createEmptyAction(): Omit<BaseAction, "type"> {
	return {
		created: Instant.now(),
		guid: window.crypto.randomUUID(),
	}
}

export function changeBuilder<T>() {
	return ChangeBuilder.of<T>()
}
