import BootstrapButton from "@/components/form/BootstrapButton"
import Icon from "@/components/Icon"
import { useLoading } from "@/composition/useLoading"
import { createEmptyAction } from "@/modifications"
import usePopups from "@/stores/popupsStore"
import { faLock } from "@fortawesome/free-solid-svg-icons"
import { Alert, defineComponent, type ReactiveComponent } from "vue-utils"
import type { CloseIncidentAction } from "../../AdditionalActions"
import { useIncidentHandler } from "../../IncidentLogHandler"

const CloseIncidentButton: ReactiveComponent = () => {
	const popups = usePopups()
	const handler = useIncidentHandler()
	const { runAction } = useLoading()

	async function handleClick() {
		if (!(await handler.validateForm()) || !(await confirmClose())) {
			return
		}
		const action: CloseIncidentAction = {
			...createEmptyAction(),
			type: "CloseIncident",
		}
		await runAction(handler.awaitChange(action))
		void popups.showAlertPopup(() => (
			<Alert title="Success">The incident has been successfully closed. You may reopen it again at any time.</Alert>
		))
	}

	async function confirmClose() {
		return await popups.confirm(() => (
			<Alert title="Confirm Close Incident?">
				Are you sure you wish to close this incident? It will be locked for editing, but can be re-opened later if
				required.
			</Alert>
		))
	}

	return () => (
		<BootstrapButton type="button" color="dark" onClick={() => void handleClick()}>
			<Icon icon={faLock} />
			<span>Close Incident</span>
		</BootstrapButton>
	)
}

export default defineComponent(CloseIncidentButton)
