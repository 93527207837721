import { Duration, Instant } from "@js-joda/core"
import type { MaybeRef } from "@vueuse/core"
import { unref } from "vue"
import { assignLoadable, useInterval, useWindowListener, type Loadable } from "vue-utils"

export function useStoreRefresh<T extends Record<string, Loadable<unknown>>>(
	stores: MaybeRef<T>,
	refreshTime: Duration
) {
	const lastRefreshTime = new Map<keyof T, Instant>()

	async function refreshStore<K extends keyof T>(storeId: K) {
		try {
			const store = unref(stores)[storeId]
			const result = await store.queryData()
			assignLoadable(store, {
				type: "done",
				result,
			})
		} catch (e) {
			console.error(e)
		}
	}

	function shouldRefresh<K extends keyof T>(storeId: K): boolean {
		const store = unref(stores)[storeId]
		if (store.type === "initial" || store.type === "loading") {
			return false
		}
		const now = Instant.now()
		const lastUpdate = lastRefreshTime.get(storeId)

		if (!lastUpdate) {
			lastRefreshTime.set(storeId, now)
			return false
		}

		return lastUpdate.isBefore(now.minus(refreshTime))
	}

	function updateStores() {
		const storesValue = unref(stores)
		for (const storeId in storesValue) {
			if (shouldRefresh(storeId)) {
				void refreshStore(storeId)
			}
		}
	}

	useInterval(refreshTime.toMillis(), updateStores)
	useWindowListener("visibilitychange", () => !document.hidden && updateStores())
}
