import { Instant } from "@js-joda/core"
import type { ApiTypeOf } from "./ApiType"
import { CallInformationSchema, type CallInformation } from "./CallInformation"
import { EmergencyPhase } from "./EmergencyPhase"
import type { Id, IncidentEntity, Nullable } from "./IncidentEntity"
import { IncidentStatus } from "./IncidentStatus"
import { LatLngSchema, type LatLng } from "./LatLng"
import { NoteEntrySchema, type NoteEntry } from "./NoteEntry"
import { InstantSchema, enumOf, mapApiType, type ApiSchema } from "./Schema"
import { UserTimeStampSchema, type UserTimeStamp } from "./UserTimeStamp"
import { TideSchema, WeatherConditionsSchema, type Tide, type WeatherConditions } from "./conditions"
import { ShipDescriptionSchema, ShipRegistrationSchema, type ShipDescription, type ShipRegistration } from "./ship"
import { AssetTaskedSchema, type AssetTasked } from "./sru"

export interface IncidentLog extends IncidentEntity {
	identifier: string
	incidentTime: Instant
	createdBy: UserTimeStamp

	status: IncidentStatus

	SMCOnDutyId: Nullable<Id>

	primaryIncidentTypeId: Id

	emergencyPhase: EmergencyPhase
	nameOfVesselOrCasualty: Nullable<string>
	personsAtRisk: number

	positionReceivedAt: Nullable<Instant>
	position: LatLng

	messageReceived: string
	natureOfIncident: Nullable<string>
	descriptionOfCasualty: Nullable<string>

	shipDescription: ShipDescription
	assetsTasked: AssetTasked[]
	callReceived: CallInformation
	registrationInformation: ShipRegistration
	weatherConditions: WeatherConditions
	currentTide: Tide

	incidentNarrative: NoteEntry[]

	reopened: Nullable<UserTimeStamp>
	closed: Nullable<UserTimeStamp>
	signedOff: Nullable<UserTimeStamp>
}

export const IncidentLogSchema: ApiSchema<IncidentLog> = {
	type: "object",
	properties: {
		id: Number,
		identifier: String,
		incidentTime: InstantSchema,
		createdBy: UserTimeStampSchema,

		status: enumOf(IncidentStatus),

		SMCOnDutyId: Number,
		primaryIncidentTypeId: Number,

		emergencyPhase: enumOf(EmergencyPhase),
		nameOfVesselOrCasualty: String,
		personsAtRisk: Number,

		positionReceivedAt: InstantSchema,
		position: LatLngSchema,

		messageReceived: String,
		natureOfIncident: String,
		descriptionOfCasualty: String,

		shipDescription: ShipDescriptionSchema,
		assetsTasked: {
			type: "array",
			schema: AssetTaskedSchema,
		},
		callReceived: CallInformationSchema,
		registrationInformation: ShipRegistrationSchema,
		weatherConditions: WeatherConditionsSchema,
		currentTide: TideSchema,

		incidentNarrative: {
			type: "array",
			schema: NoteEntrySchema,
		},

		reopened: UserTimeStampSchema,
		closed: UserTimeStampSchema,
		signedOff: UserTimeStampSchema,
	},
}

export function mapApiIncidentLog(log: ApiTypeOf<IncidentLog>): IncidentLog {
	return mapApiType(IncidentLogSchema, log)
}
