import Icon from "@/components/Icon"
import type { Nullable } from "@/models"
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { computed, ref } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import EditableField from "../EditableField"
import EditNotes from "./EditNotes"

interface Props {
	notes: Nullable<string>
	setNotes(notes: string): Promise<void>
}

const existingNotesStyles = css`
	position: relative;
	cursor: pointer;

	svg {
		color: #f6f88c;
		font-size: 1.5rem;
		& > path {
			stroke: black;
			stroke-width: 40;
		}
	}

	span:first-child {
		position: absolute;
		width: 1.25rem;
		height: 1.25rem;
		font-size: 1.125rem;
		border-radius: 100%;
		color: white;
		background-color: var(--color-error);
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid black;
		font-weight: bold;

		top: -0.75rem;
		right: -0.75rem;
	}
`

const NotesCell: ReactiveComponent<Props> = (props) => {
	const editing = ref(false)
	const hasNotes = computed(() => props.notes !== null && props.notes.trim().length > 0)

	return () => (
		<>
			<EditableField hasValue={!!props.notes} edit={() => (editing.value = true)} showOnReadOnly={hasNotes.value}>
				<div class="flex items-center justify-center">
					{hasNotes.value ? (
						<div class={existingNotesStyles}>
							<span>!</span>
							<Icon icon={faNoteSticky} />
						</div>
					) : (
						"-"
					)}
				</div>
			</EditableField>

			{editing.value && (
				<EditNotes
					notes={props.notes ?? ""}
					cancel={() => (editing.value = false)}
					setNotes={async (newNotes) => {
						await props.setNotes(newNotes)
						editing.value = false
					}}
				/>
			)}
		</>
	)
}

export default defineComponent(NotesCell, {
	notes: requiredProp(String, null),
	setNotes: requiredProp(Function),
})
