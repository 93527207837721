import Icon from "@/components/Icon"
import AnchorButton from "@/components/form/AnchorButton"
import BootstrapButton from "@/components/form/BootstrapButton"
import MapLocationSelect from "@/components/mapping/MapLocationSelect"
import SortableHeading from "@/components/table/SortableHeading"
import { IncidentStatus, IncidentsSortBy, SortDirection, SystemRole, type LatLng, type Nullable } from "@/models"
import type { IncidentLogSummary } from "@/models/IncidentLogSummary"
import { useLoggedInUser } from "@/stores/coreDataStore"
import { formatEnum } from "@/utils/formatEnum"
import { LatLngFormatType, formatLatLng } from "@/utils/formatting/formatLatLng"
import { faEdit, faEye, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { Teleport, shallowRef } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	sort: Nullable<IncidentLogSort>
	setSort(sort: IncidentLogSort): void

	incidents: IncidentLogSummary[]
}

export interface IncidentLogSort {
	direction: SortDirection
	column: IncidentsSortBy
}

const tableStyles = css`
	grid-template-columns: repeat(10, auto);

	td,
	th {
		padding: 0.35rem 0.5rem;
		white-space: nowrap;
	}
	td:nth-child(6) {
		white-space: initial;
	}
	th:last-child,
	td:last-child {
		border: none !important;
		background-color: transparent !important;
		padding: 0.25rem;
	}
	tbody > tr:nth-child(2n + 1) > td {
		background-color: #f0f0f0;
	}
`

const IncidentSummaryTable: ReactiveComponent<Props> = (props) => {
	const locationShown = shallowRef<LatLng | null>(null)
	const loggedInUser = useLoggedInUser()

	function renderHeading(column: IncidentsSortBy, text = formatEnum(column)) {
		return (
			<SortableHeading
				direction={props.sort && props.sort.column === column ? props.sort.direction : null}
				setDirection={(direction) => props.setSort({ column, direction })}
			>
				{text}
			</SortableHeading>
		)
	}

	function canEdit(incident: IncidentLogSummary): boolean {
		return incident.status !== IncidentStatus.SignedOff && loggedInUser.atLeastHasRole(SystemRole.CoastguardOfficer)
	}

	return () => (
		<table class={["grid-table", tableStyles]}>
			<thead>
				<tr>
					{renderHeading(IncidentsSortBy.IncidentId)}
					{renderHeading(IncidentsSortBy.IncidentTime)}
					{renderHeading(IncidentsSortBy.CreatedAt)}
					{renderHeading(IncidentsSortBy.PrimaryIncidentType)}
					{renderHeading(IncidentsSortBy.EmergencyPhase)}
					{renderHeading(IncidentsSortBy.NameOfVesselOrCasualty, "Name of Vessel or Casualty")}
					{renderHeading(IncidentsSortBy.PersonsAtRisk)}
					<th>Coordinates</th>
					{renderHeading(IncidentsSortBy.Status)}
					<th />
				</tr>
			</thead>
			<tbody>
				{props.incidents.map((incident) => (
					<tr key={incident.id}>
						<td>{incident.identifier}</td>
						<td>{incident.incidentTime.toUTCDateTimeString()}</td>
						<td>{incident.createdAt.toUTCDateTimeString()}</td>
						<td>{incident.primaryIncidentType.name}</td>
						<td>{formatEnum(incident.emergencyPhase)}</td>
						<td>{incident.nameOfVesselOrCasualty}</td>
						<td>{incident.personsAtRisk}</td>
						<td class="flex justify-between">
							<span>{formatLatLng(incident.position, LatLngFormatType.DegreesDecimalMinutes)}</span>
							<BootstrapButton
								color="danger"
								title="View Location"
								onClick={() => (locationShown.value = incident.position)}
								style={{ marginLeft: "0.25rem" }}
							>
								<Icon icon={faMapMarkerAlt} />
							</BootstrapButton>
						</td>
						<td>{formatEnum(incident.status)}</td>
						<td>
							<AnchorButton
								href={`/incidents/id/${incident.id}`}
								color="primary"
								title={canEdit(incident) ? "Edit Incident" : "View Incident"}
							>
								<Icon icon={canEdit(incident) ? faEdit : faEye} />
							</AnchorButton>
						</td>
					</tr>
				))}
			</tbody>
			{locationShown.value && (
				<Teleport to="body">
					<MapLocationSelect
						locationName="Incident Location"
						location={locationShown.value}
						setLocation={() => void 0}
						cancel={() => (locationShown.value = null)}
						readOnly
					/>
				</Teleport>
			)}
		</table>
	)
}

export default defineComponent(IncidentSummaryTable, {
	incidents: requiredProp(Array),

	sort: requiredProp(Object, null),
	setSort: requiredProp(Function),
})
