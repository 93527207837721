/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable @typescript-eslint/no-explicit-any */
export class Deferred<T> implements Promise<T> {
	private resolveFn?: (value: T | PromiseLike<T>) => void
	private rejectFn?: (reason?: Error) => void

	private readonly promise = new Promise<T>((resolve, reject) => {
		this.resolveFn = resolve
		this.rejectFn = reject
	})

	resolve(value: T | PromiseLike<T>): void {
		this.resolveFn!(value)
	}

	reject(error: Error): void {
		this.rejectFn!(error)
	}

	then<TResult1 = T, TResult2 = never>(
		onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null | undefined,
		onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined
	): Promise<TResult1 | TResult2> {
		return this.promise.then(onfulfilled, onrejected)
	}
	catch<TResult = never>(
		onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | null | undefined
	): Promise<T | TResult> {
		return this.promise.catch(onrejected)
	}
	finally(onfinally?: (() => void) | null | undefined): Promise<T> {
		return this.promise.finally(onfinally)
	}

	get [Symbol.toStringTag](): string {
		return this.promise[Symbol.toStringTag]
	}
}
