import { useLoading } from "@/composition/useLoading"
import { type SearchIncidentsFilter } from "@/models"
import { searchIncidentLogs } from "@/services/incidentsService"
import { watchDeep } from "@vueuse/core"
import { assignLoadable, runWithMinimumDelay, useLoadableRef } from "vue-utils"

export function useFilteredIncidents(filter: SearchIncidentsFilter) {
	const { runAction } = useLoading()

	const incidentsRef = useLoadableRef(async () => {
		const result = await searchIncidentLogs({ filter, page: 1, pageSize: -1 })
		return result.incidents
	})

	async function applyFilter() {
		const newLogs = await runAction(runWithMinimumDelay(Promise.resolve(incidentsRef.queryData()), 750))
		assignLoadable(incidentsRef, { type: "done", result: newLogs })
	}

	watchDeep(filter, applyFilter)
	return incidentsRef
}
