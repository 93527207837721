import type { LatLng } from "@/models"

export enum LatLngFormatType {
	DegreesDecimalMinutes = "DDM",
	DecimalDegrees = "DD",
}

export function formatLatLng(loc: LatLng, type: LatLngFormatType): string {
	switch (type) {
		case LatLngFormatType.DecimalDegrees:
			return `${formatDD(loc.latitude)}, ${formatDD(loc.longitude)}`
		case LatLngFormatType.DegreesDecimalMinutes:
			return `${formatDDM(loc.latitude, "latitude")}, ${formatDDM(loc.longitude, "longitude")}`
	}
	throw new Error("Unknown format type")
}

function formatDDM(value: number, type: keyof LatLng): string {
	const positiveUnit = type === "latitude" ? "N" : "E"
	const negativeUnit = type === "latitude" ? "S" : "W"
	const unit = value < 0 ? negativeUnit : positiveUnit

	const degrees = Math.trunc(Math.abs(value))
	const decimalMinutes = (Math.abs(value) - degrees) * 60

	return `${degrees}°${decimalMinutes.toFixed(3)}'${unit}`
}

function formatDD(value: number) {
	return value.toFixed(5)
}
