import { TideSchema, type Nullable, type Tide, type WeatherConditions } from "@/models"
import { mapApiType } from "@/models/Schema"
import { httpRequestJsonResponse } from "./httpService"

export async function getCurrentWeatherConditions(): Promise<Nullable<WeatherConditions>> {
	return await httpRequestJsonResponse("/api/conditions/weather")
}

export async function getCurrentTide(): Promise<Nullable<Tide>> {
	const tide = await httpRequestJsonResponse<Nullable<Tide>>("/api/conditions/tide")
	return mapApiType(TideSchema, tide)
}
