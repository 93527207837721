import AutoColumns from "@/components/AutoColumns"
import BasicSelect from "@/components/form/BasicSelect"
import { EmergencyPhase, IncidentStatus, SystemRole, User, type Id } from "@/models"
import { useCoreData, useUser } from "@/stores/coreDataStore"
import { formatEnum } from "@/utils/formatEnum"
import { Instant } from "@js-joda/core"
import { computed } from "vue"
import { Required, defineComponent, refSetter, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../IncidentLogHandler"
import IncidentInput from "../components/IncidentInput"
import IncidentInstantInput from "../components/IncidentInstantInput"
import IncidentTextArea from "../components/IncidentTextArea"
import { useIncidentSelectRef } from "../inputHelpers"

const MainDetails: ReactiveComponent = () => {
	const handler = useIncidentHandler()
	const coreData = useCoreData()

	const smcUsers = computed(() => Array.from(coreData.users.values()).filter((u) => u.role === SystemRole.SMC))
	const createdBy = useUser(() => handler.incident.createdBy.userId)
	const canEdit = () => !handler.readOnly && handler.isNewIncident

	const changeBuilder = handler.changeBuilder

	const smcRef = useIncidentSelectRef<Id | null>(changeBuilder.at("SMCOnDutyId"))
	const incidentTypeRef = useIncidentSelectRef(changeBuilder.at("primaryIncidentTypeId"))
	const emergencyPhaseRef = useIncidentSelectRef(changeBuilder.at("emergencyPhase"))

	return () => (
		<div class="flex flex-col spacing-4">
			<AutoColumns size="16rem" class="spacing-4">
				{!handler.isNewIncident && (
					<label>
						Incident Identifier
						<input type="text" value={handler.incident.identifier} disabled />
					</label>
				)}
				<label>
					<Required label="Incident Time (UTC)" />
					<IncidentInstantInput
						path={changeBuilder.at("incidentTime")}
						max={Instant.now()}
						disabled={!canEdit()}
						required
					/>
				</label>
				<label>
					Opened By
					<input type="text" value={createdBy.value?.fullName ?? "-"} disabled />
				</label>
				<label>
					Opened At (UTC)
					<input type="datetime-local" value={handler.incident.createdBy.timeStamp.toHTMLInputUTC()} disabled />
				</label>
				<label>
					Status
					<BasicSelect
						options={Object.values(IncidentStatus)}
						value={handler.incident.status}
						setValue={() => void 0}
						getText={formatEnum}
						getValue={(s) => s}
						required
						disabled
					/>
				</label>
			</AutoColumns>
			<AutoColumns size="16rem" class="spacing-4">
				<label>
					{smcUsers.value.length > 0 ? <Required label="SMC on Duty" /> : "SMC on Duty"}
					<BasicSelect
						options={smcUsers.value}
						getText={(u) => u.emailAddress}
						getValue={(u) => String(u.id)}
						value={smcRef.value}
						setValue={(u: User | null) => (smcRef.value = u?.id ?? null)}
						defaultText="N/A"
						disabled={!canEdit()}
						required={smcUsers.value.length > 0}
					/>
				</label>
				<label>
					<Required label="Primary Incident Type" />
					<BasicSelect
						options={coreData.primaryIncidentTypes.toSorted((a, b) => a.name.localeCompare(b.name))}
						value={incidentTypeRef.value}
						setValue={(type) => (incidentTypeRef.value = type.id)}
						defaultText={!canEdit() ? "N/A" : "Select Primary Incident Type"}
						disabled={!canEdit()}
						required
					/>
				</label>

				<label>
					<Required label="Emergency Phase" />
					<BasicSelect
						options={Object.values(EmergencyPhase)}
						value={emergencyPhaseRef.value}
						setValue={refSetter(emergencyPhaseRef)}
						defaultText={!canEdit() ? "N/A" : "Select Emergency Phase"}
						getText={formatEnum}
						disabled={!canEdit()}
						required
					/>
				</label>

				<label>
					<Required label="Persons At Risk" />
					<IncidentInput
						type="number"
						path={changeBuilder.at("personsAtRisk")}
						min={0}
						step={1}
						disabled={!canEdit()}
						required
					/>
				</label>

				<label>
					Name of Vessel or Casualty
					<IncidentInput
						type="text"
						path={changeBuilder.at("nameOfVesselOrCasualty")}
						placeholder={!canEdit() ? "N/A" : "Enter name..."}
						disabled={!canEdit()}
					/>
				</label>
			</AutoColumns>

			<label class="flex">
				<Required label="Message Received" />
				<IncidentTextArea
					path={changeBuilder.at("messageReceived")}
					placeholder={!canEdit() ? "N/A" : "Enter the message received..."}
					rows={3}
					disabled={!canEdit()}
					required
				/>
			</label>
			<label>
				Nature of Incident
				<IncidentTextArea
					path={changeBuilder.at("natureOfIncident")}
					placeholder={!canEdit() ? "N/A" : "Enter the nature of the incident..."}
					rows={3}
					disabled={!canEdit()}
				/>
			</label>
			<label>
				Description of Casualty (if applicable)
				<IncidentTextArea
					path={changeBuilder.at("descriptionOfCasualty")}
					placeholder={!canEdit() ? "N/A" : "Enter the description of the casualty..."}
					rows={2}
					disabled={!canEdit()}
				/>
			</label>
		</div>
	)
}

export default defineComponent(MainDetails)
