import { ContentLoading } from "@/components/loading/StateLoading"
import { IncidentStatus, type SearchIncidentsFilter } from "@/models"
import { css } from "vite-css-in-js"
import { reactive } from "vue"
import { ShadowContainer, defineComponent, ensureLoadingHasResult, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import SearchIncidentsFilters from "../shared/incident-searching/SearchIncidentsFilters"
import { bindIncidentsFilterToQuery } from "../shared/incident-searching/bindIncidentFilters"
import IncidentMap from "./IncidentMap"
import { useFilteredIncidents } from "./useFilteredIncidents"

const pageStyles = css`
	display: grid !important;
	grid-template-columns: auto 18rem;
	gap: 0.5rem;
`

const IncidentMapPage: ReactiveComponent = () => {
	const filter: SearchIncidentsFilter = reactive({
		year: null,
		statuses: [IncidentStatus.Open, IncidentStatus.Closed],
		primaryIncidentTypeIds: [],
		emergencyPhases: [],
	})
	bindIncidentsFilterToQuery(filter)

	const incidents = useFilteredIncidents(filter)

	return () => (
		<BasicPage title="Incident Map" class={pageStyles} plain>
			<ContentLoading stores={[incidents]}>
				<IncidentMap incidents={ensureLoadingHasResult(incidents)} />
				<ShadowContainer style={{ display: "flex" }} class="mb-2 flex-col">
					<h5>Filter Incidents</h5>
					<hr />
					<div class="flex-1 overflow-auto flex flex-col spacing-4">
						<SearchIncidentsFilters filter={filter} />
					</div>
				</ShadowContainer>
			</ContentLoading>
		</BasicPage>
	)
}

export default defineComponent(IncidentMapPage)
