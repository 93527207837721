import { ContentLoading } from "@/components/loading/StateLoading"
import { usePagedFilteredSearching } from "@/composition/usePageFilteredSearching"
import {
	IncidentStatus,
	IncidentsSortBy,
	SortDirection,
	type SearchIncidentsRequest,
	type SearchIncidentsResult,
} from "@/models"
import BasicPage from "@/pages/BasicPage"
import { searchIncidentLogs } from "@/services/incidentsService"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { bindIncidentsFilterToQuery } from "../shared/incident-searching/bindIncidentFilters"
import IncidentsList from "./IncidentsList"

const defaultRequestOptions = (): SearchIncidentsRequest => ({
	page: 1,
	pageSize: 12,
	filter: {
		year: null,
		emergencyPhases: [],
		statuses: [IncidentStatus.Open, IncidentStatus.Closed],
		primaryIncidentTypeIds: [],
	},
	sortBy: IncidentsSortBy.IncidentId,
	sortDirection: SortDirection.Descending,
})

const IncidentLogsPage: ReactiveComponent = () => {
	const { requestOptions, loadableRef, getResults } = usePagedFilteredSearching<
		SearchIncidentsResult,
		SearchIncidentsRequest
	>({
		loadValue: searchIncidentLogs,
		getDefault: defaultRequestOptions,
	})

	bindIncidentsFilterToQuery(requestOptions.filter)

	return () => (
		<BasicPage title="All Incident Logs" class="flex flex-col">
			<ContentLoading stores={[loadableRef]}>
				<IncidentsList request={requestOptions} result={getResults()} />
			</ContentLoading>
		</BasicPage>
	)
}

export default defineComponent(IncidentLogsPage)
