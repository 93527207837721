import type { ApiSchemaType } from "@/models/Schema"
import { castUnsafe } from "vue-utils"
import type { BasePathEntry } from "./BasePath"

export class MemberPath implements BasePathEntry {
	readonly type = "Member"
	readonly member: string

	constructor(member: string) {
		this.member = member
	}

	getValue(object: unknown): unknown {
		return this.ensureIsObject(object)[this.member]
	}

	setValue(object: unknown, newValue: unknown): unknown {
		const record = this.ensureIsObject(object)
		const existing = record[this.member]
		record[this.member] = newValue
		return existing
	}

	addValue(object: unknown, newValue: unknown): void {
		const record = this.ensureIsObject(object)
		record[this.member] = newValue
	}

	deleteValue(object: unknown): unknown {
		const record = this.ensureIsObject(object)
		const current = record[this.member]
		delete record[this.member]
		return current
	}

	resolveSchema<T>(schema: ApiSchemaType<T>): ApiSchemaType<unknown> {
		if (schema.type !== "object") {
			throw new Error(`Expected object schema, got ${schema.type}`)
		}
		const unsafeSchema = castUnsafe<Record<string, ApiSchemaType<unknown>>>(schema.properties)
		if (!(this.member in unsafeSchema)) {
			throw new Error(`Missing schema property '${this.member}'`)
		}
		return unsafeSchema[this.member]
	}

	private ensureIsObject(value: unknown): Record<string, unknown> {
		if (value === null) {
			throw new Error(`Attempting to get member '${this.member}' on null`)
		}
		if (value === undefined) {
			throw new Error(`Attempting to get member '${this.member}' on undefined`)
		}
		return castUnsafe(value)
	}

	toJSON(): string {
		return `.${this.member}`
	}

	static fromJSON(json: string): MemberPath {
		json = json.trim()
		if (!json.startsWith(".")) {
			throw new Error("Invalid member, must start with '.'")
		}
		const member = json.substring(1)
		if (member.length === 0) {
			throw new Error("Invalid member ''")
		}
		return new MemberPath(member)
	}

	get [Symbol.toStringTag]() {
		return `.${this.member}`
	}
}
