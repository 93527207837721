/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Icon from "@/components/Icon"
import { css } from "vite-css-in-js"
import { FormTabControls, ShadowContainer, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import type { IncidentFormSection } from "./IncidentFormSection"

interface Props {
	section: IncidentFormSection
	setSection(sectionId: string): void
	sections: IncidentFormSection[]
}

const navigationStyles = css`
	width: 15rem;
	padding: 0.5rem 0.5rem;

	ul {
		flex-direction: column;
		gap: 0;

		&:after {
			display: none;
		}
	}
	li {
		white-space: nowrap;
		font-size: 1.05rem;
		padding: 0.25rem 0.5rem;
	}
`

const QuickNavigation: ReactiveComponent<Props> = (props) => {
	return () => (
		<ShadowContainer class={navigationStyles}>
			<h5>Quick Navigation</h5>
			<hr class="my-2" />
			<FormTabControls
				selectedTabId={props.section.id}
				setSelectedTab={props.setSection}
				tabs={props.sections.map((tab) => ({
					...tab,
					renderTab: (tabProps) => (
						<li
							key={tab.id}
							data-active={String(tabProps.active)}
							onClick={tabProps.onClick}
							style={{ backgroundColor: tab.color }}
						>
							<div>
								<span class="inline-flex items-center spacing-2">
									<Icon icon={tab.icon} />
									<span>{tab.name}</span>
								</span>
							</div>
						</li>
					),
				}))}
			/>
		</ShadowContainer>
	)
}

export default defineComponent(QuickNavigation, {
	section: requiredProp(Object),
	setSection: requiredProp(Function),
	sections: requiredProp(Array),
})
