import { computed, renderSlot, type HTMLAttributes } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	size: string
	autoFit?: boolean
}

const AutoColumns: ReactiveComponent<Props, HTMLAttributes> = (props, { attrs, slots }) => {
	const fillMode = computed(() => (props.autoFit ? "auto-fit" : "auto-fill"))
	return () => (
		<div
			class="grid"
			style={{ gridTemplateColumns: `repeat(${fillMode.value}, minmax(min(100%, ${props.size}), 1fr))` }}
			{...attrs}
		>
			{renderSlot(slots, "default")}
		</div>
	)
}

export default defineComponent(AutoColumns, {
	size: requiredProp(String),
	autoFit: optionalProp(Boolean),
})
