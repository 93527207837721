import {
	mapApiIncidentLog,
	type Id,
	type IncidentLog,
	type Nullable,
	type SearchIncidentsRequest,
	type SearchIncidentsResult,
} from "@/models"
import { mapApiIncidentLogSummary } from "@/models/IncidentLogSummary"
import { RequestMethod, httpJsonRequestJsonResponse, httpRequestJsonResponse } from "@/services/httpService"

export async function createIncidentLog(log: IncidentLog): Promise<IncidentLog> {
	const createdLog = await httpJsonRequestJsonResponse<IncidentLog>("/api/incident-logs", log, RequestMethod.POST)
	return mapApiIncidentLog(createdLog)
}

export async function getIncidentLogById(id: Id): Promise<Nullable<IncidentLog>> {
	const log = await httpRequestJsonResponse<Nullable<IncidentLog>>(`/api/incident-logs/${id}`)
	return log ? mapApiIncidentLog(log) : null
}

export async function searchIncidentLogs(request: SearchIncidentsRequest): Promise<SearchIncidentsResult> {
	const result = await httpJsonRequestJsonResponse<SearchIncidentsResult>(
		"/api/incident-logs/search",
		request,
		RequestMethod.POST
	)
	return {
		...result,
		incidents: result.incidents.map(mapApiIncidentLogSummary),
	}
}
