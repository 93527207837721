import AutoColumns from "@/components/AutoColumns"
import { proxyProp } from "@/utils/proxyProp"
import { computed } from "vue"
import { defineComponent, useOnInput, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../IncidentLogHandler"
import IncidentInput from "../components/IncidentInput"
import IncidentToggleButton from "../components/IncidentToggleButton"

const regularVHFChannels = [16, 20, 67, 12, 62]

const CallerInformation: ReactiveComponent = () => {
	const handler = useIncidentHandler()

	const changeBuilder = handler.changeBuilder.at("callReceived")
	const info = proxyProp(() => handler.incident.callReceived)

	const vhfSelectValue = computed(() => {
		if (info.VHFChannelOther) {
			return "other"
		} else if (info.VHFChannel === null) {
			return ""
		} else {
			return info.VHFChannel.toString()
		}
	})

	function handleVHFSelect(value: string) {
		const details = getNewVHFChannelDetails(value)
		handler.submitChange(changeBuilder.at("VHFChannel").setTo(details.VHFChannel))
		handler.submitChange(changeBuilder.at("VHFChannelOther").setTo(details.VHFChannelOther))
	}

	function getNewVHFChannelDetails(value: string) {
		if (value === "") {
			return { VHFChannelOther: false, VHFChannel: null }
		} else if (value === "other") {
			return { VHFChannelOther: true, VHFChannel: null }
		} else {
			return { VHFChannelOther: false, VHFChannel: Number.parseInt(value) }
		}
	}

	function fixMMSI(event: Event) {
		const input = event.target as HTMLInputElement
		const mmsi = input.value
		const newMmsi = Array.from(mmsi)
			.filter((ch) => /[a-z0-9]/i.test(ch))
			.join("")
			.toUpperCase()
			.trim()
		input.value = newMmsi
		return newMmsi
	}

	return () => (
		<div class="flex flex-col spacing-4">
			<AutoColumns size="16rem" class="spacing-4">
				<label>
					Call Made Via DSC?
					<IncidentToggleButton path={changeBuilder.at("DSC")} disabled={handler.readOnly} />
				</label>
				<label>
					Call Made Via EPIRB?
					<IncidentToggleButton path={changeBuilder.at("EPIRB")} disabled={handler.readOnly} />
				</label>
				<label>
					Call Made Via 999?
					<IncidentToggleButton path={changeBuilder.at("emergency999")} disabled={handler.readOnly} />
				</label>
			</AutoColumns>
			<AutoColumns size="16rem" class="spacing-4">
				<label>
					VHF Channel
					<select value={vhfSelectValue.value} onChange={useOnInput(handleVHFSelect)} disabled={handler.readOnly}>
						<option key="" value="">
							-
						</option>
						{regularVHFChannels.map((ch) => (
							<option key={ch} value={ch.toString()}>
								{ch}
							</option>
						))}
						<option key="other" value="other">
							Other Channel Not Listed
						</option>
					</select>
				</label>
				<label>
					MMSI (If known)
					<IncidentInput
						type="text"
						path={changeBuilder.at("MMSI")}
						placeholder={handler.readOnly ? "N/A" : "Enter MMSI..."}
						onInput={fixMMSI}
						maxlength={9}
						disabled={handler.readOnly}
					/>
				</label>
				<label>
					Call Sign (If Known)
					<IncidentInput
						type="text"
						path={changeBuilder.at("callSign")}
						placeholder={handler.readOnly ? "N/A" : "Enter Call Sign..."}
						disabled={handler.readOnly}
					/>
				</label>
				<label>
					Phone Number of Caller
					<IncidentInput
						type="text"
						path={changeBuilder.at("phoneNumber")}
						placeholder={handler.readOnly ? "N/A" : "Enter Caller Phone Number..."}
						disabled={handler.readOnly}
					/>
				</label>
				<label>
					Name of Caller
					<IncidentInput
						type="text"
						path={changeBuilder.at("callerName")}
						placeholder={handler.readOnly ? "N/A" : "Enter caller Name..."}
						disabled={handler.readOnly}
					/>
				</label>
			</AutoColumns>
		</div>
	)
}

export default defineComponent(CallerInformation)
