import { ContentLoading } from "@/components/loading/StateLoading"
import { SystemRole, type IncidentLog } from "@/models"
import BasicPage from "@/pages/BasicPage"
import { createCoreDataRefreshRef, useLoggedInUser } from "@/stores/coreDataStore"
import { reactive } from "vue"
import { defineComponent, ensureLoadingHasResult, useLoadableRef, type ReactiveComponent } from "vue-utils"
import AuthorizeRole from "../AuthorizeRole"
import CreateIncidentLog from "./CreateIncidentLog"
import { createBlankTemplateIncidentLog } from "./createBlank"

const CreateIncidentPage: ReactiveComponent = () => {
	const coreDataRefresh = createCoreDataRefreshRef()

	const loggedInUser = useLoggedInUser()
	const incidentLog = useLoadableRef(async () => {
		const template = await createBlankTemplateIncidentLog(loggedInUser)

		//Convert to reactive so form downstream can edit values and have Vue track the changes
		return reactive(template) as IncidentLog
	})

	return () => (
		<AuthorizeRole role={SystemRole.CoastguardOfficer}>
			<BasicPage title="Create New Incident" plain scroll={false}>
				<ContentLoading stores={[incidentLog, coreDataRefresh]}>
					<CreateIncidentLog incident={ensureLoadingHasResult(incidentLog)} />
				</ContentLoading>
			</BasicPage>
		</AuthorizeRole>
	)
}

export default defineComponent(CreateIncidentPage)
