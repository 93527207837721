import type { IncidentEntity } from "./IncidentEntity"
import type { ApiSchema } from "./Schema"
import { UserTimeStampSchema, type UserTimeStamp } from "./UserTimeStamp"

export interface NoteEntry extends IncidentEntity {
	text: string
	creationMetadata: UserTimeStamp
}

export const NoteEntrySchema: ApiSchema<NoteEntry> = {
	type: "object",
	properties: {
		id: Number,
		text: String,
		creationMetadata: UserTimeStampSchema,
	},
}
