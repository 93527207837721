/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import type { Nullable } from "@/models"
import { css } from "vite-css-in-js"
import { type HTMLAttributes, type VNodeChild } from "vue"
import { defineComponent, optionalProp, propsWithDefaults, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	options: string[]
	renderOption?: (option: string) => VNodeChild

	noOptionsText: string

	highlightedOption: Nullable<string>
	setHighlightedOption(option: Nullable<string>): void
	selectOption(option: string): void
}

const dropdownStyles = css`
	position: absolute;
	background-color: #fafafa;
	box-shadow: 0.25rem 0.25rem 0.25rem #a0a0a0;
	top: 100%;
	left: 0;
	right: 0;
	border-radius: 0 0 0.35rem 0.35rem;
	border: 1px solid rgba(0, 0, 0, 0.35);
	overflow-y: auto;
	z-index: 1;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	button {
		all: unset;
		width: 100%;
		max-width: calc(100% - 1rem);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		padding: 0.25rem 0.5rem;

		&[data-selected="true"] {
			cursor: pointer;
			background-color: #d3d3d3;
		}
	}
`

const DropdownBox: ReactiveComponent<Props, HTMLAttributes> = (initialProps, { attrs }) => {
	const props = propsWithDefaults(initialProps, {
		renderOption: (option) => option,
	})

	return () => (
		<div class={dropdownStyles} {...attrs}>
			<ul onMousedown={(e) => e.preventDefault()} onMouseleave={() => props.setHighlightedOption(null)}>
				{props.options.length === 0 && (
					<li>
						<button tabindex={-1} disabled>
							<i>{props.noOptionsText}</i>
						</button>
					</li>
				)}
				{props.options.map((option) => (
					<li key={option} title={option} onMouseenter={() => props.setHighlightedOption(option)}>
						<button
							tabindex={-1}
							onClick={(e) => e.button === 0 && props.selectOption(option)}
							data-selected={String(props.highlightedOption === option)}
						>
							{props.renderOption(option)}
						</button>
					</li>
				))}
			</ul>
		</div>
	)
}

export default defineComponent(DropdownBox, {
	options: requiredProp(Array),
	renderOption: optionalProp(Function),

	noOptionsText: requiredProp(String),

	highlightedOption: requiredProp(String, null),
	setHighlightedOption: requiredProp(Function),
	selectOption: requiredProp(Function),
})
