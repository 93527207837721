import { onMounted, onUnmounted, reactive, type InjectionKey } from "vue"
import { useService, type Component } from "vue-utils"

export const AppHeaderInjectionKey = Symbol("AppHeader") as InjectionKey<AppHeaderHandler>

export function useAppHeader() {
	return useService(AppHeaderInjectionKey)
}

export function useCustomAppHeaderRender(component: Component) {
	const handler = useAppHeader()

	let symbol: symbol | null = null

	onMounted(() => {
		symbol = handler.registerComponent(component)
	})
	onUnmounted(() => {
		if (symbol !== null) {
			handler.unregisterComponent(symbol)
		}
	})
}

export class AppHeaderHandler {
	readonly componentsMap = reactive(new Map<symbol, Component>())

	get customComponents(): Component[] {
		return Array.from(this.componentsMap.values())
	}

	registerComponent(component: Component): symbol {
		const id = Symbol()
		this.componentsMap.set(id, component)
		return id
	}

	getComponent(id: symbol): Component | undefined {
		return this.componentsMap.get(id)
	}

	unregisterComponent(id: symbol): boolean {
		return this.componentsMap.delete(id)
	}
}
