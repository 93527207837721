import type { AddItemAction, ModificationAction, RemoveFieldAction, SetFieldValueAction } from "."
import { resolvePath, type ObjectPath } from "./path"

export function applyModification(action: ModificationAction, rootValue: unknown) {
	switch (action.type) {
		case "Add":
			return applyAddItem(action, rootValue)
		case "Set":
			return applySetField(action, rootValue)
		case "Remove":
			return applyRemoveField(action, rootValue)
	}
	throw new Error("Unknown modification action type")
}

export function applyAddItem(action: AddItemAction, rootValue: unknown) {
	const subPath = getSubPath(action.path)
	const toAddOn = resolvePath(subPath, rootValue)

	return action.path.at(-1)!.addValue(toAddOn, action.value)
}

/**
 * @returns The existing value before being set
 */
export function applySetField(action: SetFieldValueAction, rootValue: unknown): unknown {
	const subPath = getSubPath(action.path)
	const toSetOn = resolvePath(subPath, rootValue)

	return action.path.at(-1)!.setValue(toSetOn, action.newValue)
}

/**
 * @returns The existing value before being deleted
 */
export function applyRemoveField(action: RemoveFieldAction, rootValue: unknown): unknown {
	const subPath = getSubPath(action.path)
	const toDeleteOn = resolvePath(subPath, rootValue)

	return action.path.at(-1)!.deleteValue(toDeleteOn)
}

function getSubPath(path: ObjectPath) {
	if (path.length === 0) {
		throw new Error("Cannot run actions value directly (path length = 0)")
	}
	return path.slice(0, path.length - 1)
}
