import Icon from "@/components/Icon"
import type { Nullable } from "@/models"
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { computed, ref } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import EditableField from "../EditableField"
import EditTaskingInstructions from "./EditTaskingInstructions"

interface Props {
	instructions: Nullable<string>
	setInstructions(instructions: string): Promise<void>
}

const existingInstructionsStyles = css`
	position: relative;
	cursor: pointer;

	svg {
		color: #003e8c;
		font-size: 1.75rem;
	}

	span:first-child {
		position: absolute;
		width: 1.25rem;
		height: 1.25rem;
		font-size: 1.125rem;
		border-radius: 100%;
		color: white;
		background-color: var(--color-error);
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid black;
		font-weight: bold;

		top: -0.75rem;
		right: -0.75rem;
	}
`

const TaskingInstructionsCell: ReactiveComponent<Props> = (props) => {
	const editing = ref(false)
	const hasInstructions = computed(() => props.instructions !== null && props.instructions.trim().length > 0)

	return () => (
		<>
			<EditableField
				hasValue={!!props.instructions}
				edit={() => (editing.value = true)}
				showOnReadOnly={hasInstructions.value}
			>
				<div class="flex items-center justify-center">
					{hasInstructions.value ? (
						<div class={existingInstructionsStyles}>
							<span>!</span>
							<Icon icon={faClipboardCheck} />
						</div>
					) : (
						"-"
					)}
				</div>
			</EditableField>

			{editing.value && (
				<EditTaskingInstructions
					instructions={props.instructions ?? ""}
					cancel={() => (editing.value = false)}
					setInstructions={async (instructions) => {
						await props.setInstructions(instructions)
						editing.value = false
					}}
				/>
			)}
		</>
	)
}

export default defineComponent(TaskingInstructionsCell, {
	instructions: requiredProp(String, null),
	setInstructions: requiredProp(Function),
})
