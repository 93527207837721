import { Instant } from "@js-joda/core"
import { InstantSchema, type ApiSchema } from "../Schema"

export interface TideWaterMark {
	time: Instant
	height: number
}

export const TideWaterMarkSchema: ApiSchema<TideWaterMark> = {
	type: "object",
	properties: {
		time: InstantSchema,
		height: Number,
	},
}

export interface Tide {
	lowWater: TideWaterMark
	highWater: TideWaterMark
}

export const TideSchema: ApiSchema<Tide> = {
	type: "object",
	properties: {
		lowWater: TideWaterMarkSchema,
		highWater: TideWaterMarkSchema,
	},
}

export enum TideState {
	Rising = "Rising",
	Falling = "Falling",
}

export function getTideState(tide: Tide) {
	return tide.lowWater.time.compareTo(tide.highWater.time) > 0 ? TideState.Falling : TideState.Rising
}

export function getPreviousWaterMark(tide: Tide) {
	return tide.lowWater.time.compareTo(tide.highWater.time) < 0 ? tide.lowWater : tide.highWater
}

export function getNextWaterMark(tide: Tide) {
	return tide.lowWater.time.compareTo(tide.lowWater.time) >= 0 ? tide.highWater : tide.lowWater
}
