import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import InstantInput from "@/components/form/InstantInput"
import MapLocationSelect from "@/components/mapping/MapLocationSelect"
import { proxyProp } from "@/utils/proxyProp"
import { faLocationDot } from "@fortawesome/free-solid-svg-icons"
import { ref } from "vue"
import { Required, defineComponent, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../IncidentLogHandler"
import IncidentCoordinateInput from "../components/IncidentCoordinateInput"

const IncidentLocation: ReactiveComponent = () => {
	const handler = useIncidentHandler()
	const showMap = ref(false)

	const incident = proxyProp(() => handler.incident)

	const changeBuilder = handler.changeBuilder

	function disabled() {
		return handler.readOnly || !handler.isNewIncident
	}

	return () => (
		<div class="inline-flex flex-col spacing-4">
			<label>
				Position Received At
				<InstantInput
					value={incident.positionReceivedAt ?? incident.incidentTime}
					setValue={(newInstant) => handler.submitChange(changeBuilder.at("positionReceivedAt").setTo(newInstant))}
					min={incident.incidentTime}
					disabled={disabled()}
					required
				/>
			</label>
			<div class="flex spacing-8 items-center flex-wrap">
				<label class="flex-grow-1">
					<Required label="Incident Latitude" />
					<IncidentCoordinateInput
						type="latitude"
						path={changeBuilder.at("position").at("latitude")}
						disabled={disabled()}
						required
					/>
				</label>
				<label class="flex-grow-1">
					<Required label="Incident Longitude" />
					<IncidentCoordinateInput
						type="longitude"
						path={changeBuilder.at("position").at("longitude")}
						disabled={disabled()}
						required
					/>
				</label>
			</div>

			<BootstrapButton color="success" type="button" onClick={() => (showMap.value = true)}>
				<Icon icon={faLocationDot} />
				<span>{disabled() ? "View Location on Map" : "Pick Location From Map"}</span>
			</BootstrapButton>
			{showMap.value && (
				<MapLocationSelect
					locationName="Incident Location"
					location={
						!incident.position ||
						!Number.isFinite(incident.position.latitude) ||
						!Number.isFinite(incident.position.longitude)
							? null
							: incident.position
					}
					setLocation={(loc) => {
						const action = changeBuilder.at("position").setTo(loc)
						handler.submitChange(action)
						showMap.value = false
					}}
					cancel={() => (showMap.value = false)}
					readOnly={disabled()}
				/>
			)}
		</div>
	)
}

export default defineComponent(IncidentLocation)
