import type { Nullable } from "@/models"
import { watchImmediate } from "@vueuse/core"
import { css } from "vite-css-in-js"
import { ref, shallowRef, toRef } from "vue"
import {
	defineComponent,
	optionalProp,
	requiredProp,
	useInputValidation,
	useOnInput,
	type ReactiveComponent,
} from "vue-utils"

export type CoordinateType = "latitude" | "longitude"

interface Props {
	type: "latitude" | "longitude"

	value: Nullable<number>
	setValue(value: number): void

	required?: boolean
	disabled?: boolean
}

const PRECISION = 3

const fieldSetStyles = css`
	display: inline-grid;
	align-items: stretch;

	grid-template-columns: 4rem auto 6rem auto auto;
	gap: 0.125rem;

	& > span {
		display: flex;
		justify-content: start;
		font-size: 1.5rem;
	}

	/* Used to disable the spinner arrows */
	& > input {
		//Firefox
		-moz-appearance: textfield;

		//Chromium
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
`

function isNumber(val: unknown): val is number {
	return typeof val === "number" && Number.isFinite(val)
}
function calculateDegrees(value: Nullable<number>) {
	if (!isNumber(value)) {
		return ""
	}
	return Math.trunc(Math.abs(value)).toString()
}
function calculateDecimalMinutes(value: Nullable<number>) {
	if (!isNumber(value)) {
		return ""
	}
	const degrees = Math.trunc(Math.abs(value))
	const deciMinutes = (Math.abs(value) - degrees) * 60
	return deciMinutes.toFixed(PRECISION)
}
function calculateUnit(value: Nullable<number>, type: CoordinateType) {
	if (!isNumber(value)) {
		return type === "latitude" ? 1 : -1 //N & W, normal for Guernsey
	}
	return value <= 0 ? -1 : 1
}

/**
 * A DDM (degrees & decimal minutes) input for geo coordinates
 */
const CoordinateInput: ReactiveComponent<Props> = (props) => {
	const maxDegrees = () => (props.type === "latitude" ? 90 : 180)
	const positiveUnit = () => (props.type === "latitude" ? "N" : "E")
	const negativeUnit = () => (props.type === "latitude" ? "S" : "W")
	const isMaximum = () => Number.parseInt(degrees.value) === maxDegrees()

	const degreesInputRef = shallowRef<HTMLInputElement>()

	const degrees = ref("")
	const decimalMinutes = ref("")
	const unit = ref(1)

	watchImmediate(toRef(props, "value"), (newValue) => {
		degrees.value = calculateDegrees(newValue)
		decimalMinutes.value = calculateDecimalMinutes(newValue)
		unit.value = calculateUnit(newValue, props.type)
	})

	function handleBlur() {
		const newValue = calculateNewValue()
		if (newValue !== null) {
			props.setValue(newValue)
		}
	}

	function calculateNewValue() {
		const degs = Number.parseInt(degrees.value)
		const deciMins = Number.parseFloat(decimalMinutes.value)

		if (!Number.isSafeInteger(degs) || !isNumber(deciMins)) {
			return null
		}
		if (degs < 0 || degs > maxDegrees()) {
			return null
		}
		if (deciMins < 0 || deciMins > 60) {
			return null
		}

		const newValue = (Math.abs(degs) + Math.abs(deciMins) / 60) * unit.value
		if (isNumber(newValue) && newValue >= -maxDegrees() && newValue <= maxDegrees()) {
			return newValue
		}
		return null
	}

	useInputValidation(degreesInputRef, () => {
		if (calculateNewValue() === null) {
			return `Please enter a valid ${props.type}`
		}
		return true
	})

	return () => (
		<fieldset class={fieldSetStyles}>
			<input
				ref={degreesInputRef}
				type="number"
				v-model={degrees.value}
				placeholder="Deg"
				step={1}
				min={0}
				max={maxDegrees()}
				onBlur={handleBlur}
				required={props.required}
				disabled={props.disabled}
			/>
			<span>°</span>
			<input
				type="number"
				v-model={decimalMinutes.value}
				placeholder="Minutes"
				step={`0.${"0".repeat(PRECISION - 1)}1`}
				min={0}
				max={isMaximum() ? 0 : 60}
				onBlur={handleBlur}
				required={props.required}
				disabled={props.disabled}
			/>
			<span>'</span>

			<select
				value={unit.value}
				onInput={useOnInput((v) => (unit.value = Number.parseInt(v)))}
				onBlur={handleBlur}
				required={props.required}
				disabled={props.disabled}
			>
				<option value={1}>{positiveUnit()}</option>
				<option value={-1}>{negativeUnit()}</option>
			</select>
		</fieldset>
	)
}

export default defineComponent(CoordinateInput, {
	type: requiredProp(String),

	value: requiredProp(Number),
	setValue: requiredProp(Function),

	required: optionalProp(Boolean),
	disabled: optionalProp(Boolean),
})
