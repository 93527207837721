import AutoColumns from "@/components/AutoColumns"
import BasicSelect from "@/components/form/BasicSelect"
import { type AssetTasked, type Nullable } from "@/models"
import { useCoreDataStore } from "@/stores/coreDataStore"
import type { Immutable } from "@/utils/Immutable"
import { computed, shallowReactive } from "vue"
import { Required, castUnsafe, defineComponent, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../../IncidentLogHandler"
import EditValueModal from "../EditValueModal"
import type { EditSRUData } from "./SRUCell"

interface Props {
	existing: Nullable<Immutable<AssetTasked>>

	cancel(): void
	save(data: EditSRUData): Promise<void>
}

const EditSRU: ReactiveComponent<Props> = (props) => {
	const handler = useIncidentHandler()
	const coreDataStore = useCoreDataStore()

	const data = shallowReactive({
		SRUId: props.existing?.SRUId ?? castUnsafe<number>(null),
		customName: props.existing?.customName ?? null,
	})

	const availableUnits = computed(() => {
		const units = coreDataStore.data().searchAndRescueUnits
		return [...units].sort((u1, u2) => {
			if (u1.isCustom && !u2.isCustom) return 1
			if (!u1.isCustom && u2.isCustom) return -1
			return 0
		})
	})

	const selectedUnit = computed(() => {
		const id = data.SRUId
		return id === null ? null : coreDataStore.lookupSUR(id)
	})

	const title = computed(() => {
		if (props.existing === null) {
			return "Add Asset"
		}
		if (handler.readOnly) {
			return "View SRU"
		}
		return "Edit SRU"
	})

	const showCustomName = () => !!selectedUnit.value && selectedUnit.value.isCustom

	async function handleSubmit() {
		if (!selectedUnit.value) {
			return
		}

		const previousHasCrew =
			props.existing === null ? false : coreDataStore.lookupSUR(props.existing.SRUId)?.totalCrew !== null
		const newHasCrew = selectedUnit.value.totalCrew !== null

		const editData: EditSRUData = {
			SRUId: data.SRUId,
			customName: selectedUnit.value.isCustom ? data.customName : null,
		}
		if (previousHasCrew !== newHasCrew) {
			editData.crewLists = newHasCrew ? [] : null
		}
		await props.save(editData)
	}

	return () => (
		<EditValueModal cancel={props.cancel} save={handleSubmit} title={title.value}>
			<AutoColumns size="16rem" class="spacing-4" style={{ width: "40rem" }}>
				<label>
					<Required label="Select Asset" />
					<BasicSelect
						options={availableUnits.value}
						value={data.SRUId}
						setValue={(unit) => (data.SRUId = unit.id)}
						defaultText="Please select an asset"
						disabled={handler.readOnly}
						required
					/>
				</label>
				{showCustomName() && (
					<label>
						<Required label="Asset Name" />
						<input
							type="text"
							value={data.customName}
							onInput={useOnInput((newName) => (data.customName = newName))}
							disabled={handler.readOnly}
							required
						/>
					</label>
				)}
			</AutoColumns>
		</EditValueModal>
	)
}

export default defineComponent(EditSRU, {
	cancel: requiredProp(Function),
	save: requiredProp(Function),

	existing: requiredProp(Object, null),
})
