import BootstrapButton from "@/components/form/BootstrapButton"
import SimpleForm from "@/components/form/SimpleForm"
import { defineStore } from "pinia"
import { ConfirmResult, createPopups, renderSlot, type AlertPopup, type ConfirmPopup, type Popup } from "vue-utils"

export { ConfirmResult }
export type { AlertPopup, ConfirmPopup, Popup }

const usePopups = defineStore("popups", () => {
	return createPopups({
		AlertPopup: ({ okText, onClose }, { slots }) => (
			<SimpleForm onSubmit={onClose}>
				{renderSlot(slots)}
				<hr />
				<div class="flex justify-end">
					<div class="flex flex-wrap items-center spacing-3">
						<BootstrapButton color="secondary" type="submit">
							{okText}
						</BootstrapButton>
					</div>
				</div>
			</SimpleForm>
		),
		ConfirmPopup: ({ confirmText, cancelText, onSubmit, onCancel }, { slots }) => (
			<SimpleForm onSubmit={onSubmit}>
				{renderSlot(slots)}
				<hr />
				<div class="flex justify-end">
					<div class="flex flex-wrap items-center spacing-3">
						<BootstrapButton color="secondary" type="button" onClick={onCancel}>
							{cancelText}
						</BootstrapButton>
						<BootstrapButton color="primary" type="submit">
							{confirmText}
						</BootstrapButton>
					</div>
				</div>
			</SimpleForm>
		),
	})
})

export default usePopups
