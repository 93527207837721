const A = "A".charCodeAt(0)
const Z = "Z".charCodeAt(0)

function isUpperCase(str: string, index: number) {
	return str.charCodeAt(index) >= A && str.charCodeAt(index) <= Z
}

export function formatEnum(enumValue: string): string {
	if (!enumValue?.length) {
		return ""
	}
	if (Array.from(enumValue).every((ch) => isUpperCase(ch, 0))) {
		return enumValue
	}

	function readUpper() {
		while (i < enumValue.length && isUpperCase(enumValue, i)) {
			i++
		}
	}
	function readLower() {
		while (i < enumValue.length && !isUpperCase(enumValue, i)) {
			i++
		}
	}

	const parts: string[] = []
	let i = 1

	readLower()
	parts.push(enumValue.charAt(0).toUpperCase() + enumValue.substring(1, i))

	while (i < enumValue.length) {
		const start = i
		readUpper()
		readLower()
		parts.push(enumValue.substring(start, i))
	}

	return parts.join(" ")
}
