import { ChangeBuilder } from "@/modifications"
import type { TextareaHTMLAttributes } from "vue"
import { defineComponent, refSetter, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"
import { useIncidentAutoSubmitInputRef } from "../inputHelpers"

interface Props {
	path: ChangeBuilder<string | null>
}

const IncidentTextArea: ReactiveComponent<Props, Omit<TextareaHTMLAttributes, "value">> = (props, { attrs }) => {
	const inputRef = useIncidentAutoSubmitInputRef(() => props.path, { debounced: true })

	return () => <textarea value={inputRef.value} onInput={useOnInput(refSetter(inputRef))} {...attrs} />
}

export default defineComponent(IncidentTextArea, {
	path: requiredProp(ChangeBuilder),
})
