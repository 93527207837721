import L, { type ControlPosition, type Map, type MapOptions } from "leaflet"
import { onMounted, onUnmounted, ref, shallowRef, type HTMLAttributes } from "vue"
import { defineComponent, optionalProp, type ReactiveComponent } from "vue-utils"

interface Props {
	mapOptions?: MapOptions
	zoomControlPosition?: ControlPosition
	initMap?: (map: Map, container: HTMLElement) => void
}

const BaseMap: ReactiveComponent<Props, HTMLAttributes> = (props, { attrs }) => {
	const mapContainerRef = ref<HTMLDivElement>()

	const mapRef = shallowRef<Map | null>(null)
	const resizeObserver = new ResizeObserver((entries) => {
		if (!entries.every((entry) => entry.contentRect.width > 0 && entry.contentRect.height > 0)) {
			return
		}
		if (entries.some((entry) => entry.contentRect.width > 50_000 || entry.contentRect.height > 50_000)) {
			return
		}

		if (mapRef.value) {
			mapRef.value.invalidateSize()
		} else {
			initMap()
		}
	})

	function initMap() {
		const container = mapContainerRef.value!
		if (!container || mapRef.value) {
			return
		}

		const map = L.map(container, props.mapOptions)
		mapRef.value = map

		L.Icon.Default.imagePath = "/leaflet/"
		props.initMap?.(map, container)

		if (props.mapOptions?.zoomControl && props.zoomControlPosition)
			map.zoomControl.setPosition(props.zoomControlPosition)
	}

	onMounted(() => {
		const container = mapContainerRef.value!
		resizeObserver.observe(container)
	})

	onUnmounted(() => {
		mapRef.value?.remove()
		resizeObserver.disconnect()
	})

	return () => <div ref={mapContainerRef} {...attrs} />
}

export default defineComponent(BaseMap, {
	initMap: optionalProp(Function),
	mapOptions: optionalProp(Object),
	zoomControlPosition: optionalProp(String),
})
