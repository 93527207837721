import { AppAuthContext } from "@/authentication"
import AnchorButton from "@/components/form/AnchorButton"
import Icon from "@/components/Icon"
import { useLoggedInUser } from "@/stores/coreDataStore"
import { formatEnum } from "@/utils/formatEnum"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import LogoutButton from "./LogoutButton"

const gridStyles = css`
	* {
		text-align: left;
	}
	th {
		font-weight: 600;
		padding-right: 1rem;
	}
	td {
		font-weight: normal;
	}
`

const ProfilePage: ReactiveComponent = () => {
	const user = useLoggedInUser()

	return () => (
		<BasicPage title="Profile Information" scroll={false}>
			<table class={gridStyles}>
				<tbody>
					<tr>
						<th>Name:</th>
						<td>
							{user.firstName} {user.lastName}
						</td>
					</tr>
					<tr>
						<th>Email: </th>
						<td>{user.emailAddress}</td>
					</tr>
					<tr>
						<th>Role:</th>
						<td>{formatEnum(user.role)}</td>
					</tr>
					<tr>
						<th>User Id: </th>
						<td>{user.guid}</td>
					</tr>
				</tbody>
			</table>
			<br />
			<div class="inline-flex flex-wrap spacing-4">
				<AnchorButton color="success" href={AppAuthContext.getKeycloakManageUrl()} nonRoute>
					<Icon icon={faUser} />
					<span>Manage Account</span>
				</AnchorButton>
				<LogoutButton />
			</div>
		</BasicPage>
	)
}

export default defineComponent(ProfilePage)
