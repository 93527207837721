import { Instant } from "@js-joda/core"
import { castUnsafe } from "vue-utils"
import type { ApiTypeOf } from "./ApiType"

export type ApiSchema<T> = ApiSchemaType<NonNullable<T>>
export type ApiSchemaType<T> = ApiSchemaArray<T> | ApiSchemaObject<T> | ApiSchemaConverter<T>

export interface ApiSchemaArray<T> {
	type: "array"
	schema: ApiSchema<[T] extends [(infer U)[]] ? U : never>
}

export interface ApiSchemaObject<T> {
	type: "object"
	properties: {
		readonly [K in keyof T]: ApiSchema<T[K]>
	}
}

export interface ApiSchemaConverter<T> {
	type?: "converter"
	(value: ApiTypeOf<T>): T
}

export const InstantSchema: ApiSchema<Instant> = (value) => Instant.parse(value)

export function enumOf<T extends string>(enumValues: Record<string, T>): ApiSchemaConverter<T> {
	return (value) => {
		if (!(value in enumValues)) {
			throw new Error(`Invalid enum value '${value}'`)
		}
		return value as T
	}
}

export function mapApiType<T>(schema: ApiSchema<T>, value: ApiTypeOf<T>): T {
	if (value === null || value === undefined) {
		return value as T
	}

	if ("type" in schema) {
		switch (schema.type) {
			case "converter":
				return schema(value as ApiTypeOf<NonNullable<T>>)
			case "array":
				return mapApiArray(castUnsafe<ApiSchemaArray<unknown>>(schema.schema), castUnsafe(value)) as T
			case "object":
				return mapApiObject(schema, value)
		}

		throw new Error("Unknown schema type")
	}

	return schema(value as ApiTypeOf<NonNullable<T>>)
}

function mapApiSchema<T>(schema: ApiSchemaType<T>, value: ApiTypeOf<T>): T {
	return mapApiType(castUnsafe(schema), value)
}

function mapApiArray<T>(schema: ApiSchemaArray<T>, value: ApiTypeOf<T>[]): T[] {
	return value.map((entry) => mapApiSchema<T>(schema, entry))
}

function mapApiObject<T>(schema: ApiSchemaObject<T>, value: ApiTypeOf<T>): T {
	const result: Record<string, string> = {}
	for (const propertyName in schema.properties) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const propertySchema = castUnsafe<ApiSchemaType<string>>(schema.properties[propertyName])
		const existingValue = castUnsafe<Record<string, string>>(value)[propertyName]

		result[propertyName] = mapApiSchema(propertySchema, existingValue)
	}
	return castUnsafe(result)
}
