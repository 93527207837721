import InstantInput from "@/components/form/InstantInput"
import { ChangeBuilder } from "@/modifications"
import { Instant } from "@js-joda/core"
import type { InputHTMLAttributes } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../IncidentLogHandler"
import { useIncidentValue } from "../inputHelpers"

interface Props {
	path: ChangeBuilder<Instant>
	min?: Instant
	max?: Instant
}

const IncidentInput: ReactiveComponent<Props, InputHTMLAttributes> = (props, { attrs }) => {
	const handler = useIncidentHandler()
	const existingValue = useIncidentValue(props.path)

	function saveChanges(instant: Instant) {
		handler.submitChange(props.path.setTo(instant))
	}

	return () => (
		<InstantInput
			value={existingValue.value}
			setValue={saveChanges}
			min={props.min}
			max={props.max}
			{...attrs}
			required
		/>
	)
}

export default defineComponent(IncidentInput, {
	path: requiredProp(ChangeBuilder),
	min: optionalProp(Instant),
	max: optionalProp(Instant),
})
