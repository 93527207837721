import { AppAuthContext } from "@/authentication"
import { ContentLoading } from "@/components/loading/StateLoading"
import { useCustomAppHeaderRender } from "@/components/titlebar/useAppHeader"
import {
	IncidentLogSchema,
	User,
	mapApiIncidentLog,
	type ApiTypeOf,
	type Id,
	type IncidentLog,
	type UserDetails,
} from "@/models"
import { mapApiType } from "@/models/Schema"
import { createModificationActionSchema, type ModificationAction } from "@/modifications"
import { useCoreDataStore, useLoggedInUser } from "@/stores/coreDataStore"
import { Deferred } from "@/utils/Deferred"
import { HubConnectionBuilder, HubConnectionState, LogLevel, type IHttpConnectionOptions } from "@microsoft/signalr"
import { css } from "vite-css-in-js"
import { onUnmounted, ref, shallowRef, type Ref } from "vue"
import {
	assignLoadable,
	defineComponent,
	defineGlobals,
	refSetter,
	requiredProp,
	useCustomFormValidation,
	useLoadableRef,
	type ReactiveComponent,
} from "vue-utils"
import IncidentForm from "../shared/incident-form/IncidentForm"
import type { ActionError } from "./ActionError"
import ConnectedUsers from "./ConnectedUsers"
import DisconnectedMessage from "./DisconnectedMessage"
import { EditLiveIncidentHandler } from "./EditIncidentHandler"

interface Props {
	incidentId: Id
	refresh(): void
}

const ConnectionOptions: IHttpConnectionOptions = {
	withCredentials: false,
	async accessTokenFactory() {
		try {
			const token = await AppAuthContext.getBearerToken()
			if (token) {
				return token
			}
		} catch (e) {
			console.error(e)
		}
		return ""
	},
}

const pageStyles = css`
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 0.5rem;

	@media print {
		display: contents;
	}
`

const EditExistingIncident: ReactiveComponent<Props> = (props) => {
	const loggedInUser = useLoggedInUser()
	const coreData = useCoreDataStore()

	const urlBase = window.location.hostname === "localhost" ? "http://localhost:5171" : window.location.origin
	const connection = new HubConnectionBuilder()
		.withUrl(`${urlBase}/api/incident-logs/ws/${props.incidentId}`, ConnectionOptions)
		.configureLogging(window.location.hostname === "localhost" ? LogLevel.Debug : LogLevel.Warning)
		.build()

	let alreadyTriedLoadingIncident = false
	const incidentDeferred = new Deferred<IncidentLog>()

	const connectionLoadableRef = useLoadableRef(async () => {
		if (connection.state !== HubConnectionState.Connected) {
			await connection.start()
		}

		if (alreadyTriedLoadingIncident) {
			props.refresh()
		}
		alreadyTriedLoadingIncident = true
		await incidentDeferred
		isConnected.value = true
	})

	const schema = createModificationActionSchema(IncidentLogSchema)

	const isConnected = ref(false)
	const viewingUsers = ref<User[]>([])
	const incidentRef = ref<IncidentLog | null>(null) as Ref<IncidentLog | null>

	const formRef = shallowRef<HTMLFormElement>()
	const formValidation = useCustomFormValidation({ form: formRef })
	const handler = new EditLiveIncidentHandler({
		connection,
		incidentRef,
		isConnected,
		loggedInUser,
		validationHandler: formValidation,
		getBearer: async () => (await AppAuthContext.getBearerToken()) ?? "",
	})

	connection.on("UpdateIncident", (data: ApiTypeOf<IncidentLog>) => {
		const incident = mapApiIncidentLog(data)
		incidentRef.value = incident
		incidentDeferred.resolve(incident)
	})

	connection.on("RunAction", (data: ApiTypeOf<ModificationAction>) => {
		if (incidentRef.value) {
			const actualAction = mapApiType(schema, data)
			handler.onReceivedAction(actualAction)
		}
	})

	connection.on("ShowError", (data: ActionError) => {
		if (connectionLoadableRef.type === "loading") {
			assignLoadable(connectionLoadableRef, { type: "error", error: new Error(data.message) })
		} else {
			handler.onReceivedError(new Error(data.message), data.actionId)
		}
	})

	connection.on("ActionSuccess", (actionId: string) => {
		handler.onReceivedSuccess(actionId)
	})

	connection.on("UserConnected", (user: UserDetails) => {
		if (!viewingUsers.value.some((u) => u.id == user.id)) {
			viewingUsers.value.push(new User(user))
		}
		coreData.data().users.set(user.id, new User(user))
	})

	connection.on("UserDisconnected", (user: UserDetails) => {
		viewingUsers.value = viewingUsers.value.filter((u) => u.id !== user.id)
	})

	connection.onclose((error) => {
		if (error) {
			if (incidentRef.value === null) {
				//Not yet loaded, probably a timeout
				assignLoadable(connectionLoadableRef, { type: "error", error })
			} else {
				handler.onReceivedError(error, null)
			}
		}
		isConnected.value = false
		viewingUsers.value = []
	})

	useCustomAppHeaderRender(() => <ConnectedUsers users={viewingUsers.value} />)

	onUnmounted(() => connection.stop())

	defineGlobals({ connection })

	return () => (
		<ContentLoading stores={[connectionLoadableRef]}>
			<div class={pageStyles}>
				{!isConnected.value && <DisconnectedMessage refresh={props.refresh} />}
				<IncidentForm handler={handler} setupForm={refSetter(formRef)} />
			</div>
		</ContentLoading>
	)
}

export default defineComponent(EditExistingIncident, {
	incidentId: requiredProp(Number),
	refresh: requiredProp(Function),
})
