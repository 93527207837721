import Icon from "@/components/Icon"
import { faMap, faSatellite } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { ToggleButton, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

export enum ViewType {
	Map = "map",
	Satellite = "satellite",
}

interface Props {
	viewType: ViewType
	setViewType(type: ViewType): void
}

const viewToggleStyles = css`
	position: absolute;
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 0.5rem;
	right: 0.35rem;
	top: 0.35rem;
	padding: 0.35rem 0.75rem;
	z-index: 1;
	cursor: default;

	display: flex;
	align-items: center;
	gap: 0.5rem;

	& > div {
		display: flex;
		align-items: center;
		gap: 0.25rem;
	}

	svg {
		font-size: 1.125rem;
	}
`

const ViewToggle: ReactiveComponent<Props> = (props) => {
	return () => (
		<div class={viewToggleStyles}>
			<div>
				<Icon icon={faMap} />
				<span>Map</span>
			</div>
			<ToggleButton
				toggled={props.viewType === ViewType.Satellite}
				setToggled={(t) => props.setViewType(t ? ViewType.Satellite : ViewType.Map)}
			/>
			<div>
				<span>Satellite</span>
				<Icon icon={faSatellite} />
			</div>
		</div>
	)
}

export default defineComponent(ViewToggle, {
	viewType: requiredProp(String),
	setViewType: requiredProp(Function),
})
