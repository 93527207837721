import usePopups from "@/stores/popupsStore"
import { toRef, toValue, watch, type MaybeRefOrGetter, type Ref } from "vue"
import { Alert, ConfirmResult } from "vue-utils"

interface WatchChangesOptions<T> {
	name: string

	currentValue: Ref<T>
	globalValue: MaybeRefOrGetter<T>

	equals?(value1: T, value2: T): boolean
}

export function useWatchExternalChanges<T>(options: WatchChangesOptions<T>) {
	const popups = usePopups()

	let initialValue = toValue(options.globalValue)
	let currentGlobalValue = initialValue
	let showingPopup = false

	function equals(value1: T, value2: T) {
		if (options.equals) {
			return options.equals(value1, value2)
		}
		return value1 === value2
	}

	const currentValueRef = toRef(() => toValue(options.globalValue))
	watch(currentValueRef, (newValue) => {
		currentGlobalValue = newValue
		if (equals(options.currentValue.value, initialValue)) {
			options.currentValue.value = newValue
			initialValue = newValue
			return
		}

		if (equals(newValue, options.currentValue.value) || showingPopup) {
			return
		}
		showingPopup = true
		void showChangedPopup()
	})

	async function showChangedPopup() {
		const option = await popups.showConfirmPopup({
			cancelText: "Keep Mine",
			confirmText: "Replace with Theirs",
			content: () => (
				<Alert title={`${options.name} Changed`}>
					<p>The {options.name.toLowerCase()} for this asset have been changed by another user.</p>
					<p>Do you wish to keep your current changes, or replace with the new {options.name.toLowerCase()}?</p>
				</Alert>
			),
		})

		showingPopup = false
		if (option === ConfirmResult.Confirm) {
			options.currentValue.value = currentGlobalValue
			initialValue = currentGlobalValue
		}
	}
}
