import AutoColumns from "@/components/AutoColumns"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import IncidentInput from "../components/IncidentInput"
import IncidentTextArea from "../components/IncidentTextArea"
import { useIncidentHandler } from "../IncidentLogHandler"

const ShipRegistration: ReactiveComponent = () => {
	const handler = useIncidentHandler()
	const changeBuilder = handler.changeBuilder.at("registrationInformation")

	return () => (
		<div class="flex flex-col spacing-4">
			<AutoColumns size="16rem" class="spacing-4">
				<label>
					Port of Registry
					<IncidentInput
						type="text"
						path={changeBuilder.at("portOfRegistry")}
						placeholder={handler.readOnly ? "N/A" : "Enter port of registry..."}
						disabled={handler.readOnly}
					/>
				</label>

				<label>
					Home Port
					<IncidentInput
						type="text"
						path={changeBuilder.at("homePort")}
						placeholder={handler.readOnly ? "N/A" : "Enter the home port..."}
						disabled={handler.readOnly}
					/>
				</label>

				<label>
					Name of Owner
					<IncidentInput
						type="text"
						path={changeBuilder.at("ownerName")}
						placeholder={handler.readOnly ? "N/A" : "Enter the owner's name..."}
						disabled={handler.readOnly}
					/>
				</label>
			</AutoColumns>
			<label>
				Owner's Address
				<IncidentTextArea
					path={changeBuilder.at("ownerAddress")}
					placeholder={handler.readOnly ? "N/A" : "Enter the owner's address..."}
					rows={4}
					disabled={handler.readOnly}
				/>
			</label>
		</div>
	)
}

export default defineComponent(ShipRegistration)
