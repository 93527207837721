import { useVueAuth } from "oidc-vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import Popups from "./components/Popups"
import MainApp from "./MainApp"
import LoginPage from "./pages/login"

const App: ReactiveComponent = () => {
	const auth = useVueAuth()
	return () => (
		<>
			{auth.loggedIn.value ? <MainApp /> : <LoginPage />}
			<Popups />
		</>
	)
}

export default defineComponent(App)
