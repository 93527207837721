import { defineComponent, optionalProp, requiredProp, useOnInputNumber, type ReactiveComponent } from "vue-utils"
import Pagination from "./Pagination"

export interface Props {
	/** Name of the entities which are being displayed in the table. Should be in plural form */
	entityName: string
	page: number
	pageSize: number
	totalPages: number
	totalItems: number
	setPage(page: number): void
	setPageSize?(pageSize: number): void
}

const TableControls: ReactiveComponent<Props> = (props) => {
	return () => (
		<div class="flex items-center justify-between" style="margin-top: 1rem">
			<span>
				Total {props.entityName} Found - <strong>{props.totalItems}</strong>
			</span>
			<div class="flex items-center flex-wrap">
				<label class="flex-row items-center">
					<span>Items Per Page</span>
					<input
						type="number"
						value={props.pageSize}
						min={1}
						max={24}
						step={1}
						style={{ width: "4rem", height: "2rem" }}
						onChange={useOnInputNumber((size) => props.setPageSize?.(size))}
						disabled={!props.setPageSize}
					/>
				</label>
				<Pagination page={props.page} setPage={props.setPage} totalPages={props.totalPages} />
			</div>
		</div>
	)
}

export default defineComponent(TableControls, {
	entityName: requiredProp(String),
	page: requiredProp(Number),
	totalItems: requiredProp(Number),
	totalPages: requiredProp(Number),
	pageSize: requiredProp(Number),
	setPage: requiredProp(Function),
	setPageSize: optionalProp(Function),
})
