import type { IncidentLog } from "@/models"
import { changeBuilder } from "@/modifications"
import { shallowRef } from "vue"
import { defineComponent, requiredProp, useCustomFormValidation, type ReactiveComponent } from "vue-utils"
import IncidentForm from "../shared/incident-form/IncidentForm"
import type { IncidentLogHandler } from "../shared/incident-form/IncidentLogHandler"

interface Props {
	incident: IncidentLog
}

/**
 * An incident log handler which permits no actions and is completely read only.
 */
class SignedOffIncidentHandler implements IncidentLogHandler {
	readonly incident: IncidentLog
	readonly readOnly = true
	readonly isNewIncident = false
	readonly changeBuilder = changeBuilder<IncidentLog>()

	constructor(incident: IncidentLog) {
		this.incident = incident
	}

	createIncident(): Promise<void> {
		return Promise.reject(new Error("Unable to create already existing incident"))
	}

	validateForm(): Promise<boolean> {
		return Promise.resolve(false)
	}

	submitChange(): void {
		throw new Error("No changes can be made, as the incident is signed off and read only")
	}

	submitDebouncedChange(): void {
		this.submitChange()
	}

	awaitChange(): Promise<boolean> {
		return Promise.reject(new Error("No changes can be made, as the incident is signed off and read only"))
	}
}

const ViewSignedOffIncident: ReactiveComponent<Props> = (props) => {
	const handler = new SignedOffIncidentHandler(props.incident)
	useCustomFormValidation({ form: shallowRef<HTMLFormElement>() })

	return () => <IncidentForm handler={handler} />
}

export default defineComponent(ViewSignedOffIncident, {
	incident: requiredProp(Object),
})
