import type { AppConfig } from "@/models"
import { sendHttpRequest } from "@/services/httpHelper"
import { reactive } from "vue"

async function readJson<T>(url: string) {
	const result = await sendHttpRequest(url)
	return (await result.json()) as T
}

const config: AppConfig = reactive(await readJson<AppConfig>("/api/config.json"))
document.title = config.appName

export function useAppConfig(): AppConfig {
	return config
}
