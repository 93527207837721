import type { RouteInformation } from "@/components/navigation/Navigation"
import { SystemRole } from "@/models"
import { faMap, faPlusCircle, faTableList } from "@fortawesome/free-solid-svg-icons"

export const AppRoutes: Record<string, RouteInformation> = {
	"/incident-map": {
		name: "Incident Map",
		icon: faMap,
	},
	"/incidents/list": {
		name: "All Incident Logs",
		icon: faTableList,
	},
	"/incidents/create": {
		name: "Create New Incident",
		icon: faPlusCircle,
		hasAccess: (user) => user.atLeastHasRole(SystemRole.CoastguardOfficer),
	},
}
