import type { Nullable } from "@/models"
import { Instant, ZoneId } from "@js-joda/core"
import { computed, ref } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../../IncidentLogHandler"
import EditableField from "../EditableField"
import EditAssetTime from "./EditAssetTime"

interface Props {
	value: Nullable<Instant>
	setValue(newValue: Nullable<Instant>): Promise<void>
}

const AssetTimeCell: ReactiveComponent<Props> = (props) => {
	const handler = useIncidentHandler()

	const editing = ref(false)
	const dateFormatted = computed(() => {
		const time = props.value
		if (!time) {
			return handler.readOnly ? "-" : ""
		}
		const incidentDate = handler.incident.incidentTime.atZone(ZoneId.UTC).toLocalDate()
		const date = time.atZone(ZoneId.UTC).toLocalDate()

		if (incidentDate.isEqual(date)) {
			return time.atZone(ZoneId.UTC).toLocalTime().toHTMLInput()
		}
		return time.toUTCDateTimeString()
	})

	async function saveEdits(value: Nullable<Instant>) {
		await props.setValue(value)
		editing.value = false
	}

	return () => (
		<>
			<EditableField hasValue={props.value !== null} edit={() => (editing.value = true)}>
				<span class="flex items-center justify-center">{props.value ? dateFormatted.value : "-"}</span>
			</EditableField>
			{editing.value && (
				<EditAssetTime existing={props.value ?? Instant.now()} cancel={() => (editing.value = false)} set={saveEdits} />
			)}
		</>
	)
}

export default defineComponent(AssetTimeCell, {
	value: requiredProp(Instant, null),
	setValue: requiredProp(Function),
})
