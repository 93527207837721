import { css } from "vite-css-in-js"
import type { HTMLAttributes } from "vue"
import { defineComponent, renderSlot, type NoProps, type ReactiveComponent } from "vue-utils"

const frostedGlassStyles = css`
	padding: 1rem;
	border-radius: 0.75rem;
	background-color: rgba(250, 250, 250, 0.9);

	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
	border: 1px solid rgba(0, 0, 0, 0.35);

	margin: 1rem;
`

const FrostedGlass: ReactiveComponent<NoProps, HTMLAttributes> = (_, { attrs, slots }) => {
	return () => (
		<div class={frostedGlassStyles} {...attrs}>
			{renderSlot(slots)}
		</div>
	)
}

export default defineComponent(FrostedGlass)
