import type { User } from "@/models"
import { useLoggedInUser } from "@/stores/coreDataStore"
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { RouterLink } from "vue-router"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import Icon from "../Icon"

export interface RouteInformation {
	name: string
	icon: IconDefinition
	hasAccess?(user: User): boolean
}

interface Props {
	routes: Record<string, RouteInformation>
}

const navStyles = css`
	list-style: none;
	flex: 1 1 0;
	overflow: auto;
	margin: 0;
	padding: 0.5rem 0;
	font-size: 1.125em;
	color: white;

	display: flex;
	flex-direction: column;

	a {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		padding: 0.5rem 0.5rem;
		padding-left: 1rem;
		color: #f0f0f0;
		text-decoration: none;

		&.il-nav-active-route {
			border-left: 0.25rem solid var(--color-secondary-blue);
			background-color: #636363;
			padding-left: 0.75rem;
		}

		&:hover {
			background-color: #3a3a3a;
		}

		& > div {
			width: 2rem;
		}

		svg {
			display: flex;
			font-size: 1.25em;
		}

		@media (max-width: 80rem) {
			& > span {
				display: none;
			}
			svg {
				font-size: 1.5em;
			}
		}
	}
`

const Navigation: ReactiveComponent<Props> = (props) => {
	const user = useLoggedInUser()
	const availableRoutes = computed(() => {
		const availableRoutes: Record<string, RouteInformation> = {}
		for (const url in props.routes) {
			const route = props.routes[url]
			if (!route.hasAccess || route.hasAccess(user)) {
				availableRoutes[url] = route
			}
		}
		return availableRoutes
	})

	return () => (
		<ul class={navStyles}>
			{Object.entries(availableRoutes.value).map(([url, route]) => (
				<li key={url}>
					<RouterLink to={url} activeClass="il-nav-active-route">
						<div>
							<Icon icon={route.icon} />
						</div>
						<span>{route.name}</span>
					</RouterLink>
				</li>
			))}
		</ul>
	)
}

export default defineComponent(Navigation, {
	routes: requiredProp(Object),
})
