import { watchDeep } from "@vueuse/core"
import { onBeforeMount, reactive, toRef, toRefs, watch } from "vue"
import { assignLoadable, debounce, useLoadableRef } from "vue-utils"
import { useLoading } from "./useLoading"

interface PagedFilteredSearching {
	page: number
	pageSize: number
	filter: unknown
}

interface PagedFilteringOptions<V, T> {
	getDefault(): T
	loadValue(requestOptions: T): Promise<V>
	debounceTime?: number
}

export function usePagedFilteredSearching<V, T extends PagedFilteredSearching>(options: PagedFilteringOptions<V, T>) {
	const { runAction } = useLoading()

	const requestOptions = reactive({ ...options.getDefault() }) as T

	function resetOptions() {
		Object.assign(requestOptions, options.getDefault())
	}

	const loadableRef = useLoadableRef(() => options.loadValue(requestOptions))

	async function refreshData() {
		try {
			const result = await loadableRef.queryData()
			assignLoadable(loadableRef, { type: "done", result })
		} catch (e) {
			assignLoadable(loadableRef, { type: "error", error: e as Error })
		}
	}

	async function refreshResults() {
		//Use slightly different loading code to ensure the result is still valid even when refreshed
		if (loadableRef.type === "initial") {
			assignLoadable(loadableRef, { type: "loading" })
			await refreshData()
		} else {
			await runAction(refreshData())
		}
	}

	function hasResults(): boolean {
		return loadableRef.type !== "error" && "result" in loadableRef && !!loadableRef.result
	}

	function getResults(): V {
		if (hasResults() && "result" in loadableRef) {
			return loadableRef.result
		}
		throw new Error("No data currently loaded")
	}

	const loadValueDebounced = debounce(() => void refreshResults(), 1)
	const filterChangedDebounced = debounce(() => {
		requestOptions.page = 1
		loadValueDebounced()
	}, options.debounceTime ?? 350)

	watchDeep(toRef(requestOptions, "filter"), (newValue, oldValue) => {
		if (newValue === oldValue) {
			//Whole object changed rather than just a property. Prevents debouncing a request that should be sent immediately
			filterChangedDebounced()
		} else {
			loadValueDebounced()
		}
	})
	watch(toRef(requestOptions, "pageSize"), () => {
		requestOptions.page = 1
		loadValueDebounced()
	})
	watch(Object.values(toRefs(requestOptions)), loadValueDebounced)
	onBeforeMount(refreshResults)

	return {
		hasResults,
		getResults,

		requestOptions,
		loadableRef,

		resetOptions,
		refreshResults,
	}
}
