import type { IncidentLogSummary } from "@/models/IncidentLogSummary"
import { formatEnum } from "@/utils/formatEnum"
import { proxyProp } from "@/utils/proxyProp"
import { ZoneId } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	incident: IncidentLogSummary
}

const tooltipStyles = css`
	font-size: 1rem;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	white-space: normal;

	hr,
	p,
	pre,
	table {
		margin-top: 0;
		margin-bottom: 0.5rem;
	}
	pre {
		font-family: inherit;
	}
	h4 {
		font-size: 1.25rem;
	}
	h5 {
		font-size: 1rem;
		font-weight: 600 !important;
		margin-bottom: 0.25rem;
	}
	table {
		th {
			font-weight: 600;
			padding-right: 1rem;
		}
		td {
			font-weight: normal;
		}
	}
	width: 32rem;
`

const IncidentLogMarkerTooltip: ReactiveComponent<Props> = (props) => {
	const incident = proxyProp(props, "incident")

	const title = computed(() => {
		if (incident.nameOfVesselOrCasualty && incident.nameOfVesselOrCasualty.trim().length > 0) {
			return incident.nameOfVesselOrCasualty
		}
		return incident.primaryIncidentType.name
	})

	return () => (
		<div class={tooltipStyles}>
			<h4>{title.value}</h4>
			<hr />

			<table>
				<tbody>
					<tr>
						<th>Identifier</th>
						<td>{incident.identifier}</td>
					</tr>
					<tr>
						<th>Status</th>
						<td>{formatEnum(incident.status)}</td>
					</tr>
					<tr>
						<th>Incident Type</th>
						<td>{incident.primaryIncidentType.name}</td>
					</tr>
					<tr>
						<th>Emergency Phase</th>
						<td>{formatEnum(incident.emergencyPhase)}</td>
					</tr>
					<tr>
						<th>Incident Time (UTC)</th>
						<td>{incident.incidentTime.atZone(ZoneId.SYSTEM).toLocalDateTime().toLocalDateTimeString()}</td>
					</tr>
				</tbody>
			</table>

			<p>{incident.messageReceived}</p>
			{!!incident.natureOfIncident?.trim()?.length && <p>{incident.natureOfIncident}</p>}
			{!!incident.descriptionOfCasualty?.trim()?.length && <p>{incident.descriptionOfCasualty}</p>}

			<hr />

			<p class="mb-0" style={{ color: "#1d4a7b" }}>
				Click the marker to view the full incident log.
			</p>
		</div>
	)
}

export default defineComponent(IncidentLogMarkerTooltip, {
	incident: requiredProp(Object),
})
