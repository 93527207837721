import Icon from "@/components/Icon"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { computed, type HTMLAttributes } from "vue"
import { defineComponent, optionalProp, renderSlot, requiredProp, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../IncidentLogHandler"

interface Props {
	hasValue: boolean
	edit(): void

	showOnReadOnly?: boolean
	disabled?: boolean
}

const tdStyles = css`
	display: flex;
	align-items: stretch !important;
	justify-content: stretch !important;
	padding: 0 !important;
`

const buttonStyles = css`
	all: unset;

	display: flex;
	width: 100%;
	height: 100%;
	padding: 0.35rem 0.5rem;

	box-sizing: border-box;

	& > * {
		width: 100%;
		height: 100%;
	}

	&:not(:disabled) {
		cursor: pointer;
		&:hover {
			background-color: #b1dbb5ae;
		}
		&[data-readonly="true"]:hover {
			background-color: #dbdab1ad;
		}
		&:focus-visible {
			background-color: #c0e1f5 !important;
		}
	}
`

const plusStyles = css`
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		color: green;
		font-size: 1.25rem;
	}
`

const EditableField: ReactiveComponent<Props, HTMLAttributes> = (props, { slots, attrs }) => {
	const handler = useIncidentHandler()
	const title = computed(() => {
		if (!handler.readOnly) {
			if (props.hasValue) return "Click to Edit"
			return "Click to Add"
		}
		if (props.showOnReadOnly) {
			return "Click to View"
		}
		return ""
	})

	return () => (
		<td class={tdStyles} {...attrs}>
			<button
				class={buttonStyles}
				data-readonly={String(handler.readOnly)}
				title={title.value}
				disabled={props.disabled || (handler.readOnly && !props.showOnReadOnly)}
				onClick={props.edit}
			>
				{!props.hasValue && !handler.readOnly ? (
					<span class={plusStyles}>
						<Icon icon={faPlus} />
					</span>
				) : (
					renderSlot(slots)
				)}
			</button>
		</td>
	)
}

export default defineComponent(EditableField, {
	hasValue: requiredProp(Boolean),
	edit: requiredProp(Function),

	showOnReadOnly: optionalProp(Boolean),
	disabled: optionalProp(Boolean),
})
