import { css } from "vite-css-in-js"
import { computed, provide, shallowRef, watch } from "vue"
import {
	ShadowContainer,
	defineComponent,
	defineGlobals,
	optionalProp,
	requiredProp,
	type ReactiveComponent,
} from "vue-utils"
import IncidentFormSection from "./IncidentFormSection"
import { IncidentLogHandlerInjectionKey, type IncidentLogHandler } from "./IncidentLogHandler"
import QuickNavigation from "./QuickNavigation"
import { useIncidentLogFormScrolling } from "./formScrolling"
import { getIncidentFormSections } from "./sections"

export interface Props {
	handler: IncidentLogHandler
	setupForm?(form: HTMLFormElement): void
}

const containerStyles = css`
	display: flex;
	gap: 0.5rem;
	height: 100%;

	& > div:nth-child(1) {
		display: flex;
		flex-direction: column;
		padding: 0;
		flex-grow: 1;
	}

	@media print {
		display: contents;

		& > div:nth-child(1) {
			display: contents;
		}

		& > div:nth-child(2) {
			display: none;
		}
	}

	@media (max-width: 96rem) {
		& > div:nth-child(2) {
			display: none;
		}
	}
`

const formStyles = css`
	flex: 1 1 0;
	overflow: auto;

	@media print {
		display: contents;
		height: 100%;
		flex-basis: auto;
		overflow: initial;

		& > section {
			display: block;
			break-inside: avoid;

			+ section {
				margin-bottom: 1rem;
			}
		}
	}
`

const IncidentForm: ReactiveComponent<Props> = (props) => {
	const formContainer = shallowRef<HTMLFormElement>()
	const sections = computed(() => getIncidentFormSections(props.handler))

	const { section, setSection } = useIncidentLogFormScrolling({
		contentRef: formContainer,
		sections,
	})

	watch(formContainer, (formEl) => formEl && props.setupForm?.(formEl))
	provide(IncidentLogHandlerInjectionKey, props.handler)

	defineGlobals({ incident: props.handler.incident, handler: props.handler })

	return () => (
		<div class={containerStyles}>
			<ShadowContainer>
				<form class={formStyles} onSubmit={(e) => e.preventDefault()} ref={formContainer} novalidate>
					{sections.value.map((section) => (
						<IncidentFormSection key={section.id} section={section} />
					))}
				</form>
			</ShadowContainer>
			<QuickNavigation sections={sections.value} section={section.value} setSection={setSection} />
		</div>
	)
}

export default defineComponent(IncidentForm, {
	handler: requiredProp(Object),
	setupForm: optionalProp(Function),
})
