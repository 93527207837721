import Icon from "@/components/Icon"
import { faCompass, faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { ToggleButton, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

export enum LocationSelectType {
	Point = "point",
	Distance = "distance",
}

interface Props {
	selectType: LocationSelectType
	setSelectType(type: LocationSelectType): void
}

const toggleStyles = css`
	position: absolute;
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 0.5rem;
	left: 0.35rem;
	top: 0.35rem;
	padding: 0.35rem 0.75rem;
	z-index: 1;
	cursor: default;

	display: flex;
	align-items: center;
	gap: 0.5rem;

	& > div {
		display: flex;
		align-items: center;
		gap: 0.25rem;
	}

	svg {
		font-size: 1.125rem;
	}
`

const SelectTypeToggle: ReactiveComponent<Props> = (props) => {
	return () => (
		<div class={toggleStyles}>
			<div>
				<Icon icon={faLocationCrosshairs} />
				<span>Point</span>
			</div>
			<ToggleButton
				toggled={props.selectType === LocationSelectType.Distance}
				setToggled={(t) => props.setSelectType(t ? LocationSelectType.Distance : LocationSelectType.Point)}
			/>
			<div>
				<span>Distance</span>
				<Icon icon={faCompass} />
			</div>
		</div>
	)
}

export default defineComponent(SelectTypeToggle, {
	selectType: requiredProp(String),
	setSelectType: requiredProp(Function),
})
