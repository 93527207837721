import type { Nullable } from "../IncidentEntity"
import type { ApiSchema } from "../Schema"

export interface ShipRegistration {
	portOfRegistry: Nullable<string>
	homePort: Nullable<string>
	ownerName: Nullable<string>
	ownerAddress: Nullable<string>
}

export const ShipRegistrationSchema: ApiSchema<ShipRegistration> = {
	type: "object",
	properties: {
		portOfRegistry: String,
		homePort: String,
		ownerName: String,
		ownerAddress: String,
	},
}
