import type { IncidentLog } from "@/models"
import type { ChangeBuilder } from "@/modifications"
import type { Immutable } from "@/utils/Immutable"
import type { InjectionKey } from "vue"
import { useService } from "vue-utils"
import type { IncidentAction } from "./AdditionalActions"

export interface IncidentLogHandler {
	readonly incident: Immutable<IncidentLog>
	readonly isNewIncident: boolean
	readonly changeBuilder: ChangeBuilder<IncidentLog>

	readOnly: boolean

	createIncident(): Promise<void>
	validateForm(): Promise<boolean>

	// Following methods are declared as functions rather than methods here due to a typescript...um...."FEATURE"....
	// https://stackoverflow.com/questions/68873500/workaround-for-typescript-not-inferring-contravaraince

	submitChange: (action: IncidentAction) => void
	submitDebouncedChange: (action: IncidentAction) => void

	/**
	 * @returns Whether the action was successfully completed
	 */
	awaitChange: (action: IncidentAction) => Promise<boolean>
}

export const IncidentLogHandlerInjectionKey = Symbol("IncidentLogHandler") as InjectionKey<IncidentLogHandler>

export function useIncidentHandler(): IncidentLogHandler {
	return useService(IncidentLogHandlerInjectionKey)
}
