import type { ModificationAction, ObjectPath } from "@/modifications"
import type { Duration } from "@js-joda/core"

export class ActionScheduler {
	private readonly mostRecentAction = new Map<string, ModificationAction>()
	private readonly actionTimeoutIds = new Map<string, number>()
	private readonly debounceMs

	constructor(
		debounceTime: Duration,
		private readonly executeAction: (action: ModificationAction) => void
	) {
		this.debounceMs = debounceTime.toMillis()
	}

	scheduleAction(action: ModificationAction) {
		const pathStr = this.serializePath(action.path)

		this.mostRecentAction.set(pathStr, action)
		if (this.actionTimeoutIds.has(pathStr)) {
			return
		}

		this.actionTimeoutIds.set(
			pathStr,
			window.setTimeout(() => this.onTimerActivate(pathStr), this.debounceMs)
		)
	}

	private onTimerActivate(pathStr: string) {
		const mostRecentAction = this.mostRecentAction.get(pathStr)

		this.actionTimeoutIds.delete(pathStr)
		this.mostRecentAction.delete(pathStr)

		if (mostRecentAction) {
			this.executeAction(mostRecentAction)
		}
	}

	/**
	 * @returns Whether any actions existed at the path to cancel
	 */
	cancelAction(path: ObjectPath): boolean {
		const pathStr = this.serializePath(path)

		const timeoutId = this.actionTimeoutIds.get(pathStr)
		if (timeoutId !== undefined) {
			window.clearTimeout(timeoutId)
		}

		this.actionTimeoutIds.delete(pathStr)
		this.mostRecentAction.delete(pathStr)

		return timeoutId !== undefined
	}

	cancelAll() {
		this.mostRecentAction.clear()
		for (const timeoutId of this.actionTimeoutIds.values()) {
			window.clearTimeout(timeoutId)
		}
		this.actionTimeoutIds.clear()
	}

	private serializePath(path: ObjectPath) {
		return path.map(String).join("")
	}
}
