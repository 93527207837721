import BootstrapButton from "@/components/form/BootstrapButton"
import Icon from "@/components/Icon"
import { useLoading } from "@/composition/useLoading"
import { createEmptyAction } from "@/modifications"
import usePopups from "@/stores/popupsStore"
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons"
import { Alert, defineComponent, type ReactiveComponent } from "vue-utils"
import type { ReopenIncidentAction } from "../../AdditionalActions"
import { useIncidentHandler } from "../../IncidentLogHandler"

const ReopenIncidentButton: ReactiveComponent = () => {
	const popups = usePopups()
	const handler = useIncidentHandler()
	const { runAction } = useLoading()

	async function handleClick() {
		if (!(await confirmReopen())) {
			return
		}
		const action: ReopenIncidentAction = {
			...createEmptyAction(),
			type: "ReopenIncident",
		}
		await runAction(handler.awaitChange(action))
		void popups.showAlertPopup(() => (
			<Alert title="Success">The incident has been successfully reopened. You may close it again at any time.</Alert>
		))
	}

	async function confirmReopen() {
		return await popups.confirm(() => (
			<Alert title="Confirm Reopen">
				Are you sure you wish to reopen this incident? It will be once again become editable.
			</Alert>
		))
	}

	return () => (
		<BootstrapButton type="button" color="success" onClick={() => void handleClick()}>
			<Icon icon={faUnlockAlt} />
			<span>Reopen Incident</span>
		</BootstrapButton>
	)
}

export default defineComponent(ReopenIncidentButton)
