import CKEditor from "@/components/ckeditor/CKEditor"
import type { NoteEntry } from "@/models"
import { useUser } from "@/stores/coreDataStore"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	note: NoteEntry
}

const byStyles = css`
	font-size: 0.92rem;
	strong {
		font-weight: 600;
	}
	margin-bottom: 0.25rem;
`

const NarrativeDisplay: ReactiveComponent<Props> = (props) => {
	const createdBy = useUser(() => props.note.creationMetadata.userId)

	return () => (
		<div>
			<div class={byStyles}>
				<strong title={createdBy.value?.emailAddress}>{createdBy.value?.fullName ?? "Unknown"}</strong> at{" "}
				{props.note.creationMetadata.timeStamp.toUTCDateTimeString()} (UTC)
			</div>
			<CKEditor value={props.note.text} setValue={() => void 0} readOnly viewOnly />
		</div>
	)
}

export default defineComponent(NarrativeDisplay, {
	note: requiredProp(Object),
})
