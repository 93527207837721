import AutoColumns from "@/components/AutoColumns"
import BasicSelect from "@/components/form/BasicSelect"
import { CardinalDirection, SeaState } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { Required, defineComponent, refSetter, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../IncidentLogHandler"
import IncidentInput from "../../components/IncidentInput"
import { useIncidentSelectRef } from "../../inputHelpers"
import TideInput from "./TideInput"

const WeatherTide: ReactiveComponent = () => {
	const handler = useIncidentHandler()
	const weatherChangeBuilder = handler.changeBuilder.at("weatherConditions")

	const seaStateRef = useIncidentSelectRef(weatherChangeBuilder.at("seaState"))
	const windDirRef = useIncidentSelectRef(weatherChangeBuilder.at("windDirection"))

	return () => (
		<div class="flex flex-col spacing-4">
			<AutoColumns size="16rem" class="spacing-4">
				<label>
					<Required label="Sea State" />
					<BasicSelect
						options={Object.values(SeaState)}
						value={seaStateRef.value}
						setValue={refSetter(seaStateRef)}
						getText={formatEnum}
						defaultText={handler.readOnly ? "N/A" : "Select the sea state"}
						disabled={handler.readOnly}
						required
					/>
				</label>

				<label>
					<Required label="Wind Direction" />
					<BasicSelect
						options={Object.values(CardinalDirection)}
						value={windDirRef.value}
						setValue={refSetter(windDirRef)}
						defaultText={handler.readOnly ? "N/A" : "Select the wind direction"}
						disabled={handler.readOnly}
						required
					/>
				</label>

				<label>
					<Required label="Wind Speed (Knots)" />
					<IncidentInput
						type="number"
						path={weatherChangeBuilder.at("windSpeedKnots")}
						placeholder={handler.readOnly ? "N/A" : "Enter wind speed..."}
						min={1}
						step={1}
						disabled={handler.readOnly}
						required
					/>
				</label>

				<label>
					<Required label="Visibility (Nautical miles)" />
					<IncidentInput
						type="number"
						path={weatherChangeBuilder.at("visibilityNm")}
						placeholder={handler.readOnly ? "N/A" : "Enter visibility..."}
						min={0}
						step="0.1"
						disabled={handler.readOnly}
						required
					/>
				</label>
			</AutoColumns>

			<TideInput name="Low Water" type="lowWater" />
			<TideInput name="High Water" type="highWater" />
		</div>
	)
}

export default defineComponent(WeatherTide)
