import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../IncidentLogHandler"
import AssetTaskedRow from "./AssetTaskedRow"

const tableStyles = css`
	table-layout: fixed;
	border-collapse: collapse;

	display: grid;
	grid-template-columns: minmax(auto, 2fr) 12rem repeat(6, minmax(auto, 1fr)) repeat(3, max-content);

	--color-border: rgba(0, 0, 0, 0.2);

	border-left: thin solid var(--color-border);

	thead,
	tbody,
	tr {
		display: contents;
	}

	th {
		line-height: 1rem;
	}
	td,
	th {
		border-right: thin solid var(--color-border);
		border-bottom: thin solid var(--color-border);
		text-align: center;
		padding: 0.35rem 0.5rem;

		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
	}

	tr > *:first-child {
		justify-content: start;
		text-align: left;
	}

	td:last-child,
	th:last-child {
		border: none;
		background-color: transparent;
	}

	td:last-child {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: start;
		gap: 0.25rem;
		padding: 0.25rem;

		button {
			padding: 0.375rem 0.5rem;
		}
	}
`

const AssetsTable: ReactiveComponent = () => {
	const handler = useIncidentHandler()

	return () => (
		<div class="w-full">
			{handler.incident.assetsTasked.length > 0 && <p class="mb-2">All times in the table above are in UTC.</p>}

			<table class={tableStyles}>
				<thead>
					<tr>
						<th>SRU</th>
						<th>Crew</th>
						<th>Alerted</th>
						<th>Departed</th>
						<th>On Scene</th>
						<th>Returning</th>
						<th>Pier Heads</th>
						<th>Back on Station</th>
						<th>
							Tasking
							<br />
							Instructions
						</th>
						<th>
							Additional
							<br />
							Notes
						</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{handler.incident.assetsTasked.map((asset) => (
						<AssetTaskedRow key={asset.id} asset={asset} />
					))}
				</tbody>
			</table>
		</div>
	)
}

export default defineComponent(AssetsTable)
