import { ChangeBuilder } from "@/modifications"
import type { InputHTMLAttributes } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../IncidentLogHandler"
import { useIncidentInputRef } from "../inputHelpers"

interface Props {
	path: ChangeBuilder<string | number | null>
}

const IncidentInput: ReactiveComponent<Props, Omit<InputHTMLAttributes, "value">> = (props, { attrs }) => {
	const handler = useIncidentHandler()
	const inputRef = useIncidentInputRef(() => props.path)

	function saveChanges(e: Event) {
		const el = e.target as HTMLInputElement
		if (attrs.type === "number") {
			inputRef.value = Number.parseFloat(el.value)
		} else {
			inputRef.value = el.value
		}

		handler.submitChange(props.path.setTo(inputRef.value))
	}

	return () => <input value={inputRef.value} onChange={saveChanges} {...attrs} />
}

export default defineComponent(IncidentInput, {
	path: requiredProp(ChangeBuilder),
})
