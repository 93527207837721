import MultiSelect from "@/components/multi-select"
import { EmergencyPhase, IncidentStatus, type Id, type SearchIncidentsFilter } from "@/models"
import { useCoreData } from "@/stores/coreDataStore"
import { formatEnum } from "@/utils/formatEnum"
import { proxyProp } from "@/utils/proxyProp"
import { computed } from "vue"
import { defineComponent, requiredProp, useOnInputNumber, type ReactiveComponent } from "vue-utils"

interface Props {
	filter: SearchIncidentsFilter
}

const SearchIncidentsFilters: ReactiveComponent<Props> = (props) => {
	const filter = proxyProp(props, "filter")
	const coreData = useCoreData()

	const selectedIncidentTypes = computed(() => {
		const ids = new Set<Id>(filter.primaryIncidentTypeIds)
		return coreData.primaryIncidentTypes.filter((t) => ids.has(t.id))
	})

	return () => (
		<>
			<label>
				Status
				<MultiSelect
					class="w-full"
					options={Object.values(IncidentStatus)}
					selected={filter.statuses}
					setSelected={(s) => (filter.statuses = s as IncidentStatus[])}
					getKey={(s) => s}
					getText={formatEnum}
					allText="All Statuses"
					noneText="All Statuses"
					someText={(s) => s.join(", ")}
					entityNames="Statuses"
				/>
			</label>
			<label>
				Primary Incident Type
				<MultiSelect
					class="w-full"
					options={coreData.primaryIncidentTypes.toSorted((a, b) => a.name.localeCompare(b.name))}
					selected={selectedIncidentTypes.value}
					setSelected={(types) => (filter.primaryIncidentTypeIds = types.map((t) => t.id))}
					getKey={(t) => t.id}
					getText={(t) => t.name}
					allText="All Incident Types"
					noneText="All Incident Types"
					entityNames="Incident Types"
				/>
			</label>
			<label>
				Emergency Phase
				<MultiSelect
					class="w-full"
					options={Object.values(EmergencyPhase)}
					selected={filter.emergencyPhases}
					setSelected={(phases) => (filter.emergencyPhases = phases as EmergencyPhase[])}
					getKey={(phase) => phase}
					getText={formatEnum}
					allText="All Phases"
					noneText="All Phases"
					entityNames="Emergency Phases"
				/>
			</label>
			<label>
				Year of Incident
				<input type="number" value={filter.year} onChange={useOnInputNumber((yr) => (filter.year = yr))} step={1} />
			</label>
		</>
	)
}

export default defineComponent(SearchIncidentsFilters, {
	filter: requiredProp(Object),
})
