import type { Id, IncidentEntity, Nullable } from "@/models"
import { Instant } from "@js-joda/core"
import { InstantSchema, type ApiSchema } from "../Schema"

export interface AssetTasked extends IncidentEntity {
	SRUId: Id

	/**
	 * Usually a list of numbers, with the maximum being the "totalCrew" field of the corresponding SRU
	 */
	crewLists: Nullable<number[]>

	customName: Nullable<string>
	notes: Nullable<string>
	taskingInstructions: Nullable<string>

	alerted: Nullable<Instant>
	departed: Nullable<Instant>
	onScene: Nullable<Instant>
	returning: Nullable<Instant>
	pierHeads: Nullable<Instant>
	backOnStation: Nullable<Instant>
}

export const AssetTaskedSchema: ApiSchema<AssetTasked> = {
	type: "object",
	properties: {
		id: Number,
		SRUId: Number,

		crewLists: {
			type: "array",
			schema: Number,
		},

		customName: String,
		notes: String,
		taskingInstructions: String,

		alerted: InstantSchema,
		departed: InstantSchema,
		onScene: InstantSchema,
		returning: InstantSchema,
		pierHeads: InstantSchema,
		backOnStation: InstantSchema,
	},
}
