import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import TableControls from "@/components/table/TableControls"
import {
	IncidentStatus,
	IncidentsSortBy,
	SortDirection,
	type SearchIncidentsFilter,
	type SearchIncidentsRequest,
	type SearchIncidentsResult,
} from "@/models"
import SearchIncidentsFilters from "@/pages/shared/incident-searching/SearchIncidentsFilters"
import { proxyProp } from "@/utils/proxyProp"
import { faFilter } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import IncidentSummaryTable from "./IncidentSummaryTable"

interface Props {
	request: SearchIncidentsRequest
	result: SearchIncidentsResult
}

const filterStyles = css`
	display: inline-flex;
	flex-wrap: wrap;
	gap: 1rem 1rem;

	align-items: end;
	justify-content: stretch;

	& > * {
		flex-basis: 16rem;
	}
`

const IncidentsList: ReactiveComponent<Props> = (props) => {
	const filter = proxyProp(() => props.request.filter)
	const request = proxyProp(props, "request")
	const result = proxyProp(props, "result")

	function resetFilter() {
		const newFilter: SearchIncidentsFilter = {
			statuses: [IncidentStatus.Open, IncidentStatus.Closed],
			emergencyPhases: [],
			primaryIncidentTypeIds: [],
			year: null,
		}
		Object.assign(filter, newFilter)

		request.sortBy = IncidentsSortBy.IncidentId
		request.sortDirection = SortDirection.Descending
	}

	return () => (
		<>
			<div class={filterStyles}>
				<SearchIncidentsFilters filter={filter} />
				<div class="flex-grow-1 flex justify-end">
					<BootstrapButton color="danger" onClick={resetFilter}>
						<Icon icon={faFilter} />
						Reset Filters
					</BootstrapButton>
				</div>
			</div>
			<hr />
			{result.incidents.length === 0 ? (
				<p>
					{result.globalTotal === 0
						? "There are no incident logs recorded"
						: "No incident logs match the specified criteria"}
				</p>
			) : (
				<>
					<IncidentSummaryTable
						incidents={result.incidents}
						sort={
							request.sortBy && request.sortDirection
								? { direction: request.sortDirection, column: request.sortBy }
								: null
						}
						setSort={(sort) => {
							request.sortBy = sort.column
							request.sortDirection = sort.direction
						}}
					/>
					<TableControls
						entityName="Incident Logs"
						page={request.page}
						pageSize={request.pageSize}
						totalItems={result.totalItems}
						totalPages={result.totalPages}
						setPage={(page) => (request.page = page)}
						setPageSize={(pageSize) => (request.pageSize = pageSize)}
					/>
				</>
			)}
		</>
	)
}

export default defineComponent(IncidentsList, {
	request: requiredProp(Object),
	result: requiredProp(Object),
})
