import type { ApiSchema } from "./Schema"

export interface LatLng {
	latitude: number
	longitude: number
}

export const LatLngSchema: ApiSchema<LatLng> = {
	type: "object",
	properties: {
		latitude: Number,
		longitude: Number,
	},
}
