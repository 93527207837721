import L from "leaflet"
import { Semicircle } from "@mirei/leaflet-semicircle-ts"

export default class LocationMarker {
	readonly locationMarker: L.Marker
	private readonly radiusMarker: Semicircle | null
	private readonly markerStyles: Partial<CSSStyleDeclaration>

	constructor(options: {
		latitude: number
		longitude: number
		radius?: number
		tooltipHtml?: string
		tooltipPermanent?: boolean
		markerStyles?: Partial<CSSStyleDeclaration>
		radiusOptions?: Omit<L.CircleMarkerOptions, "radius">
	}) {
		const {
			latitude,
			longitude,
			radius,
			tooltipHtml,
			tooltipPermanent = false,
			radiusOptions = {},
			markerStyles = {},
		} = options
		this.locationMarker = L.marker([latitude, longitude])

		if (typeof radius === "number") {
			this.radiusMarker = new Semicircle([latitude, longitude])
			this.radiusMarker?.setStyle({
				...radiusOptions,
				radius,
			})
		} else {
			this.radiusMarker = null
		}

		if (tooltipHtml) {
			this.locationMarker.bindTooltip(
				L.tooltip({
					permanent: tooltipPermanent,
					interactive: true,
					content: tooltipHtml,
				})
			)
		}
		this.markerStyles = markerStyles
	}

	addTo(map: L.Map | L.LayerGroup) {
		this.locationMarker.addTo(map)

		if (this.radiusMarker) {
			this.radiusMarker.addTo(map)
		}

		const markerElement = this.locationMarker.getElement()
		if (markerElement) {
			Object.assign(markerElement.style, this.markerStyles)
		}
	}

	removeFrom(map: L.Map) {
		this.locationMarker.removeFrom(map)

		if (this.radiusMarker) {
			this.radiusMarker.removeFrom(map)
		}
	}

	moveTo(latitude: number, longitude: number) {
		this.locationMarker.setLatLng([latitude, longitude])
		this.radiusMarker?.setLatLng([latitude, longitude])
	}

	setRadius(radius: number) {
		this.radiusMarker?.setRadius(radius)
	}

	setDirection(direction: number, size: number) {
		this.radiusMarker?.setDirection(direction, size)
	}
}
