import CKEditor from "@/components/ckeditor/CKEditor"
import { ref, toRef } from "vue"
import { defineComponent, refSetter, requiredProp, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../../IncidentLogHandler"
import EditValueModal from "../EditValueModal"
import { useWatchExternalChanges } from "../useWatchChanges"

interface Props {
	notes: string

	cancel(): void
	setNotes(notes: string): Promise<void>
}

const EditNotes: ReactiveComponent<Props> = (props) => {
	const handler = useIncidentHandler()
	const notes = ref(props.notes)

	useWatchExternalChanges({
		name: "Notes",
		currentValue: notes,
		globalValue: toRef(props, "notes"),
	})

	return () => (
		<EditValueModal
			title={handler.readOnly ? "View Notes " : "Edit Notes"}
			cancel={props.cancel}
			save={() => props.setNotes(notes.value)}
			style={{ width: "64rem" }}
		>
			<div style={{ minHeight: "20rem" }}>
				<CKEditor
					class="w-full"
					value={notes.value}
					setValue={refSetter(notes)}
					height="16rem"
					readOnly={handler.readOnly}
					viewOnly={handler.readOnly}
				/>
			</div>
		</EditValueModal>
	)
}

export default defineComponent(EditNotes, {
	notes: requiredProp(String),

	cancel: requiredProp(Function),
	setNotes: requiredProp(Function),
})
