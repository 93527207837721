import type { Nullable } from "../IncidentEntity"
import type { ApiSchema } from "../Schema"
import { UnitLengthSchema, type UnitLength } from "../UnitLength"

export interface ShipDescription {
	length: Nullable<UnitLength>

	/** Text description of the ship's hull colour (if known) */
	hullColour: Nullable<string>

	/** Text description of the colour of the super structure (if known) */
	superstructureColour: Nullable<string>

	/** Text description of the colour of the sail (if applicable & known) */
	sailColour: Nullable<string>

	additionalInformation: string
}

export const ShipDescriptionSchema: ApiSchema<ShipDescription> = {
	type: "object",
	properties: {
		length: UnitLengthSchema,
		hullColour: String,
		superstructureColour: String,
		sailColour: String,
		additionalInformation: String,
	},
}
