import { Duration } from "@js-joda/core"
import { RouterView } from "vue-router"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { ContentLoading } from "./components/loading/StateLoading"
import { useStoreRefresh } from "./composition/useStoreRefresh"
import BasicPage from "./pages/BasicPage"
import { useAppConfig } from "./stores/appConfigStore"
import { useCoreDataStore } from "./stores/coreDataStore"

const MainApp: ReactiveComponent = () => {
	const config = useAppConfig()
	const coreDataStore = useCoreDataStore()
	useStoreRefresh({ coreData: coreDataStore }, Duration.ofHours(1))

	return () =>
		coreDataStore.type === "done" ? (
			<RouterView />
		) : (
			<BasicPage title={config.appName} noNav>
				<ContentLoading stores={[coreDataStore]} />
			</BasicPage>
		)
}

export default defineComponent(MainApp)
