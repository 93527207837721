import type { Nullable } from "./IncidentEntity"
import type { ApiSchema } from "./Schema"

export interface CallInformation {
	/**
	 * Almost always one of: 16, 20, 67, 12, 62
	 */
	VHFChannel: Nullable<number>

	/**
	 * If a channel other than the list above is selected
	 */
	VHFChannelOther: boolean

	/**
	 * Whether the call was made via DSC. Reference:
	 * https://en.wikipedia.org/wiki/Digital_selective_calling
	 */
	DSC: boolean

	/**
	 * The MMSI of the ship in distress' radio, or null if not available. Reference:
	 * https://en.wikipedia.org/wiki/Maritime_Mobile_Service_Identity
	 */
	MMSI: Nullable<string>

	callSign: Nullable<string>

	/**
	 * Whether the call was made via an EPIRB. Reference:
	 * https://en.wikipedia.org/wiki/Emergency_position-indicating_radiobeacon
	 */
	EPIRB: boolean

	/**
	 * Whether the call was made via 999
	 */
	emergency999: boolean

	/**
	 * The phone number of the caller, or null if not made via phone
	 */
	phoneNumber: Nullable<string>

	/**
	 * Name of the caller, or null if unknown
	 */
	callerName: Nullable<string>
}

export const CallInformationSchema: ApiSchema<CallInformation> = {
	type: "object",
	properties: {
		VHFChannel: Number,
		VHFChannelOther: Boolean,
		DSC: Boolean,
		MMSI: String,
		callerName: String,
		EPIRB: Boolean,
		emergency999: Boolean,
		phoneNumber: String,
		callSign: String,
	},
}
