import { enumOf, type ApiSchema } from "../Schema"
import { CardinalDirection } from "./CardinalDirection"
import { SeaState } from "./SeaState"

export interface WeatherConditions {
	seaState: SeaState

	windDirection: CardinalDirection

	windSpeedKnots: number

	/** Visibility in nautical miles */
	visibilityNm: number
}

export const WeatherConditionsSchema: ApiSchema<WeatherConditions> = {
	type: "object",
	properties: {
		seaState: enumOf(SeaState),
		windDirection: enumOf(CardinalDirection),
		windSpeedKnots: Number,
		visibilityNm: Number,
	},
}
