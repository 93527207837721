import { Instant } from "@js-joda/core"
import type { Id, UserTimeStamp } from "."
import { SystemRole, getRolePriority } from "./SystemRole"

export interface UserDetails {
	id: Id
	guid: string
	firstName: string
	lastName: string
	emailAddress: string
	role: SystemRole
}

export class User implements UserDetails {
	id: Id
	guid: string
	firstName: string
	lastName: string
	emailAddress: string
	role: SystemRole

	constructor(user: UserDetails) {
		this.id = user.id
		this.guid = user.guid
		this.firstName = user.firstName
		this.lastName = user.lastName
		this.emailAddress = user.emailAddress
		this.role = user.role
	}

	get fullName(): string {
		return [this.firstName, this.lastName].filter((text) => !!text && text.trim().length > 0).join(" ")
	}

	atLeastHasRole(role: SystemRole): boolean {
		const ownPriority = getRolePriority(this.role)
		const rolePriority = getRolePriority(role)

		return ownPriority >= rolePriority
	}

	createTimeStamp(): UserTimeStamp {
		return {
			timeStamp: Instant.now(),
			userId: this.id,
		}
	}
}
