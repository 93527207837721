import AutoCompleteTextInput from "@/components/form/AutoCompleteTextInput"
import { ChangeBuilder } from "@/modifications"
import { css } from "vite-css-in-js"
import type { InputHTMLAttributes } from "vue"
import { defineComponent, refSetter, requiredProp, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../IncidentLogHandler"
import { useIncidentInputRef } from "../../inputHelpers"

interface Props {
	path: ChangeBuilder<string | null>
}

const ShipColors: Record<string, string> = {
	White: "#ffffff",
	Blue: "#3971ef",
	Grey: "#bcbcbc",
	Red: "#ed3838",
	Green: "#3fad29",
	Orange: "#f2b035",
	Yellow: "#f2ff00",
	Beige: "#f5f5dc",
	Black: "#191919",
}

const colorBlockStyles = css`
	width: 1.25rem;
	height: 1.25rem;
	border: thin solid black;
`

const ColourInput: ReactiveComponent<Props, InputHTMLAttributes> = (props, { attrs }) => {
	const handler = useIncidentHandler()
	const inputValue = useIncidentInputRef(() => props.path)

	return () => (
		<AutoCompleteTextInput
			value={inputValue.value}
			setValue={refSetter(inputValue)}
			onOptionSelected={(option) => handler.submitChange(props.path.setTo(option))}
			onChange={() => handler.submitChange(props.path.setTo(inputValue.value))}
			options={Object.keys(ShipColors)}
			noOptionsText="Other"
			renderOption={(name) => (
				<div class="flex items-center spacing-2">
					<div class={colorBlockStyles} style={{ backgroundColor: ShipColors[name] }} />
					<span>{name}</span>
				</div>
			)}
			dropdownAttrs={{ style: { maxHeight: "none" } }}
			filter={false}
			show="blank"
			{...attrs}
		/>
	)
}

export default defineComponent(ColourInput, {
	path: requiredProp(ChangeBuilder),
})
