import { ChangeBuilder, resolvePath } from "@/modifications"
import { computed, type ButtonHTMLAttributes } from "vue"
import { ToggleButton, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../IncidentLogHandler"

interface Props {
	path: ChangeBuilder<boolean>
}

const IncidentToggleButton: ReactiveComponent<Props, Omit<ButtonHTMLAttributes, "value">> = (props, { attrs }) => {
	const handler = useIncidentHandler()
	const currentValue = computed(() => {
		const path = [...props.path.path]
		return resolvePath(path, handler.incident) as boolean
	})

	function setToggled(toggled: boolean) {
		handler.submitChange(props.path.setTo(toggled))
	}

	return () => (
		<ToggleButton
			toggled={currentValue.value}
			setToggled={setToggled}
			{...attrs}
			disabled={attrs.disabled as boolean}
		/>
	)
}

export default defineComponent(IncidentToggleButton, {
	path: requiredProp(ChangeBuilder),
})
