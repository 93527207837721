import type { Nullable } from "@/models"
import { positiveMod } from "@/utils/positiveMod"
import {
	computed,
	nextTick,
	onMounted,
	ref,
	shallowRef,
	watch,
	type HTMLAttributes,
	type InputHTMLAttributes,
	type VNodeChild,
} from "vue"
import {
	defineComponent,
	optionalProp,
	propsWithDefaults,
	refSetter,
	requiredProp,
	useOnInput,
	type ReactiveComponent,
} from "vue-utils"
import VirtualDropdownBox from "./VirtualDropdownBox"

interface Props {
	value: Nullable<string>
	setValue(value: string): void

	options: string[]
	noOptionsText: string
	onOptionSelected?(option: string): void

	/** @default true */
	openOnFocus?: boolean

	/** @default false */
	openOnMount?: boolean

	/** @default true */
	filter?: boolean

	/** @default "always" */
	show?: AutoCompleteShow

	renderOption?: (option: string) => VNodeChild

	containerAttrs?: HTMLAttributes
	dropdownAttrs?: HTMLAttributes
}

export type AutoCompleteShow = "always" | "blank" | "not-blank"

const AutoCompleteTextInput: ReactiveComponent<Props, InputHTMLAttributes> = (initialProps, { attrs }) => {
	const props = propsWithDefaults(initialProps, {
		renderOption: (option) => option,
		openOnFocus: true,
		openOnMount: false,
		filter: true,
	})

	const hoveredOption = ref<string | null>(null)
	const inputRef = shallowRef<HTMLInputElement>()
	const focussed = ref(false)

	const visibleOptions = computed(() => {
		if (!props.filter) {
			return props.options
		}

		const searchLower = props.value?.toLocaleLowerCase() ?? ""
		if (searchLower.trim().length === 0) {
			return props.options
		}
		return props.options.filter((o) => o.toLocaleLowerCase().includes(searchLower))
	})

	const dropdownVisible = computed(() => {
		if (props.options.length === 0 || !focussed.value || attrs.readonly) {
			return false
		}
		switch (props.show) {
			case "always":
				return true
			case "blank":
				return (props.value?.length ?? 0) === 0
			case "not-blank":
				return (props.value?.length ?? 0) > 0
		}
		return true
	})

	function handleOnInput(text: string) {
		props.setValue(text)
		focussed.value = true
	}

	function selectOption(option: string, focus: boolean) {
		props.setValue(option)
		props.onOptionSelected?.(option)
		focussed.value = false

		if (focus) {
			inputRef.value?.focus()
		}
	}

	function handleInputKeyDown(e: KeyboardEvent) {
		if (!dropdownVisible.value) {
			return
		}
		if ((e.key === "Enter" || (e.key === "Tab" && !e.shiftKey)) && hoveredOption.value) {
			if (e.key === "Enter") {
				e.preventDefault()
			}
			selectOption(hoveredOption.value, e.key === "Enter")
			return
		}

		if (e.key === "ArrowDown" || e.key === "ArrowUp") {
			e.preventDefault()
			moveOption(e.key === "ArrowDown" ? "down" : "up")
			return
		}
	}

	function moveOption(direction: "up" | "down") {
		const options = visibleOptions.value
		if (options.length === 0) {
			return
		}

		const selectedIndex = hoveredOption.value === null ? -1 : options.indexOf(hoveredOption.value)
		let newIndex
		if (direction === "down") {
			newIndex = selectedIndex + 1
		} else {
			newIndex = Math.max(0, selectedIndex) - 1
		}
		hoveredOption.value = options.at(positiveMod(newIndex, options.length)) ?? null
	}

	watch(dropdownVisible, (isVisible) => {
		if (!isVisible) {
			hoveredOption.value = null
		}
	})
	watch(visibleOptions, (newOptions) => {
		if (hoveredOption.value !== null && !newOptions.includes(hoveredOption.value)) {
			hoveredOption.value = null
		}
	})

	onMounted(() => {
		if (props.openOnMount) {
			inputRef.value?.focus()
			focussed.value = true
		}
	})

	return () => (
		<div class="relative" {...props.containerAttrs}>
			<input
				ref={inputRef}
				class="w-full"
				autocomplete="off"
				type="text"
				value={props.value}
				onInput={useOnInput(handleOnInput)}
				onFocus={() => props.openOnFocus && (focussed.value = true)}
				onKeydown={handleInputKeyDown}
				onBlur={() => void nextTick(() => (focussed.value = false))}
				{...attrs}
			/>
			{dropdownVisible.value && (
				<VirtualDropdownBox
					options={visibleOptions.value}
					renderOption={props.renderOption}
					noOptionsText={props.noOptionsText}
					highlightedOption={hoveredOption.value}
					setHighlightedOption={refSetter(hoveredOption)}
					selectOption={(option) => selectOption(option, true)}
					{...props.dropdownAttrs}
				/>
			)}
		</div>
	)
}

export default defineComponent(AutoCompleteTextInput, {
	value: requiredProp(String, null),
	setValue: requiredProp(Function),

	options: requiredProp(Array),
	noOptionsText: requiredProp(String),
	onOptionSelected: optionalProp(Function),

	openOnFocus: optionalProp(Boolean),
	openOnMount: optionalProp(Boolean),
	filter: optionalProp(Boolean),
	show: optionalProp(String),

	renderOption: optionalProp(Function),

	containerAttrs: optionalProp(Object),
	dropdownAttrs: optionalProp(Object),
})
