import { mapApiType, type ApiSchemaType } from "@/models/Schema"
import { Instant } from "@js-joda/core"
import { castUnsafe } from "vue-utils"
import { ObjectPathSchema, getNestedSchema, type ObjectPath } from "./path"

export interface BaseAction {
	type: string
	guid: string
	created: Instant
}

export interface AddItemAction<T = unknown> extends BaseAction {
	type: "Add"
	path: ObjectPath
	value: T
}

export interface SetFieldValueAction<T = unknown> extends BaseAction {
	type: "Set"
	path: ObjectPath
	newValue: T
}

export interface RemoveFieldAction extends BaseAction {
	type: "Remove"
	path: ObjectPath
}

export type ModificationAction = AddItemAction | SetFieldValueAction | RemoveFieldAction

export function isModificationAction(action: BaseAction): action is ModificationAction {
	return action.type === "Add" || action.type === "Set" || action.type === "Remove"
}

export const createModificationActionSchema =
	<T extends ModificationAction>(rootValueSchema: ApiSchemaType<unknown>): ApiSchemaType<T> =>
	(action) => {
		const commonData = {
			created: Instant.parse(action.created),
			guid: action.guid,
		}

		const path = mapApiType(ObjectPathSchema, action.path)
		const nestedSchema = getNestedSchema(rootValueSchema, path)

		switch (action.type) {
			case "Add":
				return {
					...commonData,
					type: "Add",
					path: mapApiType(ObjectPathSchema, action.path),
					value: mapApiType(castUnsafe(nestedSchema), action.value),
				} satisfies AddItemAction as T
			case "Set":
				return {
					...commonData,
					type: "Set",
					path: mapApiType(ObjectPathSchema, action.path),
					newValue: mapApiType(castUnsafe(nestedSchema), action.newValue),
				} satisfies SetFieldValueAction as T
			case "Remove":
				return {
					...commonData,
					type: "Remove",
					path: mapApiType(ObjectPathSchema, action.path),
				} satisfies RemoveFieldAction as T
		}
		throw new Error("Unknown action type")
	}
