import NavPanel from "@/components/navigation"
import AppHeader from "@/components/titlebar/AppHeader"
import { AppHeaderHandler, AppHeaderInjectionKey } from "@/components/titlebar/useAppHeader"
import { LoadingWrapper } from "@/composition/useLoading"
import { css } from "vite-css-in-js"
import { provide, renderSlot, type HTMLAttributes } from "vue"
import {
	ShadowContainer,
	defineComponent,
	optionalProp,
	propsWithDefaults,
	requiredProp,
	type ReactiveComponent,
} from "vue-utils"

interface Props {
	title: string
	noPadding?: boolean
	noNav?: boolean
	plain?: boolean
	scroll?: boolean
}

const regularPageStyles = css`
	grid-template-rows: auto 1fr;
	grid-template-columns: auto 1fr;
`

const noNavPageStyles = css`
	grid-template-rows: auto 1fr;
`

const sharedPageStyles = css`
	width: 100%;
	height: 100%;

	display: grid;

	@media print {
		display: contents;
		& > *:not(main) {
			display: none;
		}
		& > main {
			height: 100%;
			display: block;
			overflow: initial;
		}
	}
`

const BasicPage: ReactiveComponent<Props, HTMLAttributes> = (initialProps, { slots, attrs }) => {
	const props = propsWithDefaults(initialProps, {
		noPadding: false,
		noNav: false,
		plain: false,
		scroll: true,
	})

	provide(AppHeaderInjectionKey, new AppHeaderHandler())

	function PageContent() {
		const mainStyle = props.noPadding ? {} : { padding: "0.5rem" }
		if (props.plain) {
			return (
				<main style={mainStyle} {...attrs}>
					{renderContent()}
				</main>
			)
		}
		if (props.scroll) {
			return (
				<main class="print-contents" style={mainStyle}>
					<ShadowContainer class="print-contents w-full h-full">
						<div class="print-contents vu-scroll-pane w-full h-full">
							<div style={{ padding: "0.125rem" }} {...attrs}>
								{renderContent()}
							</div>
						</div>
					</ShadowContainer>
				</main>
			)
		}
		return (
			<main style={mainStyle}>
				<ShadowContainer class="print-contents w-full" {...attrs}>
					{renderContent()}
				</ShadowContainer>
			</main>
		)
	}

	function renderContent() {
		return <LoadingWrapper>{renderSlot(slots, "default")}</LoadingWrapper>
	}

	return () => (
		<div class={[sharedPageStyles, props.noNav ? noNavPageStyles : regularPageStyles]}>
			{!props.noNav && <NavPanel title={props.title} />}
			<AppHeader title={props.title} showLogo={props.noNav} />
			<PageContent />
		</div>
	)
}

export default defineComponent(BasicPage, {
	title: requiredProp(String),
	noPadding: optionalProp(Boolean),
	noNav: optionalProp(Boolean),
	plain: optionalProp(Boolean),
	scroll: optionalProp(Boolean),
})
