import { ContentLoading } from "@/components/loading/StateLoading"
import { IncidentStatus, type IncidentLog } from "@/models"
import BasicPage from "@/pages/BasicPage"
import NotFound from "@/pages/NotFound"
import { getIncidentLogById } from "@/services/incidentsService"
import { createCoreDataRefreshRef } from "@/stores/coreDataStore"
import { watchImmediate } from "@vueuse/core"
import { computed, reactive } from "vue"
import { useRouter } from "vue-router"
import { defineComponent, loadLoadable, useLoadableRef, type ReactiveComponent } from "vue-utils"
import EditExistingIncident from "./EditExistingIncident"
import ViewSignedOffIncident from "./ViewSignedOffIncident"

const EditIncidentPage: ReactiveComponent = () => {
	const router = useRouter()
	const coreDataRefresh = createCoreDataRefreshRef()

	const incidentLogId = computed(() => {
		const route = router.currentRoute.value
		if (!("incidentLogId" in route.params)) {
			return null
		}
		const id = Number.parseInt(String(route.params.incidentLogId))
		if (Number.isSafeInteger(id) && id > 0) {
			return id
		}
		return null
	})

	const incidentLogRef = useLoadableRef(async () => {
		const id = incidentLogId.value
		if (id === null) {
			//If no incident Id is specified or is not a valid number, fallback to nothing found
			return null
		}
		const log = await getIncidentLogById(id)
		return log ? (reactive(log) as IncidentLog) : null
	})

	watchImmediate(incidentLogId, () => void loadLoadable(incidentLogRef))

	function IncidentLogWrapper() {
		if (incidentLogRef.type !== "done" || !incidentLogRef.result) {
			return null
		}

		const incident = incidentLogRef.result
		if (incident.status === IncidentStatus.SignedOff) {
			return <ViewSignedOffIncident incident={incident} />
		}

		return <EditExistingIncident incidentId={incident.id} refresh={() => void loadLoadable(incidentLogRef)} />
	}

	function getTitle() {
		if (incidentLogRef.type !== "done" || !incidentLogRef.result) {
			return "View Incident"
		}
		const status = incidentLogRef.result.status
		if (status === IncidentStatus.Open) {
			return "Edit Incident"
		}
		return "View Incident"
	}

	return () => {
		if (incidentLogRef.type === "done" && !incidentLogRef.result) {
			return <NotFound />
		}
		return (
			<BasicPage title={getTitle()} plain scroll={false}>
				<ContentLoading stores={[incidentLogRef, coreDataRefresh]}>
					<IncidentLogWrapper />
				</ContentLoading>
			</BasicPage>
		)
	}
}

export default defineComponent(EditIncidentPage)
