import BootstrapButton from "@/components/form/BootstrapButton"
import { showError } from "@/composition/useLoading"
import { Teleport, ref, shallowRef, type HTMLAttributes } from "vue"
import {
	Modal,
	ModalTitle,
	defineComponent,
	optionalProp,
	renderSlot,
	requiredProp,
	useCustomFormValidation,
	type ReactiveComponent,
} from "vue-utils"
import { useIncidentHandler } from "../../IncidentLogHandler"

interface Props {
	title: string

	cancel(): void
	save(): Promise<void>

	saveDisabled?: boolean
}

const EditValueModal: ReactiveComponent<Props, HTMLAttributes> = (props, { slots, attrs }) => {
	const handler = useIncidentHandler()

	const submitting = ref(false)
	const formRef = shallowRef<HTMLFormElement>()
	const validation = useCustomFormValidation({ form: formRef })

	async function handleSubmit(e: Event) {
		e.preventDefault()

		const result = await validation.validateForm()
		if (!result.successful) {
			return
		}

		submitting.value = true
		try {
			await props.save()
		} catch (e) {
			void showError(e as Error)
		} finally {
			submitting.value = false
		}
	}

	return () => (
		<Teleport to="body">
			<Modal class="flex flex-col" zIndex={3} onCancel={props.cancel} {...attrs}>
				<form onSubmit={(e) => void handleSubmit(e)} style={{ display: "contents" }}>
					<ModalTitle title={props.title} />
					<hr />
					<div class="overflow-auto p-1">{renderSlot(slots)}</div>
					<hr />
					<div class="flex items-center justify-end spacing-4">
						<BootstrapButton type="button" color="secondary" onClick={props.cancel} disabled={submitting.value}>
							{handler.readOnly ? "Close" : "Cancel"}
						</BootstrapButton>
						{!handler.readOnly && (
							<BootstrapButton type="submit" color="primary" isSubmitting={submitting.value}>
								Save
							</BootstrapButton>
						)}
					</div>
				</form>
			</Modal>
		</Teleport>
	)
}

export default defineComponent(EditValueModal, {
	title: requiredProp(String),

	cancel: requiredProp(Function),
	save: requiredProp(Function),

	saveDisabled: optionalProp(Boolean),
})
