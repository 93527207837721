export * from "./BasicErrorPage"

import type { Component } from "vue-utils"
import { createStateLoading } from "vue-utils"
import { BasicErrorPage } from "./BasicErrorPage"
import FullScreenLoading from "./FullScreenLoading"
import LoadingSpinner from "./LoadingSpinner"

export const StateLoading = createStateLoading({
	LoadingComponent: FullScreenLoading as Component,
	ErrorComponent: BasicErrorPage,
})

export const ContentLoading = createStateLoading({
	LoadingComponent: () => (
		<div class="flex h-full items-center justify-center">
			<LoadingSpinner />
		</div>
	),
	ErrorComponent: BasicErrorPage,
})
