import { enumOf, type ApiSchema } from "./Schema"

export interface UnitLength {
	length: number
	unit: LengthUnit
}

export enum LengthUnit {
	Metres = "Metres",
	Feet = "Feet",
}

export const UnitLengthSchema: ApiSchema<UnitLength> = {
	type: "object",
	properties: {
		length: Number,
		unit: enumOf(LengthUnit),
	},
}
