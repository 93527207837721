import { Instant } from "@js-joda/core"
import type { ApiTypeOf } from "./ApiType"
import { EmergencyPhase } from "./EmergencyPhase"
import type { IIdentifier, Nullable } from "./IncidentEntity"
import { IncidentStatus } from "./IncidentStatus"
import { LatLngSchema, type LatLng } from "./LatLng"
import type { PrimaryIncidentType } from "./PrimaryIncidentType"
import { InstantSchema, enumOf, mapApiType, type ApiSchema } from "./Schema"
import { UserTimeStampSchema, type UserTimeStamp } from "./UserTimeStamp"

export interface IncidentLogSummary extends IIdentifier {
	identifier: string
	incidentTime: Instant
	status: IncidentStatus
	createdAt: Instant

	primaryIncidentType: PrimaryIncidentType
	emergencyPhase: EmergencyPhase
	nameOfVesselOrCasualty: Nullable<string>
	personsAtRisk: number
	position: LatLng

	messageReceived: string
	natureOfIncident: Nullable<string>
	descriptionOfCasualty: Nullable<string>

	signedOff: Nullable<UserTimeStamp>
}

export const IncidentLogSummarySchema: ApiSchema<IncidentLogSummary> = {
	type: "object",
	properties: {
		id: Number,
		identifier: String,
		status: enumOf(IncidentStatus),
		incidentTime: InstantSchema,
		createdAt: InstantSchema,

		primaryIncidentType: {
			type: "object",
			properties: {
				id: Number,
				name: String,
			},
		},
		emergencyPhase: enumOf(EmergencyPhase),
		nameOfVesselOrCasualty: String,
		personsAtRisk: Number,
		position: LatLngSchema,

		messageReceived: String,
		natureOfIncident: String,
		descriptionOfCasualty: String,

		signedOff: UserTimeStampSchema,
	},
}

export function mapApiIncidentLogSummary(log: ApiTypeOf<IncidentLogSummary>): IncidentLogSummary {
	return mapApiType(IncidentLogSummarySchema, log)
}
