import CoordinateInput from "@/components/CoordinateInput"
import { ChangeBuilder } from "@/modifications"
import { defineComponent, optionalProp, refSetter, requiredProp, type ReactiveComponent } from "vue-utils"
import { useIncidentAutoSubmitInputRef } from "../inputHelpers"

export type CoordinateType = "latitude" | "longitude"

interface Props {
	type: "latitude" | "longitude"
	path: ChangeBuilder<number>

	required?: boolean
	disabled?: boolean
}

const IncidentCoordinateInput: ReactiveComponent<Props> = (props) => {
	const inputValue = useIncidentAutoSubmitInputRef(() => props.path, { debounced: false })

	return () => (
		<CoordinateInput
			type={props.type}
			value={inputValue.value}
			setValue={refSetter(inputValue)}
			required={props.required}
			disabled={props.disabled}
		/>
	)
}

export default defineComponent(IncidentCoordinateInput, {
	type: requiredProp(String),
	path: requiredProp(ChangeBuilder),

	required: optionalProp(Boolean),
	disabled: optionalProp(Boolean),
})
