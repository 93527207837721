import BootstrapButton from "@/components/form/BootstrapButton"
import Icon from "@/components/Icon"
import usePopups from "@/stores/popupsStore"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { Alert, defineComponent, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../IncidentLogHandler"

const CreateIncident: ReactiveComponent = () => {
	const popups = usePopups()
	const handler = useIncidentHandler()

	async function handleCreateClick() {
		if (!(await handler.validateForm())) {
			return
		}
		const confirmation = await popups.confirm({
			confirmText: "Create",
			content: () => (
				<Alert title="Confirm Create Incident">
					Please confirm that all details are correct before saving, as{" "}
					<strong style={{ fontWeight: "600" }}>you may not be able to change some details later.</strong>
				</Alert>
			),
		})
		if (confirmation) {
			await handler.createIncident()
		}
	}

	return () => (
		<div class="flex mt-3 spacing-4 flex-wrap items-center">
			{handler.isNewIncident && !handler.readOnly && (
				<BootstrapButton color="primary" type="button" onClick={() => void handleCreateClick()}>
					<Icon icon={faSave} />
					<span>Create Incident Log</span>
				</BootstrapButton>
			)}
		</div>
	)
}

export default defineComponent(CreateIncident)
