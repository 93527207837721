import type { EmergencyPhase, Id, Nullable, SortDirection } from "."
import type { IncidentLogSummary } from "./IncidentLogSummary"
import type { IncidentStatus } from "./IncidentStatus"

export interface SearchIncidentsResult {
	globalTotal: number
	totalItems: number
	pageSize: number
	totalPages: number

	incidents: IncidentLogSummary[]
}

export interface SearchIncidentsRequest {
	pageSize: number
	page: number
	filter: SearchIncidentsFilter
	sortBy?: IncidentsSortBy
	sortDirection?: SortDirection
}

export interface SearchIncidentsFilter {
	year: Nullable<number>
	statuses: IncidentStatus[]
	primaryIncidentTypeIds: Id[]
	emergencyPhases: EmergencyPhase[]
}

export enum IncidentsSortBy {
	IncidentId = "incidentId",
	IncidentTime = "incidentTime",
	CreatedAt = "createdAt",
	PrimaryIncidentType = "primaryIncidentType",
	EmergencyPhase = "emergencyPhase",
	NameOfVesselOrCasualty = "nameOfVesselOrCasualty",
	PersonsAtRisk = "personsAtRisk",
	SignOff = "signOff",
	Status = "status",
}
