import type { UserTimeStamp } from "@/models"
import { useCoreData } from "@/stores/coreDataStore"
import { computed } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	timeStamp: UserTimeStamp
}

const UserDetails: ReactiveComponent<Props> = (props) => {
	const coreData = useCoreData()
	const signedOffBy = computed(() => {
		return coreData.users.get(props.timeStamp.userId) ?? null
	})

	return () => (
		<>
			{!!signedOffBy.value && (
				<span>
					by <strong title={signedOffBy.value.emailAddress}>{signedOffBy.value.fullName}</strong>
				</span>
			)}{" "}
			at <strong>{props.timeStamp.timeStamp.toUTCDateTimeString()}</strong> (UTC)
		</>
	)
}

export default defineComponent(UserDetails, {
	timeStamp: requiredProp(Object),
})
