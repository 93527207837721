import { useRouterTabs } from "@/components/tabs/RouterTabs"
import { onMounted, unref, type MaybeRef, type Ref } from "vue"
import { debounce } from "vue-utils"
import type { IncidentFormSection } from "./IncidentFormSection"

export function useIncidentLogFormScrolling(options: {
	sections: MaybeRef<IncidentFormSection[]>
	contentRef: Ref<HTMLElement | undefined>
}) {
	const { tab: section, setTab: setSection } = useRouterTabs(options.sections)

	let scrollTimeOut: number | undefined = undefined
	function jumpToSection(tabId = section.value.id) {
		const heading = document.getElementById(tabId)
		if (!heading) {
			return
		}
		heading.scrollIntoView({
			behavior: "smooth",
		})
		if (scrollTimeOut !== undefined) {
			window.clearTimeout(scrollTimeOut)
		}
		scrollTimeOut = window.setTimeout(() => (scrollTimeOut = undefined), 2000)
	}
	onMounted(jumpToSection)

	onMounted(() => {
		options.contentRef.value!.addEventListener(
			"scroll",
			debounce(() => {
				const container = options.contentRef.value
				if (!container || scrollTimeOut !== undefined) {
					return
				}
				const contentBounds = container.getBoundingClientRect()
				for (const tab of unref(options.sections)) {
					const bounds = document.getElementById(tab.id)?.parentElement?.getBoundingClientRect()
					if (bounds && bounds.bottom - 50 >= contentBounds.top && bounds.top <= contentBounds.bottom) {
						setSection(tab.id)
						break
					}
				}
			})
		)
	})

	return {
		section,
		setSection: (newId: string) => {
			setSection(newId)
			jumpToSection(newId)
		},
	}
}
