import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import { useLoading } from "@/composition/useLoading"
import type { AssetTasked } from "@/models"
import type { ModificationAction } from "@/modifications"
import { useCoreDataStore } from "@/stores/coreDataStore"
import usePopups from "@/stores/popupsStore"
import type { Immutable } from "@/utils/Immutable"
import { proxyProp } from "@/utils/proxyProp"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { ModalTitle, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../IncidentLogHandler"
import CrewCell from "./crew/CrewCell"
import NotesCell from "./notes/NotesCell"
import SRUCell from "./sru/SRUCell"
import TaskingInstructionsCell from "./tasking-instructions/TaskingInstructionsCell"
import AssetTimeCell from "./time/AssetTimeCell"

interface Props {
	asset: Immutable<AssetTasked>
}

const AssetTaskedRow: ReactiveComponent<Props> = (props) => {
	const { runAction } = useLoading()

	const handler = useIncidentHandler()
	const asset = proxyProp(props, "asset")
	const coreDataStore = useCoreDataStore()
	const popups = usePopups()

	function changeBuilder() {
		return handler.changeBuilder.at("assetsTasked").id(props.asset.id)
	}

	async function update<K extends keyof AssetTasked>(key: K, value: AssetTasked[K]) {
		await handler.awaitChange(changeBuilder().at(key).setTo(value))
	}

	async function handleDeleteClick() {
		const assetName = asset.customName ?? coreDataStore.lookupSUR(asset.SRUId)?.name ?? "this asset"
		const confirmation = await popups.confirm(() => (
			<>
				<ModalTitle title="Confirm remove asset launch" />
				<hr />
				Are you sure you wish to remove information about {assetName}?
			</>
		))

		if (confirmation) {
			await runAction(handler.awaitChange(changeBuilder().remove()))
		}
	}

	return () => (
		<tr key={asset.id === 0 ? Math.random() : asset.id}>
			<SRUCell
				asset={asset}
				updateAsset={async (data) => {
					const builder = changeBuilder()
					const changes: ModificationAction[] = [
						builder.at("SRUId").setTo(data.SRUId),
						builder.at("customName").setTo(data.customName),
					]

					if (data.crewLists !== undefined) {
						changes.push(builder.at("crewLists").setTo(data.crewLists))
					}

					await Promise.all(changes.map((change) => handler.awaitChange(change)))
				}}
			/>
			<CrewCell asset={asset} setCrew={(crew) => update("crewLists", crew)} />
			<AssetTimeCell value={asset.alerted} setValue={(t) => update("alerted", t)} />
			<AssetTimeCell value={asset.departed} setValue={(t) => update("departed", t)} />
			<AssetTimeCell value={asset.onScene} setValue={(t) => update("onScene", t)} />
			<AssetTimeCell value={asset.returning} setValue={(t) => update("returning", t)} />
			<AssetTimeCell value={asset.pierHeads} setValue={(t) => update("pierHeads", t)} />
			<AssetTimeCell value={asset.backOnStation} setValue={(t) => update("backOnStation", t)} />
			<TaskingInstructionsCell
				instructions={asset.taskingInstructions}
				setInstructions={(instructions) => update("taskingInstructions", instructions)}
			/>
			<NotesCell notes={asset.notes} setNotes={(notes) => update("notes", notes)} />
			<td>
				{!handler.readOnly && (
					<BootstrapButton color="danger" title="Remove asset launch" onClick={() => void handleDeleteClick()}>
						<Icon icon={faTrashAlt} />
					</BootstrapButton>
				)}
			</td>
		</tr>
	)
}

export default defineComponent(AssetTaskedRow, {
	asset: requiredProp(Object),
})
