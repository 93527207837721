import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import type { Nullable } from "@/models"
import { faCalendarXmark } from "@fortawesome/free-solid-svg-icons"
import { Instant } from "@js-joda/core"
import { computed, ref } from "vue"
import { defineComponent, refSetter, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../../IncidentLogHandler"
import EditValueModal from "../EditValueModal"

interface Props {
	existing: Nullable<Instant>

	cancel(): void
	set(time: Nullable<Instant>): Promise<void>
}

const EditAssetTime: ReactiveComponent<Props> = (props) => {
	const handler = useIncidentHandler()

	const editingText = ref(props.existing?.toHTMLInputUTC() ?? "")
	const newValue = computed(() => {
		if (editingText.value.length === 0) {
			return null
		}
		try {
			return Instant.fromHtmlInputUTC(editingText.value)
		} catch (e) {
			return null
		}
	})

	return () => (
		<EditValueModal
			title={handler.readOnly ? "View Time" : "Edit Time"}
			cancel={props.cancel}
			save={() => props.set(newValue.value)}
			saveDisabled={editingText.value.length > 0 && !newValue.value}
			style={{ width: "24rem" }}
		>
			<div class="flex items-center spacing-2">
				<input
					class="flex-grow-1"
					type="datetime-local"
					value={editingText.value}
					onInput={useOnInput(refSetter(editingText))}
					disabled={handler.readOnly}
				/>
				<BootstrapButton title="Clear" type="button" color="danger" onClick={() => (editingText.value = "")}>
					<Icon icon={faCalendarXmark} />
				</BootstrapButton>
			</div>
		</EditValueModal>
	)
}

export default defineComponent(EditAssetTime, {
	existing: requiredProp(Instant, null),

	cancel: requiredProp(Function),
	set: requiredProp(Function),
})
