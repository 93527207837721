export enum SystemRole {
	SuperAdmin = "SuperAdmin",

	/**
	 * SMC = SAR Mission Coordinator
	 * SAR = Search and Rescue
	 *
	 * Therefore SMC = Search and Rescue Mission Coordinator
	 */
	SMC = "SMC",

	/**
	 * Standard user
	 */
	CoastguardOfficer = "CoastguardOfficer",

	ReadOnly = "ReadOnly",
}

const rolePriorities: Record<SystemRole, number> = {
	[SystemRole.SuperAdmin]: 100,
	[SystemRole.SMC]: 50,
	[SystemRole.CoastguardOfficer]: 10,
	[SystemRole.ReadOnly]: 1,
}

/**
 * @returns A number value of the role's priority. Higher values indicate a higher level of role
 */
export function getRolePriority(role: SystemRole): number {
	return rolePriorities[role]
}
