import { faCog } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { useRouter } from "vue-router"
import { Link, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import Icon from "../Icon"
import UserIndicator from "./UserIndicator"

interface Props {
	firstName: string
	lastName: string
}

const buttonStyles = css`
	display: flex;
	align-items: center;
	gap: 0.75rem;
	border: none;
	background-color: transparent;
	padding: 0.35rem;
	padding-left: 1rem;
	padding-right: 1rem;
	transition: 0.25s;
	height: 100%;
	text-decoration: none;

	&:hover {
		background-color: rgba(109, 119, 148, 0.35);
	}
	cursor: pointer;

	& > svg {
		font-size: 1.25rem;
		color: #333;
	}
`

const ProfileButton: ReactiveComponent<Props> = (props) => {
	const router = useRouter()
	const isOnPage = computed(() => router.currentRoute.value.path === "/profile")

	return () => (
		<Link href="/profile" class={buttonStyles} title={isOnPage.value ? "" : "Open Profile"}>
			<Icon icon={faCog} />
			<UserIndicator color="#2761ab" firstName={props.firstName} lastName={props.lastName} showName={false} />
		</Link>
	)
}

export default defineComponent(ProfileButton, {
	firstName: requiredProp(String),
	lastName: requiredProp(String),
})
