import { createRouter, createWebHistory, type RouteComponent, type RouteRecordRaw } from "vue-router"
import IncidentLogsPage from "./all-incident-logs"
import CreateIncidentPage from "./create-incident"
import EditIncidentPage from "./edit-incident"
import IncidentMapPage from "./incident-map"
import NotFound from "./NotFound"
import ProfilePage from "./profile"

const simpleRoutes: Record<string, RouteComponent> = {
	"/incident-map": IncidentMapPage,
	"/incidents/create": CreateIncidentPage,
	"/incidents/id/:incidentLogId": EditIncidentPage,
	"/incidents/list": IncidentLogsPage,
	"/profile": ProfilePage,
	"/:pathMatch(.*)*": NotFound,
}
const routes: RouteRecordRaw[] = [
	...Object.entries(simpleRoutes).map(([path, component]) => ({ path, component, name: component.name })),
]

const router = createRouter({
	routes: [
		...routes,
		{
			path: "/",
			redirect: "/incident-map",
		},
	],
	history: createWebHistory(),
})

export default router
