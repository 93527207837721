import type { LatLng, Nullable } from "@/models"
import { LatLngFormatType, formatLatLng } from "@/utils/formatting/formatLatLng"
import { watchImmediate } from "@vueuse/core"
import L, { Polyline, Popup, type LatLngTuple, type Map } from "leaflet"
import { css } from "vite-css-in-js"
import { shallowRef, toRef, watch } from "vue"
import {
	Modal,
	ModalTitle,
	defineComponent,
	optionalProp,
	refSetter,
	requiredProp,
	type ReactiveComponent,
} from "vue-utils"
import BootstrapButton from "../form/BootstrapButton"
import LeafletMap from "./LeafletMap"
import LocationMarker from "./LocationMarker"
import SelectTypeToggle, { LocationSelectType } from "./SelectTypeToggle"

interface Props {
	locationName: string
	location: Nullable<LatLng>
	setLocation(location: LatLng): void
	cancel(): void
	readOnly?: boolean
}

const mapStyles = css`
	cursor: crosshair;

	.leaflet-interactive,
	.leaflet-map-pane,
	.leaflet-control-container,
	.leaflet-grab {
		cursor: crosshair;
	}
	.leaflet-drag-target {
		cursor: grab;
	}
`

function withinRange(value: number, min: number, max: number) {
	const range = max - min
	while (value < min) {
		value += range
	}
	while (value > max) {
		value -= range
	}
	return value
}

function fixLatLng(location: LatLng): LatLng {
	return {
		latitude: withinRange(location.latitude, -90, 90),
		longitude: withinRange(location.longitude, -180, 180),
	}
}

const MapLocationSelect: ReactiveComponent<Props> = (props) => {
	const currentlySelected = shallowRef<Nullable<LatLng>>(props.location)
	const distanceStartCoords = shallowRef<Nullable<LatLng>>(null)
	const selectType = shallowRef<LocationSelectType>(LocationSelectType.Point)

	let map: Map
	let pin: LocationMarker | null = null
	let distanceStart: LocationMarker | null = null
	let distanceLine: Polyline | null = null
	let distancePopup: Popup | null = null

	function initMap(lMap: Map) {
		map = lMap

		if (props.location) {
			map.setView([props.location.latitude, props.location.longitude], map.getZoom(), {
				animate: false,
			})
		}

		updatePin()
		lMap.addEventListener("click", (clickEvent) => {
			if (props.readOnly) {
				return
			}

			if (selectType.value === LocationSelectType.Point) {
				if (distanceLine) {
					distanceLine.remove()
					distancePopup = null
					distanceStartCoords.value = null
				}

				currentlySelected.value = {
					latitude: clickEvent.latlng.lat,
					longitude: clickEvent.latlng.lng,
				}
			} else if (selectType.value === LocationSelectType.Distance) {
				if (distanceLine) {
					distanceLine.remove()
				}

				if (!distanceStartCoords.value) {
					currentlySelected.value = null
					distanceStartCoords.value = {
						latitude: clickEvent.latlng.lat,
						longitude: clickEvent.latlng.lng,
					}
				} else {
					distancePopup = null
					distanceStartCoords.value = null
					currentlySelected.value = {
						latitude: clickEvent.latlng.lat,
						longitude: clickEvent.latlng.lng,
					}
				}
			}
		})
		lMap.addEventListener("mousemove", (mouseEvent) => {
			if (
				!!props.readOnly ||
				selectType.value === LocationSelectType.Point ||
				!!currentlySelected.value ||
				!distanceStartCoords.value
			) {
				return
			}

			const from: LatLngTuple = [distanceStartCoords.value.latitude, distanceStartCoords.value.longitude]
			const to: LatLngTuple = [mouseEvent.latlng.lat, mouseEvent.latlng.lng]
			const coords: LatLngTuple[] = [from, to]

			if (distanceLine) {
				distanceLine.remove()
			}

			const latLngTo: LatLng = { latitude: mouseEvent.latlng.lat, longitude: mouseEvent.latlng.lng }
			const distance = L.latLng(from).distanceTo(to)
			const nauticalDistance = distance / 1852
			const bearing = calcAngle(distanceStartCoords.value, latLngTo)

			const popupText = `Distance: ${nauticalDistance.toFixed(2)}nm; Bearing: ${bearing.toFixed(1)}°`

			distanceStart?.setRadius(distance)
			distanceStart?.setDirection(bearing, 35)

			if (!distancePopup) {
				distancePopup = L.popup({
					closeButton: false,
					closeOnEscapeKey: false,
					offset: [150, 40],
					className: "direction-popup",
				})
					.setLatLng(to)
					.setContent(popupText)
					.openOn(map)
			}
			distancePopup.setLatLng(to)
			distancePopup.setContent(popupText)

			distanceLine = L.polyline(coords, { color: "#b4611c" })
			distanceLine.addTo(map)
		})
	}

	function updatePin() {
		if (!map) {
			return
		}
		const loc = currentlySelected.value
		if (!loc) {
			if (pin) {
				pin.removeFrom(map)
				pin = null
			}
			return
		}
		const tooltipDiv = document.createElement("div")

		const title = document.createElement("span")
		title.innerText = props.locationName

		const fixedLoc = fixLatLng(loc)

		tooltipDiv.append(title)
		tooltipDiv.append(document.createElement("br"))
		tooltipDiv.append(new Text(formatLatLng(fixedLoc, LatLngFormatType.DegreesDecimalMinutes)))

		if (pin) {
			pin.moveTo(loc.latitude, loc.longitude)
			pin.locationMarker.getTooltip()?.setContent(tooltipDiv.innerHTML)
		} else {
			pin = new LocationMarker({
				latitude: loc.latitude,
				longitude: loc.longitude,
				tooltipHtml: tooltipDiv.innerHTML,
				tooltipPermanent: true,
			})
			pin.addTo(map)
		}
	}

	function startDistance() {
		if (!map) {
			return
		}

		const loc = distanceStartCoords.value
		if (!loc) {
			if (distanceStart) {
				distanceStart.removeFrom(map)
				distanceStart = null
			}
			return
		}

		if (distanceStart) {
			distanceStart.moveTo(loc.latitude, loc.longitude)
		} else {
			distanceStart = new LocationMarker({
				latitude: loc.latitude,
				longitude: loc.longitude,
				radius: 0,
				radiusOptions: {
					color: "#ff8612",
				},
				markerStyles: {
					filter: "hue-rotate(180deg) brightness(95%)",
				},
			})
			distanceStart.addTo(map)
		}
	}

	/// Get bearing of point 2 from point 1
	/// Leaflet PolylineMeasure plugin by @ppete2
	/// https://github.com/ppete2/Leaflet.PolylineMeasure/blob/9908e5a2cbd2753c335ac758f3559ba9da6f99e9/Leaflet.PolylineMeasure.js#L740
	function calcAngle(p1: LatLng, p2: LatLng, outbound = true): number {
		const p1Lat = (p1.latitude / 180) * Math.PI
		const p1Lng = (p1.longitude / 180) * Math.PI
		const p2Lat = (p2.latitude / 180) * Math.PI
		const p2Lng = (p2.longitude / 180) * Math.PI
		const y = Math.sin(p2Lng - p1Lng) * Math.cos(p2Lat)
		const x = Math.cos(p1Lat) * Math.sin(p2Lat) - Math.sin(p1Lat) * Math.cos(p2Lat) * Math.cos(p2Lng - p2Lng)
		const bearing = Math.round((Math.atan2(y, x) * 180) / Math.PI + (outbound ? 360 : 180))
		return bearing % 360
	}

	function handleConfirm() {
		if (currentlySelected.value) {
			props.setLocation(fixLatLng(currentlySelected.value))
		}
	}

	watch(toRef(props, "location"), (newLoc) => (currentlySelected.value = newLoc))
	watchImmediate(toRef(props, "locationName"), updatePin)
	watchImmediate(currentlySelected, updatePin)
	watchImmediate(distanceStartCoords, startDistance)

	return () => (
		<Modal zIndex={4} style={{ width: "80%", height: "90%" }} class="flex flex-col" onCancel={props.cancel}>
			<ModalTitle title={`${props.readOnly ? "View" : "Select"} ${props.locationName}`} />
			<hr />
			<LeafletMap
				initMap={initMap}
				zoomControlPosition="bottomright"
				class={["flex-grow-1", props.readOnly ? undefined : mapStyles]}
			>
				<SelectTypeToggle selectType={selectType.value} setSelectType={refSetter(selectType)} />
			</LeafletMap>
			<hr />
			<div class="flex items-center justify-end spacing-4">
				<BootstrapButton color="secondary" onClick={props.cancel}>
					{props.readOnly ? "Close" : "Cancel"}
				</BootstrapButton>
				{!props.readOnly && (
					<BootstrapButton color="primary" onClick={handleConfirm} disabled={!currentlySelected.value}>
						Select Location
					</BootstrapButton>
				)}
			</div>
		</Modal>
	)
}

export default defineComponent(MapLocationSelect, {
	locationName: requiredProp(String),
	location: requiredProp(Object, null),
	setLocation: requiredProp(Function),
	cancel: requiredProp(Function),
	readOnly: optionalProp(Boolean),
})
