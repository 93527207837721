import Icon from "@/components/Icon"
import type { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type Component, type ReactiveComponent } from "vue-utils"

export interface IncidentFormSection {
	id: string
	name: string
	icon: IconDefinition
	render?: Component
	color?: string
}

const sectionStyles = css`
	padding: 1.25rem;
	padding-top: 0;
	padding-bottom: 2rem;

	& > h5 {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		font-size: 1.125rem;
		padding-top: 1.25rem;
	}
`

interface Props {
	section: IncidentFormSection
}

const IncidentFormSection: ReactiveComponent<Props> = (props) => {
	return () => (
		<section class={sectionStyles} style={{ backgroundColor: props.section.color }}>
			<h5 id={props.section.id}>
				<Icon icon={props.section.icon} />
				<span>{props.section.name}</span>
			</h5>
			<hr class="my-2" />
			{props.section.render && <props.section.render />}
		</section>
	)
}

export default defineComponent(IncidentFormSection, {
	section: requiredProp(Object),
})
