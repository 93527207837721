import { css } from "vite-css-in-js"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../IncidentLogHandler"
import AddNarrative from "./AddNarrative"
import NarrativeDisplay from "./NarrativeDisplay"

const listStyles = css`
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
`

const IncidentNarrative: ReactiveComponent = () => {
	const handler = useIncidentHandler()
	const narrative = computed(() => {
		const entries = handler.incident.incidentNarrative ? [...handler.incident.incidentNarrative] : []
		return entries.sort((n1, n2) => n1.creationMetadata.timeStamp.compareTo(n2.creationMetadata.timeStamp))
	})

	return () => (
		<>
			<ul class={listStyles}>
				{narrative.value.map((note, i) => (
					<li key={i}>
						{i !== 0 && <hr />}
						<NarrativeDisplay note={note} />
					</li>
				))}
			</ul>
			{!handler.readOnly && <AddNarrative />}
		</>
	)
}

export default defineComponent(IncidentNarrative)
