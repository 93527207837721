import { IncidentStatus, SystemRole } from "@/models"
import { useLoggedInUser } from "@/stores/coreDataStore"
import { proxyProp } from "@/utils/proxyProp"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import type { JSX } from "vue/jsx-runtime"
import { useIncidentHandler } from "../../IncidentLogHandler"
import CloseIncidentButton from "./CloseIncidentButton"
import ReopenIncidentButton from "./ReopenIncidentButton"
import SignOffButton from "./SignOffButton"
import UserDescriptor from "./UserDescriptor"

const buttonListStyles = css`
	display: inline-grid;
	gap: 1rem;

	list-style: none;
	padding: 0;
	margin: 0;

	li {
		display: contents;
	}
`

const StatusActions: ReactiveComponent = () => {
	const handler = useIncidentHandler()
	const loggedInUser = useLoggedInUser()
	const incident = proxyProp(() => handler.incident)

	function canReopen() {
		return incident.status === IncidentStatus.Closed && loggedInUser.atLeastHasRole(SystemRole.CoastguardOfficer)
	}
	function canSignOff() {
		return incident.status !== IncidentStatus.SignedOff && loggedInUser.atLeastHasRole(SystemRole.SMC)
	}
	function canClose() {
		return incident.status === IncidentStatus.Open && loggedInUser.atLeastHasRole(SystemRole.CoastguardOfficer)
	}

	const additionalButtons = computed(() => {
		const buttons: JSX.Element[] = []
		if (canReopen()) {
			buttons.push(<ReopenIncidentButton key="reopen" />)
		}
		if (canClose()) {
			buttons.push(<CloseIncidentButton key="close" />)
		}
		if (canSignOff()) {
			buttons.push(<SignOffButton key="sign-off" />)
		}
		return buttons
	})

	return () => (
		<>
			{handler.incident.status === IncidentStatus.Open && !!handler.incident.reopened && (
				<UserDescriptor actionName="reopened" timeStamp={handler.incident.reopened} />
			)}
			{handler.incident.status === IncidentStatus.Closed && (
				<UserDescriptor actionName="closed" timeStamp={handler.incident.closed} />
			)}
			{handler.incident.status === IncidentStatus.SignedOff && (
				<UserDescriptor actionName="signed off" timeStamp={handler.incident.signedOff} />
			)}
			{additionalButtons.value.length > 0 && (
				<ol class={buttonListStyles} style={{ gridTemplateColumns: `repeat(${additionalButtons.value.length}, auto)` }}>
					{additionalButtons.value.map((btn) => (
						<li key={btn.key!}>{btn}</li>
					))}
				</ol>
			)}
		</>
	)
}

export default defineComponent(StatusActions)
