export enum CardinalDirection {
	N = "N",
	NNE = "NNE",
	NE = "NE",
	ENE = "ENE",
	E = "E",
	ESE = "ESE",
	SE = "SE",
	SSE = "SSE",
	S = "S",
	SSW = "SSW",
	SW = "SW",
	WSW = "WSW",
	W = "W",
	WNW = "WNW",
	NW = "NW",
	NNW = "NNW",
}
