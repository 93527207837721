export enum EmergencyPhase {
	Distress = "Distress",
	Alert = "Alert",
	Uncertainty = "Uncertainty",
	NonSAR = "NonSAR",
	Monitoring = "Monitoring",
}

export function getEmergencyPhaseLeafletFilter(phase: EmergencyPhase): string {
	switch (phase) {
		case EmergencyPhase.Distress:
			return "hue-rotate(150deg) brightness(105%)"
		case EmergencyPhase.Alert:
			return "hue-rotate(180deg) brightness(130%)"
		case EmergencyPhase.Uncertainty:
			return "hue-rotate(200deg) brightness(150%)"
		case EmergencyPhase.NonSAR:
			return "hue-rotate(250deg) brightness(115%)"
		case EmergencyPhase.Monitoring:
			return "brightness(110%)"
	}
	throw new Error(`Unknown phase ${phase as string}`)
}
