import tinycolor from "tinycolor2"
import { css } from "vite-css-in-js"
import { computed, type HTMLAttributes } from "vue"
import {
	calculateForegroundColor,
	defineComponent,
	optionalProp,
	propsWithDefaults,
	requiredProp,
	type ReactiveComponent,
} from "vue-utils"

interface Props {
	firstName: string
	lastName: string

	showName?: boolean
}

const styles = css`
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0.5);
	border-radius: 100%;
	padding: 0.25rem;
	font-size: 1.25rem;
	width: 2.125rem;
	height: 2.125rem;
	user-select: none;
`

function firstLetter(str: string) {
	return str.length === 0 ? "" : str[0]
}

function firstLetterCode(str: string) {
	if (str.length === 0) {
		return 0
	}
	return str[0].toUpperCase().charCodeAt(0) - "A".charCodeAt(0)
}

const UserIndicator: ReactiveComponent<Props, HTMLAttributes> = (initialProps, { attrs }) => {
	const props = propsWithDefaults(initialProps, {
		showName: true,
	})

	const userInitials = computed(() => {
		return firstLetter(props.firstName) + firstLetter(props.lastName)
	})

	const color = computed(() => {
		const index = firstLetterCode(props.lastName) + firstLetterCode(props.firstName) * 27
		const hue = index / (27 * 27)

		const color = tinycolor.fromRatio({ h: hue, s: 0.8, v: 0.8 })
		return color.toHexString()
	})

	return () => (
		<div
			class={styles}
			style={{ backgroundColor: color.value, color: calculateForegroundColor(color.value) }}
			title={props.showName ? `${props.firstName} ${props.lastName}` : undefined}
			{...attrs}
		>
			{userInitials.value}
		</div>
	)
}

export default defineComponent(UserIndicator, {
	firstName: requiredProp(String),
	lastName: requiredProp(String),
	showName: optionalProp(Boolean),
})
