import type { UserTimeStamp } from "@/models"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import UserDetails from "./UserDetails"

interface Props {
	actionName: string
	timeStamp: UserTimeStamp | null
}

const UserDescriptor: ReactiveComponent<Props> = (props) => {
	return () => (
		<p>
			This incident log has been {props.actionName}
			{!!props.timeStamp && (
				<>
					{" "}
					<UserDetails timeStamp={props.timeStamp} />
				</>
			)}
			.
		</p>
	)
}

export default defineComponent(UserDescriptor, {
	actionName: requiredProp(String),
	timeStamp: requiredProp(Object, null),
})
