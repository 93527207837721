import { css } from "vite-css-in-js"
import { computed, reactive, toRef } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../../IncidentLogHandler"
import EditValueModal from "../EditValueModal"
import { useWatchExternalChanges } from "../useWatchChanges"

interface Props {
	totalCrew: number
	existing: number[]

	cancel(): void
	setCrew(crew: number[]): Promise<void>
}

const crewStyles = css`
	margin-top: 0.35rem;
	list-style: none;
	padding: 0;

	columns: 3rem auto;
	width: 32rem;

	display: grid;
	grid-template-columns: repeat(auto-fill, 4rem);

	label {
		flex-direction: row;
		align-items: center;
		gap: 0.35rem;
		padding: 0.25rem;
		padding-bottom: 0;
		padding-right: 0.5rem;
		user-select: none;

		input {
			padding: 0;
			height: auto;
		}
	}
`

const EditCrew: ReactiveComponent<Props> = (props) => {
	const handler = useIncidentHandler()
	const crew = reactive(new Set(props.existing))

	useWatchExternalChanges({
		name: "Crew",
		currentValue: computed({
			get: () => Array.from(crew),
			set(newCrew) {
				crew.clear()
				for (const crewNo of newCrew) {
					crew.add(crewNo)
				}
			},
		}),
		equals(crew1, crew2) {
			const c1 = new Set(crew1)
			const c2 = new Set(crew2)
			return c1.size === c2.size && [...c1].every((c) => c2.has(c))
		},
		globalValue: toRef(props, "existing"),
	})

	function handleCrewToggle(e: Event, number: number) {
		const input = e.target as HTMLInputElement
		if (input.checked) {
			crew.add(number)
		} else {
			crew.delete(number)
		}
	}

	return () => (
		<EditValueModal
			title={handler.readOnly ? "View Crew" : "Edit Crew"}
			cancel={props.cancel}
			save={() => props.setCrew(Array.from(crew))}
		>
			<ol class={crewStyles}>
				{Array.from({ length: props.totalCrew }, (_, i) => (
					<li key={i}>
						<label>
							<input
								type="checkbox"
								checked={crew.has(i + 1)}
								onChange={(e) => handleCrewToggle(e, i + 1)}
								disabled={handler.readOnly}
							/>
							{i + 1}
						</label>
					</li>
				))}
			</ol>
		</EditValueModal>
	)
}

export default defineComponent(EditCrew, {
	totalCrew: requiredProp(Number),
	existing: requiredProp(Array),

	cancel: requiredProp(Function),
	setCrew: requiredProp(Function),
})
