import type { IncidentFormSection } from "../IncidentFormSection"

import { IncidentStatus } from "@/models"
import { faLifeRing, faStickyNote } from "@fortawesome/free-regular-svg-icons"
import {
	faAnchor,
	faCircleInfo,
	faLock,
	faMapLocationDot,
	faPenNib,
	faSave,
	faShip,
	faTowerBroadcast,
	faWind,
} from "@fortawesome/free-solid-svg-icons"
import type { IncidentLogHandler } from "../IncidentLogHandler"
import CallerInformation from "./CallerInformation"
import CreateIncident from "./CreateIncident"
import IncidentLocation from "./IncidentLocation"
import MainDetails from "./MainDetails"
import ShipRegistration from "./ShipRegistration"
import AssetsTasked from "./assets-tasked/AssetsTasked"
import IncidentNarrative from "./narrative"
import ShipDescription from "./ship-description"
import StatusActions from "./status-actions"
import WeatherTide from "./weather-tide/WeatherTide"

const alpha = 0.1
const green = `rgba(92, 232, 72, ${alpha})`
const blue = `rgba(72, 179, 232, ${alpha})`
const red = `rgba(204, 0, 0, ${alpha})`
const orange = `rgba(227, 164, 15, ${alpha})`
const purple = `rgba(77, 72, 232, ${alpha})`
const yellow = `rgba(255, 251, 0, ${alpha})`
const gray = `rgba(145, 145, 145, ${alpha})`
const pink = `rgba(227, 0, 192, ${alpha})`

export const AllFormSections = {
	mainDetails: {
		id: "main",
		name: "Main Details",
		icon: faCircleInfo,
		color: green,
		render: MainDetails,
	},
	incidentLocation: {
		id: "incident-location",
		name: "Incident Location",
		icon: faMapLocationDot,
		color: red,
		render: IncidentLocation,
	},
	callerInformation: {
		id: "caller-information",
		name: "Caller Information",
		icon: faTowerBroadcast,
		color: orange,
		render: CallerInformation,
	},
	shipDescription: {
		id: "ship-description",
		name: "Ship Description",
		icon: faShip,
		color: blue,
		render: ShipDescription,
	},
	assetsTasked: {
		id: "assets-tasked",
		name: "Assets Tasked",
		icon: faLifeRing,
		color: pink,
		render: AssetsTasked,
	},
	weatherAndTide: {
		id: "weather-and-tide",
		name: "Weather & Tide",
		icon: faWind,
		color: purple,
		render: WeatherTide,
	},
	shipRegistration: {
		id: "ship-registration",
		name: "Ship Registration",
		icon: faAnchor,
		color: gray,
		render: ShipRegistration,
	},
	additionalInfo: {
		id: "narrative",
		name: "Incident Narrative",
		icon: faStickyNote,
		color: yellow,
		render: IncidentNarrative,
	},
	createIncident: {
		id: "create",
		name: "Create Incident Log",
		icon: faSave,
		render: CreateIncident,
	},
	closeIncident: {
		id: "close",
		name: "Close Incident",
		icon: faLock,
		render: StatusActions,
	},
	signOff: {
		id: "sign-off",
		name: "Sign Off",
		icon: faPenNib,
		render: StatusActions,
	},
} satisfies Record<string, IncidentFormSection>

export function getIncidentFormSections(handler: IncidentLogHandler) {
	const sections = selectFormSections(
		"mainDetails",
		"incidentLocation",
		"callerInformation",
		"shipDescription",
		"weatherAndTide",
		"shipRegistration",
		"assetsTasked",
		"additionalInfo"
	)

	if (handler.isNewIncident && !handler.readOnly) {
		sections.push(AllFormSections.createIncident)
	}
	if (!handler.isNewIncident) {
		if (handler.incident.status !== IncidentStatus.Open) {
			sections.push(AllFormSections.signOff)
		} else if (!handler.readOnly) {
			sections.push(AllFormSections.closeIncident)
		}
	}

	return sections
}

function selectFormSections(...keys: (keyof typeof AllFormSections)[]): IncidentFormSection[] {
	const sections: IncidentFormSection[] = []
	for (const key of keys) {
		sections.push(AllFormSections[key])
	}
	return sections
}
