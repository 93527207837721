/* eslint-disable vue/one-component-per-file */
import LeafletMap from "@/components/mapping/LeafletMap"
import LocationMarker from "@/components/mapping/LocationMarker"
import { getEmergencyPhaseLeafletFilter } from "@/models"
import type { IncidentLogSummary } from "@/models/IncidentLogSummary"
import { useAppConfig } from "@/stores/appConfigStore"
import type { Map } from "leaflet"
import { render, toRef, watch } from "vue"
import { useRouter } from "vue-router"
import {
	RefQueries,
	defineComponent,
	renderSlot,
	requiredProp,
	useQueryRef,
	type ReactiveComponent,
	type RouterQueryRefOptions,
} from "vue-utils"
import IncidentLogMarkerTooltip from "./IncidentLogMarkerTooltip"

interface Props {
	incidents: IncidentLogSummary[]
}

const FloatingPointQuery: RouterQueryRefOptions<number> = {
	parse: (qry) => Number.parseFloat(String(qry)),
	stringify: (no) => no.toFixed(5),
}

const IncidentMap: ReactiveComponent<Props> = (props, { slots }) => {
	const router = useRouter()
	const appConfig = useAppConfig()

	const latitude = useQueryRef("lat", appConfig.mapLocation.latitude, FloatingPointQuery)
	const longitude = useQueryRef("lng", appConfig.mapLocation.longitude, FloatingPointQuery)
	const zoom = useQueryRef("zoom", appConfig.mapZoom, RefQueries.Int)

	let map: Map
	const markers: LocationMarker[] = []

	function initMap(mapData: Map, container: HTMLElement) {
		map = mapData

		try {
			map.setView([latitude.value, longitude.value], zoom.value, { animate: false })
		} catch (e) {
			//Ignored
		}
		map.addEventListener("moveend", () => {
			latitude.value = map.getCenter().lat
			longitude.value = map.getCenter().lng
			zoom.value = map.getZoom()
		})

		container.style.borderRadius = "0.5rem"
		container.style.overflow = "hidden"

		updateMarkers()
	}

	function updateMarkers() {
		if (!map) {
			return
		}
		for (const marker of markers) {
			marker.removeFrom(map)
		}
		markers.length = 0

		for (const log of props.incidents) {
			/*
			 	Leaflet only supports binding markers directly to HTML, rather than VueJS
				Use Vue's in built "render" function to render a Vue component version of the tooltip directly to a container div
			 */
			const container = document.createElement("div")
			render(<IncidentLogMarkerTooltip incident={log} />, container)

			const marker = new LocationMarker({
				latitude: log.position.latitude,
				longitude: log.position.longitude,
				markerStyles: {
					filter: getEmergencyPhaseLeafletFilter(log.emergencyPhase),
				},
				tooltipHtml: container.innerHTML,
			})
			marker.locationMarker.addEventListener("click", () => {
				void router.push(`/incidents/id/${log.id}`)
			})
			markers.push(marker)
			marker.addTo(map)
		}
	}

	watch(toRef(props, "incidents"), updateMarkers)

	return () => (
		<LeafletMap
			initMap={initMap}
			mapOptions={{
				zoomControl: true,
			}}
			zoomControlPosition="bottomright"
			class="flex-grow-1"
		>
			{renderSlot(slots)}
		</LeafletMap>
	)
}

export default defineComponent(IncidentMap, {
	incidents: requiredProp(Array),
})
