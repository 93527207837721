import { proxyProp } from "@/utils/proxyProp"
import { computed, unref, type HTMLAttributes, type MaybeRef } from "vue"
import { useRouter } from "vue-router"
import {
	FormTabs,
	FormTabsRenderingMethod,
	defineComponent,
	optionalProp,
	requiredProp,
	type FormTab,
	type ReactiveComponent,
} from "vue-utils"

interface Props {
	tabs: FormTab[]
	onSelected?: (tab: FormTab) => void
	renderingMethod?: FormTabsRenderingMethod
}

export function useRouterTabs<T extends FormTab = FormTab>(
	tabs: MaybeRef<T[]>,
	options: {
		onSelected?: (tab: T) => void
	} = {}
) {
	const router = useRouter()

	function setTab(id: string) {
		const current = router.currentRoute.value
		void router.replace({
			...current,
			hash: `#${id}`,
		})
		if (options.onSelected) {
			const tab = unref(tabs).find((t) => t.id === id)
			if (tab) {
				options.onSelected(tab)
			}
		}
	}

	const tab = computed(() => {
		const hash = router.currentRoute.value.hash

		let tab = unref(tabs).find((tab) => `#${tab.id}` === hash)
		if (!tab) {
			tab = unref(tabs)[0]
			setTab(tab.id)
		}
		return tab
	})

	return {
		tab,
		setTab,
	}
}

const RouterTabs: ReactiveComponent<Props, HTMLAttributes> = (props, { slots, attrs }) => {
	const { tab, setTab } = useRouterTabs(proxyProp(props, "tabs"))

	return () => (
		<FormTabs
			tabs={props.tabs}
			selectedTabId={tab.value.id}
			setSelectedTab={setTab}
			content={props.renderingMethod}
			v-slots={slots}
			{...attrs}
		/>
	)
}

export default defineComponent(RouterTabs, {
	tabs: requiredProp(Array),
	onSelected: optionalProp(Function),
	renderingMethod: optionalProp(String),
})
