import { LoadingError, showError } from "@/composition/useLoading"
import { IncidentStatus, SystemRole, type IncidentLog, type Nullable, type User } from "@/models"
import { changeBuilder, type ModificationAction } from "@/modifications"
import { applyModification } from "@/modifications/applyModification"
import usePopups from "@/stores/popupsStore"
import { Deferred } from "@/utils/Deferred"
import { Duration } from "@js-joda/core"
import { HubConnectionState, type HubConnection } from "@microsoft/signalr"
import type { Ref } from "vue"
import { Alert, type ValidationHandler } from "vue-utils"
import type { IncidentAction } from "../shared/incident-form/AdditionalActions"
import type { IncidentLogHandler } from "../shared/incident-form/IncidentLogHandler"
import { ActionScheduler } from "./ActionScheduler"

interface Options {
	incidentRef: Ref<IncidentLog | null>
	isConnected: Ref<boolean>
	loggedInUser: User
	connection: HubConnection
	validationHandler: ValidationHandler
	getBearer: () => Promise<string>
}

/**
 * An incident log handler which permits no actions and is completely read only.
 */
export class EditLiveIncidentHandler implements IncidentLogHandler {
	private readonly popups = usePopups()

	private readonly options: Options
	private readonly actionScheduler
	private readonly waitingActionsMap = new Map<string, Deferred<boolean>>()

	constructor(options: Options) {
		this.options = options
		this.actionScheduler = new ActionScheduler(Duration.ofMillis(500), (action) => void this.sendAction(action))

		this.connection.onclose(() => {
			this.actionScheduler.cancelAll()
		})
	}

	readonly isNewIncident = false
	readonly changeBuilder = changeBuilder<IncidentLog>()

	private get connection() {
		return this.options.connection
	}

	get incident(): IncidentLog {
		const ref = this.options.incidentRef
		if (!ref.value) {
			throw new Error("Incident log is not yet loaded")
		}
		return ref.value
	}

	private get incidentLoaded() {
		return this.options.incidentRef.value !== null
	}

	createIncident(): Promise<void> {
		return Promise.reject(new Error("Unable to create already existing incident"))
	}

	async validateForm(): Promise<boolean> {
		const result = await this.options.validationHandler.validateForm()
		if (result.successful) {
			return true
		}
		if (result.errorMessages?.length) {
			void this.popups.showAlertPopup(() => (
				<Alert title="Missing Information">
					<ul style={{ margin: 0, listStyle: "none", padding: 0 }}>
						{result.errorMessages?.map((msg, i) => <li key={i}>{msg}</li>)}
					</ul>
				</Alert>
			))
		}
		return false
	}

	get readOnly(): boolean {
		if (!this.options.loggedInUser.atLeastHasRole(SystemRole.CoastguardOfficer)) {
			return true
		}
		if (!this.options.isConnected.value) {
			return true
		}
		if (this.incidentLoaded && this.incident.status !== IncidentStatus.Open) {
			return true
		}
		return false
	}

	submitChange(action: IncidentAction): void {
		if (action.type === "Set") {
			applyModification(action, this.incident)
		}
		void this.sendAction(action)
	}

	submitDebouncedChange(action: IncidentAction): void {
		if (action.type === "Set") {
			applyModification(action, this.incident)
			this.actionScheduler.scheduleAction(action)
		} else {
			void this.sendAction(action)
		}
	}

	async awaitChange(action: IncidentAction): Promise<boolean> {
		if ("path" in action) {
			this.actionScheduler.cancelAction(action.path)
		}

		const deferred = new Deferred<boolean>()
		await this.sendAction(action)

		this.waitingActionsMap.set(action.guid, deferred)

		if (!(await deferred)) {
			return false
		}

		if (action.type === "Set") {
			//Set actions don't get re-emitted by the server, so wait for completion then apply the action
			this.onReceivedAction(action)
		}

		return true
	}

	onReceivedAction(action: ModificationAction): void {
		applyModification(action, this.incident)
	}

	onReceivedSuccess(actionId: string) {
		this.waitingActionsMap.get(actionId)?.resolve(true)
		this.waitingActionsMap.delete(actionId)
	}

	onReceivedError(error: Error, actionId: Nullable<string>) {
		if (actionId !== null && this.waitingActionsMap.has(actionId)) {
			const waitingAction = this.waitingActionsMap.get(actionId)!
			this.waitingActionsMap.delete(actionId)

			waitingAction.reject(error)
		} else {
			const errorToShow =
				error instanceof LoadingError ? error : new LoadingError("You have been disconnected", error.message)
			if (this.waitingActionsMap.size === 0) {
				void showError(errorToShow)
			} else {
				for (const promise of this.waitingActionsMap.values()) {
					promise.reject(errorToShow)
				}
				this.waitingActionsMap.clear()
			}

			if (this.connection.state !== HubConnectionState.Disconnected) {
				void this.connection.stop()
			}
		}
	}

	private async sendAction(action: IncidentAction) {
		const bearer = await this.options.getBearer()
		await this.connection.send("RunAction", {
			action,
			bearer,
		})
	}
}
