export enum SeaState {
	/** Wave height less than 0.5m */
	Smooth = "Smooth",

	/** Wave height between 0.5m and 1.25m */
	Slight = "Slight",

	/** Wave height between 1.25m and 2.5m */
	Moderate = "Moderate",

	/** Wave height between 2.5m and 4.0m */
	Rough = "Rough",

	/** Wave height between 4.0m and 6.0m */
	VeryRough = "VeryRough",

	/** Wave height between 6.0 and 9.0m */
	High = "High",

	/** Wave height between 9.0m and 14.0m */
	VeryHigh = "VeryHigh",

	/** Wave height more than 14.0m */
	Phenomenal = "Phenomenal",
}
