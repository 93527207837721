import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

const msgStyles = css`
	background-color: darkred;
	color: white;
	font-size: 1.125rem;
	padding: 0.75rem 1rem;
	border-radius: 0.35rem;
	font-weight: 600;

	& > button {
		all: unset;
		text-decoration: underline;
		cursor: pointer;
	}

	@media print {
		display: none;
	}
`

interface Props {
	refresh(): void
}

const DisconnectedMessage: ReactiveComponent<Props> = (props) => {
	return () => (
		<div class={msgStyles}>
			You have been disconnected and will not be able to see any changes to the incident.{" "}
			<button onClick={props.refresh}>Click here to refresh</button>
		</div>
	)
}

export default defineComponent(DisconnectedMessage, {
	refresh: requiredProp(Function),
})
