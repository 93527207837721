import type { IIdentifier, Id } from "@/models"
import type { ApiSchemaType } from "@/models/Schema"
import { castUnsafe } from "vue-utils"
import type { BasePathEntry } from "./BasePath"

export class IdPath implements BasePathEntry {
	readonly type = "Id"
	readonly id: Id

	constructor(id: Id) {
		if (!Number.isSafeInteger(id)) {
			throw new Error(`Invalid id '${id}'`)
		}
		this.id = id
	}

	getValue(object: unknown): unknown {
		const array = this.ensureIsArray(object)
		for (const entry of array) {
			if ("id" in entry && entry.id === this.id) {
				return entry
			}
		}
		throw new Error(`Unable to find object in array with id ${this.id}`)
	}

	setValue(object: unknown, newValue: unknown): unknown {
		const actualNewValue = this.ensureValueIsIdentifiable(newValue)
		const array = this.ensureIsArray(object)

		for (let i = 0; i < array.length; i++) {
			const entry = array[i]
			if ("id" in entry && entry.id === this.id) {
				array[i] = actualNewValue
				return entry
			}
		}
		throw new Error(`Unable to find object in array with id ${this.id}`)
	}

	addValue(object: unknown, newValue: unknown): void {
		const actualNewValue = this.ensureValueIsIdentifiable(newValue)
		const array = this.ensureIsArray(object)

		const existingIndex = array.findIndex((entry) => "id" in entry && entry.id === this.id)
		if (existingIndex < 0) {
			array.push(actualNewValue)
		} else {
			array[existingIndex] = actualNewValue
		}
	}

	deleteValue(object: unknown): unknown {
		const array = this.ensureIsArray(object)
		for (let i = 0; i < array.length; i++) {
			const entry = array[i]
			if ("id" in entry && entry.id === this.id) {
				return array.splice(i, 1)[0]
			}
		}
		console.warn(`Unable to find object in array with id ${this.id}`)
		return undefined
	}

	resolveSchema<T>(schema: ApiSchemaType<T>): ApiSchemaType<unknown> {
		if (schema.type !== "array") {
			throw new Error(`Expected array schema, got ${schema.type}`)
		}
		return schema.schema as ApiSchemaType<unknown>
	}

	private ensureValueIsIdentifiable(value: unknown): IIdentifier {
		const newValue = castUnsafe<IIdentifier>(value)
		if (!("id" in newValue) || !Number.isSafeInteger(newValue.id)) {
			throw new Error(`Invalid identifiable object (${String(value)})`)
		}
		return newValue
	}

	private ensureIsArray(object: unknown): IIdentifier[] {
		if (!Array.isArray(object)) {
			throw new Error(`Expected array, got ${typeof object}`)
		}
		return castUnsafe(object)
	}

	toJSON() {
		return `{${this.id}}`
	}

	public static fromJSON(json: string): IdPath {
		json = json.trim()
		if (!json.startsWith("{") || !json.endsWith("}")) {
			throw new Error("Invalid format for Id")
		}
		const idText = json.substring(1, json.length - 1)
		const id = Number.parseInt(idText)
		if (!Number.isSafeInteger(id)) {
			throw new Error(`Invalid id '${idText}'`)
		}
		return new IdPath(id)
	}

	get [Symbol.toStringTag]() {
		return `{${this.id}}`
	}
}
