import type { ApiSchema, ApiSchemaType } from "@/models/Schema"
import { castUnsafe } from "vue-utils"
import { IdPath } from "./IdPath"
import { MemberPath } from "./MemberPath"

export * from "./BasePath"

export * from "./IdPath"
export * from "./MemberPath"

export type ObjectPathEntry = MemberPath | IdPath
export type ObjectPath = ObjectPathEntry[]

export const ObjectPathEntrySchema: ApiSchema<ObjectPathEntry> = (path) => {
	if (path.startsWith("{")) return IdPath.fromJSON(path)
	if (path.startsWith(".")) return MemberPath.fromJSON(path)

	throw new Error(`Invalid path '${path}'`)
}

export const ObjectPathSchema: ApiSchema<ObjectPath> = {
	type: "array",
	schema: ObjectPathEntrySchema,
}

export function resolvePath(path: ObjectPath, value: unknown) {
	let currentValue = value
	for (const entry of path) {
		currentValue = entry.getValue(currentValue)
	}
	return currentValue
}

export function getNestedSchema<T>(schema: ApiSchemaType<T>, path: ObjectPath): ApiSchemaType<unknown> {
	let currentSchema: ApiSchemaType<unknown> = castUnsafe(schema)
	for (const entry of path) {
		currentSchema = entry.resolveSchema(currentSchema)
	}
	return currentSchema
}
