import { Instant } from "@js-joda/core"
import type { InputHTMLAttributes } from "vue"
import { optionalProp, requiredProp, useOnInput } from "vue-utils"

type Props = {
	readonly value: Instant | null
	readonly min?: Instant
	readonly max?: Instant
} & (
	| {
			setValue(instant: Instant): void
			required: true
	  }
	| {
			setValue(instant: Instant | null): void
			required?: false
	  }
)

function InstantInput(
	props: Props & Omit<InputHTMLAttributes, keyof Props>,
	{ attrs }: { attrs: Omit<InputHTMLAttributes, keyof Props> }
) {
	function handleChange(newValue: string) {
		newValue = newValue.trim()
		if (newValue.length === 0 && !props.required) {
			props.setValue(null)
		} else {
			props.setValue(Instant.fromHtmlInputUTC(newValue))
		}
	}

	return (
		<input
			type="datetime-local"
			value={props.value?.toHTMLInputUTC() ?? ""}
			onChange={useOnInput(handleChange)}
			required={props.required}
			{...attrs}
		/>
	)
}

InstantInput.props = {
	value: requiredProp(Instant, null),
	setValue: requiredProp(Function),

	min: optionalProp(Instant),
	max: optionalProp(Instant),
	required: optionalProp(Boolean),
}

export default InstantInput
