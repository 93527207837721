import Icon from "@/components/Icon"
import UserIndicator from "@/components/titlebar/UserIndicator"
import type { User } from "@/models"
import { useLoggedInUser } from "@/stores/coreDataStore"
import { faUserGroup } from "@fortawesome/free-solid-svg-icons"
import { computed } from "vue"
import { defineComponent, getUnique, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	users: User[]
}

const ConnectedUsers: ReactiveComponent<Props> = (props) => {
	const loggedInUser = useLoggedInUser()
	const allUsers = computed(() => (props.users.length === 0 ? [] : [loggedInUser, ...props.users]))
	const sortedUsers = computed(() =>
		getUnique(allUsers.value, (u) => u.id).sort((u1, u2) => u1.fullName.localeCompare(u2.fullName))
	)

	return () =>
		sortedUsers.value.length === 0 ? (
			<div />
		) : (
			<>
				<div class="inline-flex items-center h-full" style={{ padding: "0 1rem", gap: "0.125rem" }}>
					<Icon title="Connected Users" icon={faUserGroup} style={{ marginRight: "0.5rem", color: "#242424" }} />
					{sortedUsers.value.map((user) => (
						<UserIndicator
							key={user.id}
							firstName={user.firstName}
							lastName={user.lastName}
							style={{ fontSize: "1.125rem" }}
						/>
					))}
				</div>
				<div class="h-full" style={{ borderRight: "0.125rem solid rgba(0, 0, 0, 0.55)", height: "2.35rem" }} />
			</>
		)
}

export default defineComponent(ConnectedUsers, {
	users: requiredProp(Array),
})
