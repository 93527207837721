import { User, type Id, type PrimaryIncidentType, type SearchAndRescueUnit } from "@/models"
import { httpRequestJsonResponse } from "@/services/httpService"
import { getLoggedInUser, getUsers } from "@/services/usersService"
import { toReactive } from "@vueuse/core"
import { defineStore } from "pinia"
import { computed, toRef, toValue, type ComputedRef, type MaybeRefOrGetter } from "vue"
import {
	assignLoadable,
	defineGlobals,
	ensureLoadingHasResult,
	mergePromises,
	piniaLoadableState,
	useLoadableRef,
	type Loadable,
} from "vue-utils"

export async function getPrimaryIncidentTypes(): Promise<PrimaryIncidentType[]> {
	return await httpRequestJsonResponse("/api/data/primary-incident-types")
}

export async function getSearchAndRescueUnits(): Promise<SearchAndRescueUnit[]> {
	return await httpRequestJsonResponse("/api/data/search-and-rescue-units")
}

export interface CoreData {
	searchAndRescueUnits: SearchAndRescueUnit[]
	primaryIncidentTypes: PrimaryIncidentType[]
	loggedInUser: User
	users: Map<Id, User>
}

async function loadData(): Promise<CoreData> {
	const mainData = await mergePromises({
		searchAndRescueUnits: getSearchAndRescueUnits(),
		primaryIncidentTypes: getPrimaryIncidentTypes(),
		loggedInUser: getLoggedInUser(),
		users: getUsers(),
	})

	const usersById = new Map<Id, User>()
	for (const user of mainData.users) {
		usersById.set(user.id, user)
	}

	return {
		...mainData,
		users: usersById,
	}
}

export const useCoreDataStore = defineStore("core-data", {
	state: () => piniaLoadableState(loadData),
	actions: {
		data(): CoreData {
			return ensureLoadingHasResult(this)
		},
		lookupUser(id: Id): User | null {
			return this.data().users.get(id) ?? null
		},
		lookupSUR(id: Id): SearchAndRescueUnit | null {
			return this.data().searchAndRescueUnits.find((sru) => sru.id == id) ?? null
		},
		lookupIncidentType(id: Id): PrimaryIncidentType | null {
			return this.data().primaryIncidentTypes.find((type) => type.id == id) ?? null
		},
	},
})

export function useCoreData(): CoreData {
	const store = useCoreDataStore()
	return toReactive(toRef(() => ensureLoadingHasResult(store)))
}

export function createCoreDataRefreshRef(): Loadable<void> {
	const dataStore = useCoreDataStore()
	return useLoadableRef(async () => {
		const data = await dataStore.queryData()
		assignLoadable(dataStore, {
			type: "done",
			result: data,
		})
	})
}

export function useUser(userId: MaybeRefOrGetter<Id | null | undefined>): ComputedRef<User | null> {
	const store = useCoreDataStore()
	return computed(() => {
		const actualId = toValue(userId)
		if (typeof actualId === "number") {
			return store.lookupUser(actualId)
		}
		return null
	})
}

export function useLoggedInUser(): User {
	const store = useCoreDataStore()
	return toReactive(toRef(() => store.data().loggedInUser))
}

defineGlobals({
	useCoreData,
	useCoreDataStore,
	useLoggedInUser,
})
