import { useAppConfig } from "@/stores/appConfigStore"
import { useLocalStorage } from "@vueuse/core"
import L, { type ControlPosition, type Map, type MapOptions } from "leaflet"
import { css } from "vite-css-in-js"
import { watch, type HTMLAttributes } from "vue"
import { defineComponent, optionalProp, refSetter, renderSlot, type ReactiveComponent } from "vue-utils"
import BaseMap from "./BaseMap"
import ViewToggle, { ViewType } from "./ViewToggle"

interface Props {
	mapOptions?: MapOptions
	zoomControlPosition?: ControlPosition
	initMap?: (map: Map, container: HTMLElement) => void
}

const mapContainerStyles = css`
	position: relative;
	flex-grow: 1;
`

const mapStyles = css`
	inset: 0;
	max-width: min(100%, 100vw);
	max-height: min(100%, 100vh);
	position: absolute;
	z-index: 0;

	.leaflet-tooltip {
		opacity: 0.95 !important;
		border-radius: 0.5rem;
		padding: 0.5rem;
		box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
	}

	.direction-popup {
		.leaflet-popup-content-wrapper,
		.leaflet-popup-tip {
			background-color: transparent;
			box-shadow: none;
		}

		.leaflet-popup-content-wrapper {
			background-color: rgba(255, 255, 255, 0.8);
			border-radius: 0.5rem;
			padding: 0.35rem 0.75rem;
			margin-top: 0;

			.leaflet-popup-content {
				font-size: var(--bs-body-font-size);
				margin: 0;
				line-height: 1.6rem;
			}
		}
	}
`

const LeafletMap: ReactiveComponent<Props, HTMLAttributes> = (props, { attrs, slots }) => {
	let map: Map
	const viewType = useLocalStorage("incident-map-view-type", ViewType.Map)

	const appConfig = useAppConfig()

	const osmLayer = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
		attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
	})
	const satelliteLayer = L.tileLayer(
		"https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
		{
			attribution:
				"Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
		}
	)
	const seaMapLayer = L.tileLayer("https://t1.openseamap.org/seamark/{z}/{x}/{y}.png")

	function initMap(mapData: Map, container: HTMLElement) {
		map = mapData
		map.setView([appConfig.mapLocation.latitude, appConfig.mapLocation.longitude], appConfig.mapZoom)
		updateLayers()

		props.initMap?.(mapData, container)
	}

	function updateLayers() {
		if (!map) {
			return
		}

		seaMapLayer.removeFrom(map)
		if ((viewType.value as ViewType) === ViewType.Satellite) {
			osmLayer.removeFrom(map)
			satelliteLayer.addTo(map)
		} else {
			satelliteLayer.removeFrom(map)
			osmLayer.addTo(map)
		}
		seaMapLayer.addTo(map)
	}

	watch(viewType, updateLayers)

	return () => (
		<div class={mapContainerStyles} {...attrs}>
			<BaseMap
				initMap={initMap}
				mapOptions={{
					zoomControl: true,
					...props.mapOptions,
				}}
				zoomControlPosition={props.zoomControlPosition}
				class={mapStyles}
			/>
			{renderSlot(slots)}
			<ViewToggle viewType={viewType.value as ViewType} setViewType={refSetter(viewType)} />
		</div>
	)
}

export default defineComponent(LeafletMap, {
	initMap: optionalProp(Function),
	mapOptions: optionalProp(Object),
	zoomControlPosition: optionalProp(String),
})
