import AutoColumns from "@/components/AutoColumns"
import BasicSelect from "@/components/form/BasicSelect"
import { LengthUnit, type UnitLength } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { Required, ToggleButton, defineComponent, useOnInputNumber, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../IncidentLogHandler"
import IncidentTextArea from "../../components/IncidentTextArea"
import ColourInput from "./ColourInput"

const ShipDescription: ReactiveComponent = () => {
	const handler = useIncidentHandler()

	const changeBuilder = handler.changeBuilder.at("shipDescription")
	const description = proxyProp(() => handler.incident.shipDescription)

	function updateLengthKnown(known: boolean) {
		const newLength = known ? { length: 0, unit: LengthUnit.Feet } : null
		handler.submitChange(changeBuilder.at("length").setTo(newLength))
	}

	function updateShipLength<K extends keyof UnitLength>(key: K, value: UnitLength[K]) {
		const existingLength = description.length ?? { length: 0, unit: LengthUnit.Feet }
		handler.submitChange(
			changeBuilder.at("length").setTo({
				...existingLength,
				[key]: value,
			})
		)
	}

	return () => (
		<div class="flex flex-col spacing-4">
			<AutoColumns size="16rem" class="spacing-4">
				<label style={{ height: "4.25rem" }}>
					Ship Length Known?
					<ToggleButton toggled={!!description.length} setToggled={updateLengthKnown} disabled={handler.readOnly} />
				</label>
				{!!description.length && (
					<label>
						<Required label="Ship Length" />
						<input
							type="number"
							placeholder={handler.readOnly ? "N/A" : "Enter ship length..."}
							value={description.length.length}
							onChange={useOnInputNumber((newLength) => updateShipLength("length", newLength))}
							min={0}
							step={0.1}
							disabled={handler.readOnly}
							required
						/>
					</label>
				)}
				{!!description.length && (
					<label>
						<Required label="Ship Length Units" />
						<BasicSelect
							options={Object.values(LengthUnit)}
							value={description.length.unit}
							setValue={(unit) => updateShipLength("unit", unit)}
							disabled={handler.readOnly}
							required
						/>
					</label>
				)}
			</AutoColumns>

			<AutoColumns size="16rem" class="spacing-4">
				<label>
					Hull Colour
					<ColourInput
						path={changeBuilder.at("hullColour")}
						placeholder={handler.readOnly ? "N/A" : "Description of superstructure..."}
						disabled={handler.readOnly}
					/>
				</label>

				<label>
					Superstructure Colour
					<ColourInput
						path={changeBuilder.at("superstructureColour")}
						placeholder={handler.readOnly ? "N/A" : "Description of superstructure..."}
						disabled={handler.readOnly}
					/>
				</label>

				<label>
					Sail Colour
					<ColourInput
						path={changeBuilder.at("sailColour")}
						placeholder={handler.readOnly ? "N/A" : "Enter description of sail..."}
						disabled={handler.readOnly}
					/>
				</label>
			</AutoColumns>
			<label>
				Additional Information
				<IncidentTextArea
					path={changeBuilder.at("additionalInformation")}
					placeholder={
						handler.readOnly ? "N/A" : "Enter any additional information to help identity the ship or casualty..."
					}
					rows={3}
					disabled={handler.readOnly}
				/>
			</label>
		</div>
	)
}

export default defineComponent(ShipDescription)
