import { Instant } from "@js-joda/core"
import type { Id } from "."
import { InstantSchema, type ApiSchema } from "./Schema"

export interface UserTimeStamp {
	userId: Id
	timeStamp: Instant
}

export const UserTimeStampSchema: ApiSchema<UserTimeStamp> = {
	type: "object",
	properties: {
		userId: Number,
		timeStamp: InstantSchema,
	},
}
