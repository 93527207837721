import type { AssetTasked } from "@/models"
import { useCoreDataStore } from "@/stores/coreDataStore"
import type { Immutable } from "@/utils/Immutable"
import { computed, ref } from "vue"
import { defineComponent, getUnique, requiredProp, type ReactiveComponent } from "vue-utils"
import EditableField from "../EditableField"
import EditCrew from "./EditCrew"

interface Props {
	asset: Immutable<AssetTasked>
	setCrew(crew: number[]): Promise<void>
}

const CrewCell: ReactiveComponent<Props> = (props) => {
	const coreDataStore = useCoreDataStore()
	const editing = ref(false)

	const canConfigureCrew = computed(() => {
		const sru = coreDataStore.lookupSUR(props.asset.SRUId)
		return sru !== null && sru.totalCrew !== null
	})
	const showAddCrew = computed(() => {
		return canConfigureCrew.value && (props.asset.crewLists === null || props.asset.crewLists.length === 0)
	})
	const crewText = computed(() => {
		const crew = props.asset.crewLists
		if (crew === null || crew.length === 0) {
			return "-"
		}
		return [...getUnique([...crew])].sort((a, b) => a - b).join(", ")
	})
	const totalCrew = computed(() => {
		const sru = coreDataStore.lookupSUR(props.asset.SRUId)
		return sru?.totalCrew ?? 0
	})

	return () => (
		<>
			<EditableField
				hasValue={!showAddCrew.value}
				edit={() => (editing.value = true)}
				disabled={!canConfigureCrew.value}
			>
				<span class="w-full text-center">{crewText.value}</span>
			</EditableField>

			{editing.value && (
				<EditCrew
					existing={props.asset.crewLists ? [...props.asset.crewLists] : []}
					totalCrew={totalCrew.value}
					setCrew={async (newCrew) => {
						await props.setCrew(newCrew)
						editing.value = false
					}}
					cancel={() => (editing.value = false)}
				/>
			)}
		</>
	)
}

export default defineComponent(CrewCell, {
	asset: requiredProp(Object),
	setCrew: requiredProp(Function),
})
