import { IncidentLogSchema, SystemRole, type ApiTypeOf, type IIdentifier, type IncidentLog, type User } from "@/models"
import { mapApiType } from "@/models/Schema"
import {
	changeBuilder,
	createModificationActionSchema,
	isModificationAction,
	type ChangeBuilder,
	type ModificationAction,
} from "@/modifications"
import { applyModification } from "@/modifications/applyModification"
import usePopups from "@/stores/popupsStore"
import type { Immutable } from "@/utils/Immutable"
import { Alert, castUnsafe, type ValidationHandler } from "vue-utils"
import type { IncidentAction } from "../shared/incident-form/AdditionalActions"
import type { IncidentLogHandler } from "../shared/incident-form/IncidentLogHandler"

interface Options {
	createIncident(): Promise<void>
	validationHandler: ValidationHandler
	incident: IncidentLog
	loggedInUser: User
}

export class CreateIncidentHandler implements IncidentLogHandler {
	private readonly options: Options
	private readonly popups = usePopups()
	private keyCounter = 1

	constructor(options: Options) {
		this.options = options
	}

	isNewIncident = true
	changeBuilder: ChangeBuilder<IncidentLog> = changeBuilder()

	get incident(): Immutable<IncidentLog> {
		return this.options.incident
	}

	async createIncident(): Promise<void> {
		await this.options.createIncident()
	}

	async validateForm(): Promise<boolean> {
		const result = await this.options.validationHandler.validateForm()
		if (result.successful) {
			return true
		}
		if (result.errorMessages?.length) {
			void this.popups.showAlertPopup(() => (
				<Alert title="Missing Information">
					<ul style={{ margin: 0, listStyle: "none", padding: 0 }}>
						{result.errorMessages?.map((msg, i) => <li key={i}>{msg}</li>)}
					</ul>
				</Alert>
			))
		}
		return false
	}

	get readOnly(): boolean {
		return !this.options.loggedInUser.atLeastHasRole(SystemRole.CoastguardOfficer)
	}

	submitChange(action: IncidentAction): void {
		if (!isModificationAction(action)) {
			console.error(`Unable to process action '${action.type}'`)
			return
		}

		if (action.type === "Add") {
			/*
				When adding a new value, the Id of the object is most likely 0.
				This causes issues where objects won't have unique Ids/Keys, both with Vue, and also with actions

				Work around this problem by setting an initial Id - it'll be set properly on the server side anyway
			*/

			const newValue = castUnsafe<IIdentifier>(action.value)
			if ("id" in newValue && newValue.id === 0) {
				newValue.id = this.keyCounter++
			}
		}

		//Apply directly to the view model - no server side action is required as the whole form gets submitted at once
		applyModification(action, this.incident)
	}

	submitDebouncedChange(action: IncidentAction): void {
		this.submitChange(action)
	}

	awaitChange(action: IncidentAction): Promise<boolean> {
		this.submitDebouncedChange(action)
		return Promise.resolve(true)
	}

	receiveEventJson(json: unknown): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const jsonData = (typeof json === "string" ? JSON.parse(json) : json) as ApiTypeOf<ModificationAction>
		const schema = createModificationActionSchema(IncidentLogSchema)

		const actualAction = mapApiType(schema, jsonData)
		applyModification(actualAction, this.incident)
	}
}
