import { useVueAuth } from "oidc-vue"
import { css } from "vite-css-in-js"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import type { JSX } from "vue/jsx-runtime"
import ProfileButton from "./ProfileButton"
import { useAppHeader } from "./useAppHeader"

const headerStyles = css`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	letter-spacing: 0.0125rem;
	background-color: white;
	height: 3rem;
	font-weight: 500;
	box-shadow: 0 0.25rem 0.25rem #0000001f;
	z-index: 2;
	font-size: 1.25rem;
	border-bottom: thin solid rgba(0, 0, 0, 0.2);

	img {
		height: 100%;
		width: auto;
		object-fit: contain;
	}
`

interface Props {
	title: string
	showLogo?: boolean
}

const AppHeader: ReactiveComponent<Props> = (props) => {
	const { loggedIn, user } = useVueAuth()
	const handler = useAppHeader()

	function renderCustomEntries() {
		const elements: JSX.Element[] = []
		for (const [key, Component] of handler.componentsMap) {
			elements.push(<Component key={key} />)
		}
		return elements
	}

	return () => (
		<header class={headerStyles} style={{ paddingLeft: props.showLogo ? "0.5rem" : "1.5rem" }}>
			{props.showLogo && <img src="/logo.png" alt="Logo" />}
			<span class="flex-grow-1" style={{ whiteSpace: "nowrap" }}>
				{props.title}
			</span>

			{renderCustomEntries()}

			{loggedIn.value && (
				<ProfileButton
					key="profile-button"
					firstName={user.value?.profile.given_name ?? ""}
					lastName={user.value?.profile.family_name ?? ""}
				/>
			)}
		</header>
	)
}

export default defineComponent(AppHeader, {
	title: requiredProp(String),
	showLogo: optionalProp(Boolean),
})
