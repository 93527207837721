import type { AssetTasked, Id, Nullable } from "@/models"
import { useCoreDataStore } from "@/stores/coreDataStore"
import type { Immutable } from "@/utils/Immutable"
import { computed, ref } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import EditableField from "../EditableField"
import EditSRU from "./EditSRU"

export interface EditSRUData {
	SRUId: Id
	customName: Nullable<string>
	crewLists?: AssetTasked["crewLists"]
}

interface Props {
	asset: Immutable<AssetTasked>
	updateAsset(data: EditSRUData): Promise<void>
}

const SRUCell: ReactiveComponent<Props> = (props) => {
	const coreDataStore = useCoreDataStore()
	const editing = ref(false)

	const name = computed(() => {
		if (props.asset.customName) {
			return props.asset.customName
		}
		return coreDataStore.lookupSUR(props.asset.SRUId)?.name ?? ""
	})

	return () => (
		<>
			<EditableField edit={() => (editing.value = true)} hasValue>
				<span class="w-full text-left">{name.value}</span>
			</EditableField>
			{editing.value && (
				<EditSRU
					cancel={() => (editing.value = false)}
					existing={props.asset}
					save={async (data) => {
						await props.updateAsset(data)
						editing.value = false
					}}
				/>
			)}
		</>
	)
}

export default defineComponent(SRUCell, {
	asset: requiredProp(Object),
	updateAsset: requiredProp(Function),
})
