import BootstrapButton from "@/components/form/BootstrapButton"
import Icon from "@/components/Icon"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { ref } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../IncidentLogHandler"
import AssetsTable from "./AssetsTable"
import EditSRU from "./sru/EditSRU"
import type { EditSRUData } from "./sru/SRUCell"

const AssetsTasked: ReactiveComponent = () => {
	const handler = useIncidentHandler()
	const addingAsset = ref(false)

	async function addAsset(data: EditSRUData) {
		const newAssetPath = handler.changeBuilder.at("assetsTasked").id(0)
		const action = newAssetPath.add({
			...data,
			id: 0,
			crewLists: data.crewLists ?? null,
			alerted: null,
			departed: null,
			onScene: null,
			returning: null,
			pierHeads: null,
			backOnStation: null,
			taskingInstructions: null,
			notes: null,
		})
		await handler.awaitChange(action)
		addingAsset.value = false
	}

	return () => (
		<div class="flex flex-col spacing-4">
			{addingAsset.value && <EditSRU cancel={() => (addingAsset.value = false)} existing={null} save={addAsset} />}

			{handler.incident.assetsTasked && handler.incident.assetsTasked.length > 0 ? (
				<AssetsTable />
			) : (
				<span>No assets have been tasked.</span>
			)}

			{!handler.readOnly && (
				<div>
					<BootstrapButton type="button" color="success" onClick={() => (addingAsset.value = true)}>
						<Icon icon={faPlusCircle} />
						<span>Add Asset Tasked</span>
					</BootstrapButton>
				</div>
			)}
		</div>
	)
}

export default defineComponent(AssetsTasked)
