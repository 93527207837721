import { resolvePath, type ChangeBuilder } from "@/modifications"
import { computed, customRef, shallowRef, toValue, watch, type MaybeRefOrGetter, type Ref } from "vue"
import { useIncidentHandler } from "./IncidentLogHandler"

export function useIncidentValue<T>(changeBuilder: MaybeRefOrGetter<ChangeBuilder<T>>): Ref<T> {
	const handler = useIncidentHandler()
	return computed(() => {
		const path = [...toValue(changeBuilder).path]
		return resolvePath(path, handler.incident) as T
	})
}

export function useIncidentInputRef<T>(changeBuilder: MaybeRefOrGetter<ChangeBuilder<T>>): Ref<T> {
	const currentFormValue = useIncidentValue(changeBuilder)

	const valueRef = shallowRef<T>(currentFormValue.value)
	watch(currentFormValue, (newFormValue) => (valueRef.value = newFormValue))

	return valueRef
}

export function useIncidentSelectRef<T>(changeBuilder: MaybeRefOrGetter<ChangeBuilder<T>>): Ref<T> {
	return useIncidentAutoSubmitInputRef(changeBuilder, { debounced: false })
}

export function useIncidentAutoSubmitInputRef<T>(
	changeBuilder: MaybeRefOrGetter<ChangeBuilder<T>>,
	options: { debounced: boolean }
): Ref<T> {
	const handler = useIncidentHandler()
	const valueRef = useIncidentInputRef(changeBuilder)

	return customRef<T>((track, trigger) => ({
		get() {
			track()
			return valueRef.value
		},
		set(newValue: T) {
			if (options.debounced) {
				handler.submitDebouncedChange(toValue(changeBuilder).setTo(newValue))
			} else {
				handler.submitChange(toValue(changeBuilder).setTo(newValue))
			}
			trigger()
		},
	}))
}
