import {
	IncidentStatus,
	type CardinalDirection,
	type EmergencyPhase,
	type IncidentLog,
	type SeaState,
	type User,
} from "@/models"
import { getCurrentTide, getCurrentWeatherConditions } from "@/services/weatherService"
import { Instant } from "@js-joda/core"
import { castUnsafe, mergePromises } from "vue-utils"

export async function createBlankTemplateIncidentLog(loggedInUser: User): Promise<IncidentLog> {
	const { weather, tide } = await mergePromises({
		weather: getCurrentWeatherConditions(),
		tide: getCurrentTide(),
	})

	return {
		id: 0,
		identifier: "000/00",
		incidentTime: Instant.now(),
		createdBy: loggedInUser.createTimeStamp(),

		status: IncidentStatus.Open,

		SMCOnDutyId: null,

		primaryIncidentTypeId: castUnsafe(null),

		emergencyPhase: "" as EmergencyPhase,
		nameOfVesselOrCasualty: "",
		personsAtRisk: 0,

		positionReceivedAt: null,
		position: {
			latitude: NaN,
			longitude: NaN,
		},

		messageReceived: "",
		natureOfIncident: "",
		descriptionOfCasualty: null,

		shipDescription: {
			length: null,
			hullColour: null,
			superstructureColour: null,
			sailColour: null,
			additionalInformation: "",
		},
		assetsTasked: [],
		callReceived: {
			VHFChannel: null,
			VHFChannelOther: false,
			DSC: false,
			MMSI: null,
			callSign: null,
			EPIRB: false,
			emergency999: false,
			phoneNumber: null,
			callerName: null,
		},
		registrationInformation: {
			portOfRegistry: null,
			homePort: null,
			ownerName: null,
			ownerAddress: null,
		},
		weatherConditions: weather ?? {
			seaState: "" as SeaState,
			windDirection: "" as CardinalDirection,
			visibilityNm: NaN,
			windSpeedKnots: NaN,
		},
		currentTide: tide ?? {
			lowWater: {
				height: NaN,
				time: null as unknown as Instant,
			},
			highWater: {
				height: NaN,
				time: null as unknown as Instant,
			},
		},
		incidentNarrative: [],

		reopened: null,
		closed: null,
		signedOff: null,
	}
}
