import { User, type SystemRole } from "@/models"
import { httpRequestJsonResponse, sanitiseQueryParams } from "./httpService"

export interface GetUsersOptions {
	role?: SystemRole
}

export async function getUsers(options: GetUsersOptions = {}): Promise<User[]> {
	const url = `/api/users?${sanitiseQueryParams(options)}`
	const users = await httpRequestJsonResponse<User[]>(url)
	return users.map((user) => new User(user))
}

export async function getLoggedInUser(): Promise<User> {
	const userDetails = await httpRequestJsonResponse("/api/users/logged-in")
	return new User(userDetails)
}
