import Icon from "@/components/Icon"
import CKEditor from "@/components/ckeditor/CKEditor"
import BootstrapButton from "@/components/form/BootstrapButton"
import { showError } from "@/composition/useLoading"
import { useLoggedInUser } from "@/stores/coreDataStore"
import usePopups from "@/stores/popupsStore"
import { faPenNib } from "@fortawesome/free-solid-svg-icons"
import { computed, ref } from "vue"
import { Alert, ConfirmResult, defineComponent, refSetter, useCustomCheck, type ReactiveComponent } from "vue-utils"
import { useIncidentHandler } from "../../IncidentLogHandler"

const AddNarrative: ReactiveComponent = () => {
	const handler = useIncidentHandler()
	const loggedInUser = useLoggedInUser()
	const popups = usePopups()

	const narrativeText = ref("")
	const submitting = ref(false)

	useCustomCheck(async () => {
		if (!hasText.value) {
			return true
		}
		const result = await popups.showConfirmPopup({
			content: () => (
				<Alert title="Narrative Pending">
					<p>You currently have incident narrative which has not been added.</p>
					<p>Do you wish to proceed anyway? This narrative text will not be included.</p>
				</Alert>
			),
			cancelText: "Cancel",
			confirmText: "Create Anyway",
		})
		return result === ConfirmResult.Confirm
	})

	const hasText = computed(() => {
		const text = narrativeText.value.trim()
		const el = document.createElement("div")
		el.innerHTML = text
		return el.innerText.trim().length > 0
	})

	async function addNarrative() {
		const newNotePath = handler.changeBuilder.at("incidentNarrative").id(0)
		const action = newNotePath.add({
			id: 0,
			text: narrativeText.value,
			creationMetadata: loggedInUser.createTimeStamp(),
		})

		submitting.value = true
		try {
			await handler.awaitChange(action)
			narrativeText.value = ""
		} catch (e) {
			void showError(e as Error)
		} finally {
			submitting.value = false
		}
	}

	return () => (
		<div>
			<p style={{ fontSize: "1.125rem", margin: "0.5rem 0" }}>Add Narrative</p>
			<CKEditor
				value={narrativeText.value}
				setValue={refSetter(narrativeText)}
				readOnly={submitting.value}
				height="12rem"
			/>
			{narrativeText.value.length > 0 && (
				<div class="flex justify-end spacing-4 flex-wrap mt-2">
					<BootstrapButton
						type="button"
						color="success"
						disabled={!hasText.value || submitting.value}
						isSubmitting={submitting.value}
						onClick={() => void addNarrative()}
					>
						<Icon icon={faPenNib} />
						Add Narrative
					</BootstrapButton>
				</div>
			)}
		</div>
	)
}

export default defineComponent(AddNarrative)
